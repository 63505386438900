import {
  ButtonBack,
  ButtonCreate,
  ButtonUpdate,
} from '@bhawanadevteam/react-core';
import { InfoItemHorizontal, Input, SelectSearch, TextArea } from 'components';
import { Formik, useFormikContext } from 'formik';
import React from 'react';
import { Card, Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import { useQuery } from 'react-query';
import PermintaanAsetApi from '../__PermintaanAsetApi__';
import TabGambar from './TabGambar';
import TabFile from './TabFile';

const DetailModal = () => {
  const { values } = useFormikContext();
  return (
    <Row className="mb-3">
      <Col>
        <InfoItemHorizontal
          label="Entitas Aset"
          text={values?.namaEntitasAset ?? '-'}
          width={130}
        />
        <InfoItemHorizontal
          label="Grup Aset"
          text={values?.namaGrupAset ?? '-'}
          width={130}
        />
        <InfoItemHorizontal
          label="Kategori Aset"
          text={values?.namaKategoriAset ?? '-'}
          width={130}
        />
        <InfoItemHorizontal
          label="Jenis Aset"
          text={values?.namaJenisAset ?? '-'}
          width={130}
        />
        <InfoItemHorizontal
          label="Nama Aset"
          text={values?.namaAset ?? '-'}
          width={130}
        />
      </Col>
      <Col>
        <InfoItemHorizontal
          label="Keperluan"
          text={values?.keperluanLabel ?? '-'}
          width={130}
        />
        <InfoItemHorizontal
          label="Metode Pengadaan"
          text={values?.metodePengadaanLabel ?? '-'}
          width={130}
        />
        <InfoItemHorizontal
          label="Unit Organisasi"
          text={values?.namaUnitProduksi ?? '-'}
          width={130}
        />
        <InfoItemHorizontal
          label="Keterangan"
          text={values?.keterangan ?? '-'}
          width={130}
        />
      </Col>
    </Row>
  );
};

export const ModalForm = ({ setModalConfig, modalConfig }) => {
  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();

  const { data: dropdownEntitasAset, isFetching: loadingDropdownEntitasAset } =
    useQuery(['entitasAset', 'dropdown'], () =>
      PermintaanAsetApi.getDropdownEntitasAset()
    );

  const { data: dropdownGrupAset, isFetching: loadingDropdownGrupAset } =
    useQuery(['grupAset', 'dropdown'], () =>
      PermintaanAsetApi.getDropdownGrupAset()
    );

  const {
    data: dropdownKategoriAset,
    isFetching: loadingDropdownKategoriAset,
  } = useQuery(
    ['kategoriAset', 'dropdown', values?.idGrupAset],
    () =>
      PermintaanAsetApi.getDropdownKategoriAset({
        id_grup_aset: values?.idGrupAset,
      }),
    { enabled: !!values?.idGrupAset }
  );

  const { data: dropdownJenisAset, isFetching: loadingDropdownJenisAset } =
    useQuery(
      ['jenisAset', 'dropdown', values?.idKategoriAset],
      () =>
        PermintaanAsetApi.getDropdownJenisAset({
          id_kategori_aset: values?.idKategoriAset,
        }),
      { enabled: !!values?.idKategoriAset }
    );

  const {
    data: dropdownUnitProduksi,
    isFetching: loadingDropdownUnitProduksi,
  } = useQuery(['unitProduksi', 'dropdown'], () =>
    PermintaanAsetApi.getDropdownUnitProduksi()
  );

  const { data: dropdownKeperluan, isFetching: loadingDropdownKeperluan } =
    useQuery(['keperluan', 'dropdown'], () =>
      PermintaanAsetApi.getDropdownKeperluan()
    );

  const {
    data: dropdownMetodePengadaan,
    isFetching: loadingDropdownMetodePengadaan,
  } = useQuery(['metodePengadaan', 'dropdown'], () =>
    PermintaanAsetApi.getDropdownMetodePengadaan()
  );

  console.log('cuy', values);

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ show: false, data: {}, type: '' })}
      scrollable
      size="lg"
    >
      <Modal.Header closeButton>Tambah Item Aset Yang Diminta</Modal.Header>
      <Modal.Body>
        {modalConfig?.type === 'read' ? (
          <DetailModal />
        ) : (
          <>
            <SelectSearch
              label="Entitas Aset"
              option={dropdownEntitasAset}
              loading={loadingDropdownEntitasAset}
              name="idEntitasAset"
              placeholder="Pilih Entitas Aset"
              defaultValue={
                values?.idEntitasAset
                  ? {
                      value: values?.idEntitasAset,
                      label: values?.namaEntitasAset,
                    }
                  : undefined
              }
              onChange={(val) =>
                setValues({
                  ...values,
                  idEntitasAset: val.value,
                  namaEntitasAset: val.label,
                })
              }
              error={errors.idEntitasAset && touched.idEntitasAset && true}
              errorText={
                errors.idEntitasAset &&
                touched.idEntitasAset &&
                errors.idEntitasAset
              }
            />

            <SelectSearch
              label="Grup Aset"
              option={dropdownGrupAset}
              loading={loadingDropdownGrupAset}
              name="idGrupAset"
              placeholder="Pilih grup aset"
              defaultValue={
                values?.idGrupAset
                  ? {
                      value: values?.idGrupAset,
                      label: values?.namaGrupAset,
                    }
                  : undefined
              }
              onChange={(val) =>
                val.value !== '4'
                  ? setValues({
                      ...values,
                      idGrupAset: val.value,
                      namaGrupAset: val.label,
                      metodePengadaan: 'pembelian',
                      metodePengadaanLabel: 'Pembelian',
                    })
                  : setValues({
                      ...values,
                      idGrupAset: val.value,
                      namaGrupAset: val.label,
                      metodePengadaan: undefined,
                      metodePengadaanLabel: '',
                    })
              }
              error={errors.idGrupAset && touched.idGrupAset && true}
              errorText={
                errors.idGrupAset && touched.idGrupAset && errors.idGrupAset
              }
            />
            <SelectSearch
              label="Kategori Aset"
              name="idKategoriAset"
              placeholder="Pilih kategori aset"
              defaultValue={
                values?.idKategoriAset
                  ? {
                      value: values?.idKategoriAset,
                      label: values?.namaKategoriAset,
                    }
                  : undefined
              }
              option={dropdownKategoriAset}
              loading={loadingDropdownKategoriAset}
              isDisabled={Boolean(!values?.idGrupAset)}
              onChange={(val) =>
                setValues({
                  ...values,
                  idKategoriAset: val.value,
                  namaKategoriAset: val.label,
                })
              }
              error={errors.idKategoriAset && touched.idKategoriAset && true}
              errorText={
                errors.idKategoriAset &&
                touched.idKategoriAset &&
                errors.idKategoriAset
              }
            />
            <SelectSearch
              label="Jenis Aset"
              name="idJenisAset"
              placeholder="Pilih jenis aset"
              defaultValue={
                values?.idJenisAset
                  ? {
                      value: values?.idJenisAset,
                      label: values?.namaJenisAset,
                    }
                  : undefined
              }
              option={dropdownJenisAset}
              loading={loadingDropdownJenisAset}
              isDisabled={Boolean(!values?.idKategoriAset)}
              onChange={(val) =>
                setValues({
                  ...values,
                  idJenisAset: val.value,
                  namaJenisAset: val.label,
                })
              }
              error={errors.idJenisAset && touched.idJenisAset && true}
              errorText={
                errors.idJenisAset && touched.idJenisAset && errors.idJenisAset
              }
            />

            <Input
              label="Nama Aset"
              type="text"
              name="namaAset"
              placeholder="Masukan nama aset"
              value={values?.namaAset}
              onChange={handleChange}
              error={errors.namaAset && touched.namaAset && true}
              errorText={errors.namaAset && touched.namaAset && errors.namaAset}
            />

            <SelectSearch
              label="Unit Organisasi"
              name="idUnitProduksi"
              placeholder="Pilih unit organisasi"
              defaultValue={
                values?.idUnitProduksi
                  ? {
                      value: values?.idUnitProduksi ?? undefined,
                      label: values?.namaUnitProduksi ?? '',
                    }
                  : undefined
              }
              option={dropdownUnitProduksi}
              loading={loadingDropdownUnitProduksi}
              onChange={(val) =>
                setValues({
                  ...values,
                  idUnitProduksi: val.value,
                  namaUnitProduksi: val.label,
                })
              }
              error={errors.idUnitProduksi && touched.idUnitProduksi && true}
              errorText={
                errors.idUnitProduksi &&
                touched.idUnitProduksi &&
                errors.idUnitProduksi
              }
            />

            <SelectSearch
              label="Keperluan"
              name="keperluan"
              placeholder="Pilih keperluan"
              defaultValue={
                values?.keperluan
                  ? {
                      value: values?.keperluan,
                      label: values?.keperluanLabel,
                    }
                  : undefined
              }
              option={dropdownKeperluan}
              loading={loadingDropdownKeperluan}
              onChange={(val) =>
                setValues({
                  ...values,
                  keperluan: val.value,
                  keperluanLabel: val.label,
                })
              }
              error={errors.keperluan && touched.keperluan && true}
              errorText={
                errors.keperluan && touched.keperluan && errors.keperluan
              }
            />

            <SelectSearch
              key={values?.metodePengadaan}
              label="Metode Pengadaan"
              name="metodePengadaan"
              placeholder="Pilih metode pengadaan"
              isDisabled={!values?.idGrupAset || values?.idGrupAset !== '4'}
              defaultValue={
                values?.metodePengadaan
                  ? {
                      value: values?.metodePengadaan ?? undefined,
                      label: values?.metodePengadaanLabel ?? '',
                    }
                  : undefined
              }
              option={dropdownMetodePengadaan}
              loading={loadingDropdownMetodePengadaan}
              onChange={(val) =>
                setValues({
                  ...values,
                  metodePengadaan: val.value,
                  metodePengadaanLabel: val.label,
                })
              }
              error={errors.metodePengadaan && touched.metodePengadaan && true}
              errorText={
                errors.metodePengadaan &&
                touched.metodePengadaan &&
                errors.metodePengadaan
              }
            />

            <TextArea
              label="Keterangan"
              name="keterangan"
              placeholder="Masukan keterangan"
              value={values?.keterangan}
              onChange={handleChange}
            />
          </>
        )}
        <Card>
          <Tab.Container defaultActiveKey="tabGambar">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="tabGambar">Gambar</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tabFile">File</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Tab.Content>
              <Tab.Pane eventKey="tabGambar">
                <div className="m-2">
                  <TabGambar readOnly={modalConfig?.type === 'read' && true} />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="tabFile">
                <div className="m-2">
                  <TabFile readOnly={modalConfig?.type === 'read' && true} />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <ButtonBack
          onClick={() =>
            setModalConfig({ ...modalConfig, data: {}, show: false })
          }
        />
        {modalConfig?.type === 'add' && (
          <ButtonCreate onClick={handleSubmit} loading={isSubmitting} />
        )}
        {modalConfig?.type === 'update' && (
          <ButtonUpdate
            variant="success"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
