import { useFormikContext } from 'formik';
import {
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  DataStatus,
  InputCurrency,
} from 'components';
import { calcTotalProgress } from '../helpers/calcTotalProgress';

export const TableRealisasiPekerjaan = ({ readOnly }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <div className="font-weight-bold" style={{ fontSize: '14px' }}>
        Realisasi Pekerjaan
      </div>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>Rincian Pekerjaan</Th>
            <Th width={150}>Progress Sebelumnya</Th>
            <Th width={150}>Progress Saat Ini</Th>
            <Th width={150}>Progress s/d Saat Ini</Th>
          </Tr>
        </THead>

        <TBody>
          {values?.pekerjaan?.length ? (
            values?.pekerjaan?.map((val, index) => (
              <Tr key={val.id_work_order_rinc}>
                <TdFixed textCenter>{index + 1}</TdFixed>
                <Td>{val.nama_pekerjaan ?? '-'}</Td>
                <Td textRight>
                  {readOnly
                    ? parseFloat(val.akumulasi_progress ?? 0) -
                      parseFloat(val.persentase_progress ?? 0)
                    : parseFloat(val.akumulasi_progress ?? 0)}
                  %
                </Td>
                <Td textRight noPadding={!readOnly}>
                  {readOnly ? (
                    `${parseFloat(val.persentase_progress ?? 0)}%`
                  ) : (
                    <InputCurrency
                      noMargin
                      prefix=""
                      allowLeadingZeros={false}
                      wrapperClassName="m-0"
                      className="text-right m-0"
                      value={values?.pekerjaan?.[index]?.persentase_progress}
                      onChange={(e) =>
                        setFieldValue(
                          `pekerjaan.${index}.persentase_progress`,
                          e
                        )
                      }
                      isAllowed={(e) => {
                        const { floatValue } = e;

                        if (!floatValue) return true;

                        return (
                          floatValue <=
                          100 - parseFloat(val.akumulasi_progress ?? 0)
                        );
                      }}
                    />
                  )}
                </Td>
                <Td textRight>{parseFloat(val?.akumulasi_progress ?? 0)} %</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={8}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
          {!!values?.pekerjaan?.length && (
            <Tr className="font-weight-bold">
              <Td textRight colSpan={4}>
                Total Progress Seluruh Rincian Pekerjaan
              </Td>
              <Td textRight>
                {calcTotalProgress(values?.pekerjaan ?? []).totalProgress ?? 0}%
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};
