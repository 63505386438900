import {
  ButtonCreate,
  ButtonDelete,
  ButtonUpdate,
} from "@bhawanadevteam/react-core";
import {
  ActionButton,
  Input,
  SelectSearch,
  TBody,
  Table,
  Td,
  Th,
  ThFixed,
  Tr,
} from "components";
import Thead from "components/Table/THead";
import { Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import * as Yup from "yup";

export const TableAnalisaChecklistContent = ({ dataTable, type, action }) => {
  const { values, setValues } = useFormikContext();

  const onDeleteDataTable = (ind) => {
    const filterData = dataTable?.filter((val, index) => index !== ind);
    setValues({ ...values, detail: filterData });
  };

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Kode Item Aset</ThFixed>
            <Th>Item Aset</Th>
            <Th>Kategori Aset</Th>
            <Th>Jenis Aset</Th>
            <Th>Keterangan</Th>
            {action !== "detail" && <ThFixed>Aksi</ThFixed>}
          </Tr>
        </Thead>
        <TBody>
          {/* {action !== "detail" && <FormInputTable action="create" />} */}

          {dataTable?.map((val, ind) => (
            <Tr key={ind}>
              <Td>{ind + 1}</Td>
              <Td>{val.kodeItemAset ?? "-"}</Td>
              <Td>{val.namaItemAset ?? "-"}</Td>
              <Td>{val.namaKelompokAset ?? "-"}</Td>
              <Td>{val.namaJenisAset ?? "-"}</Td>
              <Td>{val.keterangan ?? "-"}</Td>
              {action !== "detail" && (
                <Td>
                  <ButtonGroup>
                    <ButtonDelete
                      noText
                      icon
                      size="sm"
                      onClick={() => onDeleteDataTable(ind)}
                    />
                  </ButtonGroup>
                </Td>
              )}
            </Tr>
          ))}
        </TBody>
      </Table>
    </div>
  );
};
