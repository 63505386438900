import { createContext } from 'react';
import { TransferAlatMesinWorkOrderApi } from 'api';
import { useQuery } from 'react-query';

export const DropdownContext = createContext();

export const DropdownProvider = ({ children }) => {
  const STALE_DURATION_MINUTES = 4 * 60 * 1000; // 4 MINUTES

  const dropdownAlat = useQuery(
    ['dropdown', 'alat'],
    () =>
      TransferAlatMesinWorkOrderApi.getDropdownAset({ id_grup_aset: 4 }).then(
        (res) => {
          const data = res.data.data.map((val) => ({
            ...val,
            value: val.id_item_aset,
            label: val.nama_item_aset,
          }));
          return data;
        },
      ),
    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownAset = useQuery(
    ['dropdown', 'aset'],
    () =>
      TransferAlatMesinWorkOrderApi.getDropdownAset().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item_aset,
          label: val.nama_item_aset,
        }));
        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownKaryawan = useQuery(
    ['dropdown', 'karyawan'],
    () =>
      TransferAlatMesinWorkOrderApi.getDropdownKaryawan().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_karyawan,
          label: val.nama_karyawan,
        }));

        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  return (
    <DropdownContext.Provider
      value={{
        dropdownAlat,
        dropdownAset,
        dropdownKaryawan,
      }}
    >
      {children}
    </DropdownContext.Provider>
  );
};

export const withDropdownProvider = (Component) => (props) => {
  return (
    <DropdownProvider>
      <Component {...props} />
    </DropdownProvider>
  );
};
