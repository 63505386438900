import { useState, useContext } from 'react';
import { Nav, Tab, Card, ButtonGroup } from 'react-bootstrap';
import { useFormikContext, useFormik } from 'formik';
import * as yup from 'yup';
import {
  IoCheckmarkOutline,
  IoCloseOutline,
  IoAddOutline,
} from 'react-icons/io5';
import {
  Table,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  Td,
  TdFixed,
  DataStatus,
  Input,
  ActionButton,
  SelectSearch,
  UpdateButton,
  DeleteButton,
} from 'components';
import { RupiahConvert } from 'utilities';
import { WorkOrderContext } from '../context';

export const TabRincianBiaya = ({ createable }) => {
  const {
    dropdownBahan,
    dropdownUpah,
    dropdownAlat,
    dropdownSubkon,
    dropdownOverhead,
  } = useContext(WorkOrderContext);

  return (
    <>
      <div className="font-weight-bold" style={{ fontSize: '14px' }}>
        Rincian Biaya
      </div>

      <Card className="mb-3">
        <Tab.Container defaultActiveKey="bahan">
          <Card.Header>
            <Nav variant="tabs" defaultActiveKey="bahan">
              <NavTab eventKey="bahan" title="Bahan" />
              <NavTab eventKey="upah" title="Upah" />
              <NavTab eventKey="alat" title="Alat Mesin" />
              <NavTab eventKey="subkon" title="Subkon" />
              <NavTab eventKey="overhead" title="Overhead" />
            </Nav>
          </Card.Header>

          <Card.Body className="p-3">
            <Tab.Content>
              <TabPane
                eventKey="bahan"
                component={
                  <TableRincianBiaya
                    buaso="bahan"
                    createable={createable}
                    dropdown={dropdownBahan}
                  />
                }
              />
              <TabPane
                eventKey="upah"
                component={
                  <TableRincianBiaya
                    buaso="upah"
                    createable={createable}
                    dropdown={dropdownUpah}
                  />
                }
              />
              <TabPane
                eventKey="alat"
                component={
                  <TableRincianBiaya
                    buaso="alat_mesin"
                    createable={createable}
                    dropdown={dropdownAlat}
                  />
                }
              />
              <TabPane
                eventKey="subkon"
                component={
                  <TableRincianBiaya
                    buaso="subkon"
                    createable={createable}
                    dropdown={dropdownSubkon}
                  />
                }
              />
              <TabPane
                eventKey="overhead"
                component={
                  <TableRincianBiaya
                    buaso="overhead"
                    createable={createable}
                    dropdown={dropdownOverhead}
                  />
                }
              />
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </Card>
    </>
  );
};

export const TableRincianBiaya = ({ createable, buaso, dropdown }) => {
  const { values, setValues } = useFormikContext();
  const [editIndex, setEditIndex] = useState(null);

  const title = {
    bahan: 'Bahan',
    upah: 'Upah',
    alat_mesin: 'Alat Mesin',
    subkon: 'Subkon',
    overhead: 'Overhead',
  };

  const onEditItem = (index) => {
    setEditIndex(index);
  };

  const onCloseEditing = () => {
    setEditIndex(null);
  };

  const onDeleteItem = (index) => {
    setValues((prev) => ({
      ...prev,
      [buaso]: prev?.[buaso].toSpliced(index, 1),
    }));
  };

  return (
    <>
      <div className="font-weight-bold" style={{ fontSize: '14px' }}>
        List Data {title[buaso]}
      </div>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>Kode Item</Th>
            <Th>Item {title[buaso]}</Th>
            <Th>Harga Satuan</Th>
            <Th width={100}>Qty</Th>
            <Th>Satuan</Th>
            <Th>Subtotal</Th>
            {createable && <ThFixed>Aksi</ThFixed>}
          </Tr>
        </THead>

        <TBody>
          {createable && (
            <InputRincianBiaya buaso={buaso} dropdown={dropdown} />
          )}
          {values[buaso]?.length > 0 ? (
            values[buaso]?.map((val, index) => {
              if (editIndex === index) {
                return (
                  <InputRincianBiaya
                    buaso={buaso}
                    dropdown={dropdown}
                    isEditing={true}
                    index={index}
                    data={val}
                    onCloseEditing={onCloseEditing}
                  />
                );
              }

              return (
                <Tr key={index}>
                  <TdFixed textCenter>{index + 1}</TdFixed>
                  <Td>{val?.[`kode_item_${buaso}`] ?? '-'}</Td>
                  <Td>{val?.[`nama_item_${buaso}`] ?? '-'}</Td>
                  <Td textRight>
                    {
                      RupiahConvert(String(Math.round(val.harga_satuan ?? 0)))
                        .getWithComa
                    }
                  </Td>
                  <Td textRight>
                    {Math.round(val?.[`qty_work_order_${buaso}`] ?? 0)}
                  </Td>
                  <Td>{val.satuan}</Td>
                  <Td textRight>
                    {
                      RupiahConvert(
                        String(
                          Math.round(
                            parseFloat(val.harga_satuan ?? 0) *
                              parseFloat(val?.[`qty_work_order_${buaso}`]),
                          ),
                        ),
                      ).getWithComa
                    }
                  </Td>
                  {createable && (
                    <td className="p-0 m-0">
                      <ButtonGroup className="btn-block">
                        <UpdateButton
                          size="sm"
                          onClick={() => onEditItem(index)}
                        />
                        <DeleteButton
                          size="sm"
                          onClick={() => onDeleteItem(index)}
                        />
                      </ButtonGroup>
                    </td>
                  )}
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={createable ? 8 : 7}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};

export const InputRincianBiaya = ({
  isEditing = false,
  buaso,
  index,
  data = null,
  onCloseEditing,
  dropdown,
}) => {
  const formikContext = useFormikContext();

  const validationSchema = yup.object().shape({
    [`id_item_${buaso}`]: yup.string().required(),
    [`qty_work_order_${buaso}`]: yup.string().required(),
  });

  const { values, setValues, setFieldValue, errors, touched, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        [`kode_item_${buaso}`]: data?.[`kode_item_${buaso}`] ?? '',
        [`id_item_${buaso}`]: data?.[`id_item_${buaso}`] ?? '',
        [`nama_item_${buaso}`]: data?.[`nama_item_${buaso}`] ?? '',
        [`id_item_${buaso}`]: data?.[`id_item_${buaso}`] ?? '',
        [`qty_work_order_${buaso}`]: data?.[`qty_work_order_${buaso}`] ?? '0',
        harga_satuan: data?.harga_satuan,
        satuan: data?.satuan,
      },
      validationSchema,
      onSubmit: (values) => {
        if (isEditing) {
          formikContext.setValues((prev) => ({
            ...prev,
            [buaso]: prev[buaso].toSpliced(index, 1, values),
          }));

          onCloseEditing();
          return;
        }

        formikContext.setValues((prev) => ({
          ...prev,
          [buaso]: prev[buaso].toSpliced(0, 0, values),
        }));
      },
    });

  return (
    <tr className="p-0 m-0">
      <td colSpan={3} className="p-0 m-0">
        <SelectSearch
          noMargin
          placeholder="Pilih salah satu..."
          defaultValue={
            values?.[`id_item_${buaso}`]
              ? dropdown?.data?.find(
                  (val) => val.value === values?.[`id_item_${buaso}`],
                )
              : null
          }
          option={dropdown?.data}
          loading={dropdown?.isLoading}
          onChange={(e) =>
            setValues((prev) => ({
              ...prev,
              [`id_item_${buaso}`]: e.value,
              [`kode_item_${buaso}`]: e.kode_item,
              [`nama_item_${buaso}`]: e.nama_item,
              harga_satuan: e.hps_pakai,
              satuan: e.satuan_pakai,
            }))
          }
          error={errors[`id_item_${buaso}`] && touched[`id_item_${buaso}`]}
        />
      </td>
      <Td textRight>
        {
          RupiahConvert(String(Math.round(values.harga_satuan ?? 0)))
            .getWithComa
        }
      </Td>
      <td className="p-0 m-0">
        <Input
          noMargin
          className="text-right"
          placeholder="Qty"
          value={values[`qty_work_order_${buaso}`]}
          onChange={(e) =>
            setFieldValue(`qty_work_order_${buaso}`, e.target.value)
          }
          error={
            errors[`qty_work_order_${buaso}`] &&
            touched[`qty_work_order_${buaso}`]
          }
        />
      </td>
      <Td>{values.satuan}</Td>
      <Td textRight>
        {
          RupiahConvert(
            String(
              Math.round(
                parseFloat(values.harga_satuan ?? 0) *
                  parseFloat(values?.[`qty_work_order_${buaso}`] ?? 0),
              ),
            ),
          ).getWithComa
        }
      </Td>
      <td className="p-0 m-0">
        <ButtonGroup className="btn-block">
          {isEditing ? (
            <>
              <ActionButton
                size="sm"
                className="bg-success"
                text={<IoCheckmarkOutline />}
                onClick={handleSubmit}
              />
              <ActionButton
                size="sm"
                className="bg-danger"
                text={<IoCloseOutline />}
                onClick={onCloseEditing}
              />
            </>
          ) : (
            <ActionButton
              size="sm"
              className="btn-block"
              text={<IoAddOutline />}
              onClick={handleSubmit}
            />
          )}
        </ButtonGroup>
      </td>
    </tr>
  );
};

const NavTab = ({ eventKey, title }) => (
  <Nav.Item>
    <Nav.Link eventKey={eventKey}>{title}</Nav.Link>
  </Nav.Item>
);

const TabPane = ({ eventKey, component }) => (
  <Tab.Pane eventKey={eventKey}>{component}</Tab.Pane>
);
