import {
  ButtonCreate,
  ButtonDetail,
  ButtonUpdate,
  ButtonVerify,
  tableNumber,
} from '@bhawanadevteam/react-core';
import {
  Alert,
  ApprovalStatusButton,
  DataStatus,
  Pagination,
  TBody,
  THead,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
  Tr,
} from 'components';
import React, { useState } from 'react';
import {
  formInitialValuesRegisterAset,
  formSubmitValueMapperRegisterAset,
  getStatusApprovalLabel,
} from '../__PermintaanAsetUtils__';
import { ButtonGroup, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DateConvert } from 'utilities';
import { ModalRegistrasiAset } from './ModalRegistrasiAset';
import { Formik } from 'formik';
import { useMutation } from 'react-query';
import PenerimaanAsetApi from '../__PenerimaanAsetApi__/PenerimaanAsetApi';
import * as Yup from 'yup';

export const TableList = ({
  paginationConfig,
  setPaginationConfig,
  alertConfig,
  setAlertConfig,
  query,
  type,
  refetch,
}) => {
  const history = useHistory();
  const [modalConfig, setModalConfig] = useState({ show: false, id: '' });
  const onPaginationChange = ({ selected }) =>
    setPaginationConfig({
      ...paginationConfig,
      [type]: { ...paginationConfig[type], page: selected + 1 },
    });

  const onPaginationDataLengthChange = (e) =>
    setPaginationConfig({
      ...paginationConfig,
      [type]: {
        ...paginationConfig[type],
        page: 1,
        per_page: e.target.value,
      },
    });

  const createPenerimaanRegisterAset = useMutation((data) => {
    if (type === 'tanah') {
      return PenerimaanAsetApi.createRegisterAsetTanah(data);
    } else if (type === 'bangunan') {
      return PenerimaanAsetApi.createRegisterAsetBangunan(data);
    } else if (type === 'kendaraan') {
      return PenerimaanAsetApi.createRegisterAsetKendaraan(data);
    } else if (type === 'alatMesin') {
      return PenerimaanAsetApi.createRegisterAsetAlatMesin(data);
    } else if (type === 'inventaris') {
      return PenerimaanAsetApi.createRegisterAsetInventaris(data);
    } else if (type === 'sistem') {
      return PenerimaanAsetApi.createRegisterAsetSistem(data);
    }
    return PenerimaanAsetApi.createRegisterAsetLainnya(data);
  });

  const formValidationSchema = Yup.object().shape({
    bulan_perolehan: Yup.string().required('Masukan bulan perolehan'),
    tahun_perolehan: Yup.string().required('Masukan tahun perolehan'),
    id_satuan_pakai: Yup.string().required('Pilih satuan pakai'),
    id_kondisi_aset: Yup.string().required('Pilih kondisi aset'),
    id_karyawan: Yup.string().required('Pilih karyawan penanggung jawab'),
    id_provinsi: Yup.string().required('Pilih provinsi'),
    id_kabupaten: Yup.string().required('Pilih kabupaten'),
    id_kecamatan: Yup.string().required('Pilih kecamatan'),
    id_desa: Yup.string().required('Pilih desa'),
  });

  const formSubmitHandler = (value, { setSubmitting }) => {
    createPenerimaanRegisterAset
      .mutateAsync(formSubmitValueMapperRegisterAset(value))
      .then(() => {
        refetch();
        setAlertConfig({
          ...alertConfig,
          [type]: {
            ...alertConfig?.[type],
            variant: 'primary',
            show: true,
            text: 'Data berhasil disimpan!!!',
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          ...alertConfig,
          [type]: {
            ...alertConfig?.[type],
            variant: 'danger',
            show: true,
            text: err?.response?.data?.message,
          },
        });
      })
      .finally(() => {
        setModalConfig({ ...modalConfig, show: false, id: undefined });
        setSubmitting(false);
      });
  };

  return query.isFetching ? (
    <DataStatus text="Memuat Data..." loading />
  ) : (
    <>
      <Alert
        show={alertConfig?.[type]?.show}
        text={alertConfig?.[type]?.text}
        variant={alertConfig?.[type]?.variant}
        showCloseButton
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            [type]: {
              ...alertConfig?.[type],
              show: false,
            },
          })
        }
      />
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Permintaan Aset</ThFixed>
            <ThFixed>Informasi Purchase Order Aset</ThFixed>
            <Th>Vendor</Th>
            <Th>Item Aset Yang Diterima</Th>
            <ThFixed>Registrasi Aset</ThFixed>
            <ThFixed>Status Approval</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {query?.data?.data?.length > 0 ? (
            query?.data?.data?.map((val, index) => (
              <Tr key={index}>
                <TdFixed>
                  {tableNumber({
                    pageCurrent: paginationConfig?.[type]?.page,
                    dataLength: paginationConfig?.[type]?.per_page,
                    index,
                  })}
                </TdFixed>
                <TdFixed>
                  <ButtonGroup>
                    <ButtonDetail
                      icon
                      noText
                      variant="primary"
                      onClick={() =>
                        history.push(
                          '/transaksi/penerimaan-aset/detail/' +
                            val?.id_penerimaan_aset,
                          {
                            type,
                          }
                        )
                      }
                    />
                    {val?.status_approval === 'REV' && (
                      <ButtonUpdate
                        icon
                        noText
                        variant="success"
                        onClick={() =>
                          history.push(
                            '/transaksi/penerimaan-aset/ubah/' +
                              val?.id_penerimaan_aset
                          )
                        }
                      />
                    )}
                  </ButtonGroup>
                </TdFixed>
                <Td>
                  <div>
                    {DateConvert(new Date(val?.tgl_penerimaan_aset)).custom}
                  </div>
                  <div>{val.no_penerimaan_aset ?? '-'}</div>
                </Td>
                <Td>
                  <div>
                    {DateConvert(new Date(val?.tgl_purchase_order_aset)).custom}
                  </div>
                  <div>{val.no_purchase_order_aset ?? '-'}</div>
                </Td>
                <Td>{val?.nama_vendor ?? '-'}</Td>
                <Td>{val?.nama_aset ?? '-'}</Td>
                <Td className="text-center">
                  {val?.id_aset_yang_diterima ? (
                    <ButtonVerify icon noText variant="success" />
                  ) : (
                    <ButtonCreate
                      icon
                      noText
                      tooltipText="Registrasi Aset"
                      disabled={val?.status_approval !== 'APP'}
                      onClick={() =>
                        setModalConfig({
                          ...modalConfig,
                          show: true,
                          id: val?.id_penerimaan_aset,
                        })
                      }
                    />
                  )}
                </Td>
                <Td>
                  <ApprovalStatusButton
                    variant={
                      getStatusApprovalLabel(val?.status_approval).variant
                    }
                  >
                    {getStatusApprovalLabel(val?.status_approval).label}
                  </ApprovalStatusButton>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={11}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
      <Pagination
        dataLength={paginationConfig?.[type]?.per_page}
        dataNumber={
          paginationConfig?.[type]?.page * paginationConfig?.[type]?.per_page -
          paginationConfig?.[type]?.per_page +
          1
        }
        dataPage={
          paginationConfig?.[type]?.dataCount <
          paginationConfig?.[type]?.per_page
            ? paginationConfig?.[type]?.dataCount
            : paginationConfig?.[type]?.page *
              paginationConfig?.[type]?.per_page
        }
        dataCount={query?.data?.data_count}
        currentPage={paginationConfig?.[type]?.page}
        totalPage={query?.data?.total_page}
        onPaginationChange={onPaginationChange}
        onDataLengthChange={onPaginationDataLengthChange}
      />
      {modalConfig?.show && (
        <Formik
          initialValues={formInitialValuesRegisterAset()}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          <ModalRegistrasiAset
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
            type={type}
          />
        </Formik>
      )}
    </>
  );
};
