// React
import React, { useState, useEffect } from 'react';

// Component
import {
  CRUDLayout,
  Th,
  THead,
  TBody,
  Tr,
  Td,
  TdFixed,
  ThFixed,
  DataStatus,
} from '../../../../../../components';
import { DateConvert } from 'utilities';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { AsetTanahApi } from 'api';
import { Button } from 'react-bootstrap';

const PemeliharaanAset = ({ data }) => {
  const { tipe, id } = useParams();
  const [dataMaintenance, setDataMaintenance] = useState({});
  const [loadingMaintenance, setLoadingMaintenance] = useState(false);

  const STATUS_WORK_ORDER = {
    open: {
      variant: 'outline-warning',
      textColor: 'text-warning',
      label: 'ON PROGRESS',
    },
    close: {
      variant: 'outline-secondary',
      textColor: 'text-secondary',
      label: 'CLOSED',
    },
  };

  const getDataMaintenance = () => {
    setLoadingMaintenance(true);
    let single = '';

    if (tipe == 'ubah' || tipe == 'detail') {
      single = id;
    } else {
      single = data.id_item_aset.id_item_aset;
    }

    AsetTanahApi.getMaintenance({ id_item_aset: single })
      .then((res) =>
        setDataMaintenance({
          ...data,
          maintenance: res.data.data ?? [],
        })
      )
      .finally(() => setLoadingMaintenance(false));
  };

  useEffect(() => {
    getDataMaintenance();
    return () => {};
  }, []);

  return loadingMaintenance ? (
    <DataStatus text="Memuat Data..." loading />
  ) : (
    <CRUDLayout.Table>
      <THead>
        <Tr>
          <ThFixed>No.</ThFixed>
          <ThFixed>Informasi Work Order</ThFixed>
          <ThFixed>Informasi Maintenance Aset</ThFixed>
          <Th>Keterangan</Th>
          <ThFixed>Status</ThFixed>
        </Tr>
      </THead>

      <TBody>
        {dataMaintenance?.maintenance?.length > 0 ? (
          dataMaintenance?.maintenance?.map((val, index) => {
            const status = STATUS_WORK_ORDER[val.status_work_order ?? 'open'];
            return (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>
                  <div>
                    {DateConvert(new Date(val.tgl_work_order)).defaultDMY}
                  </div>
                  <div>{val?.no_work_order ?? '-'}</div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {
                      DateConvert(new Date(val.tgl_maintenance_request))
                        .defaultDMY
                    }
                  </div>
                  <div>{val?.no_maintenance_request ?? '-'}</div>
                </TdFixed>
                <Td>{val.keterangan}</Td>
                <TdFixed>
                  <Button
                    variant={status.variant}
                    textColor={status.textColor}
                    className="text-nowrap btn-block btn-sm"
                  >
                    {status.label}
                  </Button>
                </TdFixed>
              </Tr>
            );
          })
        ) : (
          <Tr>
            <TdFixed colSpan="5">
              <DataStatus text="Tidak Ada Data" />
            </TdFixed>
          </Tr>
        )}
      </TBody>
    </CRUDLayout.Table>
  );
};

export default PemeliharaanAset;
