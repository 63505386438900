import {
  ButtonCreate,
  ButtonDelete,
  ButtonUpdate,
} from '@bhawanadevteam/react-core';
import {
  ActionButton,
  Input,
  SelectSearch,
  TBody,
  Table,
  Td,
  Th,
  ThFixed,
  Tr,
} from 'components';
import Thead from 'components/Table/THead';
import { Formik, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import * as Yup from 'yup';

export const TableAnalisaChecklistContent = ({
  dropdown,
  loadingDropdown,
  dataTable,
  type,
  action,
}) => {
  const { values, setValues } = useFormikContext();
  const [isUpdate, setIsUpdate] = useState('');

  const onDeleteDataTable = (ind) => {
    const filterData = dataTable?.filter((val, index) => index !== ind);
    setValues({ ...values, [`detail_${type}`]: filterData });
  };

  const FormInputTable = ({ action, data }) => {
    const formInitialValuesTable = {
      id_item_checklist: data?.id_item_checklist ?? undefined,
      nama_item_checklist: data?.nama_item_checklist ?? undefined,
      kode_item_checklist: data?.kode_item_checklist ?? undefined,
    };
    const formValidationSchemaTable = Yup.object().shape({
      id_item_checklist: Yup.string().required(`Pilih item checklist ${type}`),
    });

    const formSubmitHandlerTable = (value) => {
      action === 'create' &&
        setValues({ ...values, [`detail_${type}`]: [...dataTable, value] });

      action === 'update' &&
        setValues({
          ...values,
          [`detail_${type}`]: dataTable?.map((val, ind) =>
            ind === isUpdate ? value : val
          ),
        });

      setIsUpdate(undefined);
    };

    return (
      <Formik
        initialValues={formInitialValuesTable}
        validationSchema={formValidationSchemaTable}
        onSubmit={formSubmitHandlerTable}
      >
        {({
          values: valuesTable,
          setValues: setValuesTable,
          errors,
          touched,
          handleSubmit,
        }) => (
          <Tr>
            <Td colSpan="3">
              <SelectSearch
                key={valuesTable.id_item_checklist}
                option={dropdown}
                name="id_item_checklist"
                defaultValue={
                  valuesTable?.id_item_checklist
                    ? {
                        label: valuesTable?.nama_item_checklist,
                        value: valuesTable?.id_item_checklist,
                      }
                    : null
                }
                placeholder={`Pilih Item checklist ${type}`}
                loading={loadingDropdown}
                onChange={(val) =>
                  setValuesTable({
                    ...valuesTable,
                    id_item_checklist: val.value,
                    nama_item_checklist: val.label,
                    kode_item_checklist: val.kode,
                  })
                }
                error={
                  errors.id_item_checklist && touched.id_item_checklist && true
                }
                errorText={
                  touched.id_item_checklist && errors.id_item_checklist
                }
              />
            </Td>
            <Td>
              {action === 'update' ? (
                <ButtonGroup>
                  <ActionButton
                    text={<AiOutlineCheck />}
                    variant="success"
                    onClick={handleSubmit}
                    size="sm"
                  />
                  <ActionButton
                    text={<AiOutlineClose />}
                    variant="danger"
                    onClick={() => setIsUpdate(undefined)}
                    size="sm"
                  />
                </ButtonGroup>
              ) : (
                <ButtonCreate
                  noText
                  icon
                  className="px-4"
                  size="sm"
                  onClick={handleSubmit}
                />
              )}
            </Td>
          </Tr>
        )}
      </Formik>
    );
  };

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Kode</ThFixed>
            <Th className="text-capitalize">Item {type}</Th>
            {action !== 'detail' && <ThFixed>Aksi</ThFixed>}
          </Tr>
        </Thead>
        <TBody>
          {action !== 'detail' && <FormInputTable action="create" />}

          {dataTable?.map((val, ind) =>
            isUpdate === ind ? (
              <FormInputTable action="update" data={val} />
            ) : (
              <Tr key={ind}>
                <Td>{ind + 1}</Td>
                <Td>{val.kode_item_checklist ?? '-'}</Td>
                <Td>{val.nama_item_checklist ?? '-'}</Td>
                {action !== 'detail' && (
                  <Td>
                    <ButtonGroup>
                      <ButtonUpdate
                        noText
                        icon
                        variant="success"
                        size="sm"
                        onClick={() => setIsUpdate(ind)}
                      />
                      <ButtonDelete
                        noText
                        icon
                        size="sm"
                        onClick={() => onDeleteDataTable(ind)}
                      />
                    </ButtonGroup>
                  </Td>
                )}
              </Tr>
            )
          )}
        </TBody>
      </Table>
    </div>
  );
};
