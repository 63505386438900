import {
  ButtonCreate,
  ButtonDetail,
  ButtonVerify,
} from '@bhawanadevteam/react-core';
import { TBody, Table, Td, Th, ThFixed, Tr } from 'components';
import Thead from 'components/Table/THead';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { ModalForm } from './ModalForm';

export const TablePemeliaharaanAsetContent = ({ action, data, type }) => {
  const { values } = useFormikContext();
  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
    type: '',
    key: '',
  });

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Kode Item Aset</ThFixed>
            <Th>Item Aset</Th>
            <Th>Kategori Aset</Th>
            <Th>Jenis Aset</Th>
            <Th>Keterangan</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </Thead>
        <TBody>
          {data?.length > 0 &&
            data?.map((val, ind) => (
              <Tr key={ind}>
                <Td>{ind + 1}</Td>
                <Td>{val.kode_item_aset ?? '-'}</Td>
                <Td>{val.nama_item_aset ?? '-'}</Td>
                <Td>{val.nama_kategori_aset ?? '-'}</Td>
                <Td>{val.nama_jenis_aset ?? '-'}</Td>
                <Td>{val.keterangan ?? '-'}</Td>
                <Td>
                  <ButtonGroup>
                    {action !== 'detail' ? (
                      val.status_checklist === true ? (
                        <ButtonVerify icon noText />
                      ) : (
                        <ButtonCreate
                          noText
                          icon
                          size="sm"
                          onClick={() =>
                            setModalConfig({ show: true, data: val, key: ind })
                          }
                        />
                      )
                    ) : type === 'alreadyChecklist' ? (
                      <ButtonDetail
                        noText
                        icon
                        size="sm"
                        onClick={() =>
                          setModalConfig({ show: true, data: val, key: ind })
                        }
                      />
                    ) : (
                      <ButtonDetail
                        noText
                        icon
                        size="sm"
                        onClick={() =>
                          setModalConfig({
                            show: true,
                            data: {
                              ...val,
                              analisa_checklist: values?.detail_checklist
                                ?.filter(
                                  (item) =>
                                    item.id_maintenance_request_detail ===
                                    val.id_maintenance_request_detail
                                )
                                .map((val) => ({
                                  ...val,
                                  status_value: val?.status_perlengkapan,
                                  kondisi_value: val?.kondisi_perlengkapan,
                                })),
                            },
                            key: ind,
                          })
                        }
                      />
                    )}
                  </ButtonGroup>
                </Td>
              </Tr>
            ))}
        </TBody>
      </Table>
      {modalConfig.show && (
        <ModalForm
          setModalConfig={setModalConfig}
          modalConfig={modalConfig}
          action={action}
        />
      )}
    </div>
  );
};
