import React, { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import PenerimaanAsetApi from '../__PenerimaanAsetApi__/PenerimaanAsetApi';
import { useQuery } from 'react-query';
import {
  DataStatus,
  InfoItemHorizontal,
  InputCurrency,
  NumberFormat,
  SelectSearch,
  TextArea,
} from 'components';
import { ButtonCreate, Input } from 'components2';
import { DateConvert } from 'utilities';
import { Field, Formik, useFormik, useFormikContext } from 'formik';
import { monthOptions } from '../__PermintaanAsetUtils__';

const InfoPenerimaanAset = () => {
  const { values } = useFormikContext();
  return (
    <Row>
      <Col>
        <InfoItemHorizontal
          label="Tgl. Penerimaan Aset"
          text={
            values?.tgl_penerimaan_aset
              ? DateConvert(new Date(values?.tgl_penerimaan_aset)).detail
              : '-'
          }
        />
        <InfoItemHorizontal
          label="No. Penerimaan Aset"
          text={values?.no_penerimaan_aset ?? '-'}
        />
        <InfoItemHorizontal
          label="Tgl. Purchase Order Aset"
          text={
            values?.tgl_purchase_order_aset
              ? DateConvert(new Date(values?.tgl_purchase_order_aset)).detail
              : '-'
          }
        />
        <InfoItemHorizontal
          label="No. Purchase Order Aset"
          text={values?.no_purchase_order_aset ?? '-'}
        />
      </Col>
      <Col>
        <InfoItemHorizontal
          label="Grup Aset"
          text={values?.nama_grup_aset ?? '-'}
        />
        <InfoItemHorizontal
          label="Kategori Aset"
          text={values?.nama_kategori_aset ?? '-'}
        />
        <InfoItemHorizontal
          label="Jenis Aset"
          text={values?.nama_jenis_aset ?? '-'}
        />
        <InfoItemHorizontal
          label="Item Aset Yang Diminta"
          text={values?.nama_item_aset ?? '-'}
        />
        <InfoItemHorizontal label="Keperluan" text={values?.keperluan ?? '-'} />
      </Col>
    </Row>
  );
};

const CheckInput = ({ values, handleChange, type, action }) => {
  if (type === 'kendaraan') {
    return (
      <Row>
        <Col>
          <InputCurrency
            disabled
            label="Harga Perolehan"
            name="harga_perolehan"
            decimalScale="2"
            value={parseFloat(values?.harga_perolehan ?? 0)}
          />
        </Col>
        <Col>
          <Input
            disabled={action === 'detail'}
            label="Plat Nomor"
            name="plat_nomor"
            placeholder="Masukan plat nomor"
            value={values?.plat_nomor}
            onChange={handleChange}
          />
        </Col>
      </Row>
    );
  }
  if (type === 'alatMesin') {
    return (
      <Row>
        <Col>
          <InputCurrency
            disabled
            label="Harga Perolehan"
            name="harga_perolehan"
            decimalScale="2"
            value={parseFloat(values?.harga_perolehan ?? 0)}
          />
        </Col>
        <Col>
          <Input
            disabled={action === 'detail'}
            label="No. Seri"
            name="no_seri"
            placeholder="Masukan no. seri"
            value={values?.no_seri}
            onChange={handleChange}
          />
        </Col>
      </Row>
    );
  }
  return (
    <InputCurrency
      disabled
      label="Harga Perolehan"
      name="harga_perolehan"
      decimalScale="2"
      value={parseFloat(values?.harga_perolehan ?? 0)}
    />
  );
};

const CheckInputPerTab = ({
  values,
  dropdownPabrikan,
  loadingDropdownPabrikan,
  type,
  action,
  handleChange,
  setValues,
}) => {
  if (type === 'kendaraan' || type === 'alatMesin' || type === 'inventaris') {
    return (
      <SelectSearch
        isDisabled={action === 'detail'}
        label="Pabrikan"
        placeholder="Pilih pabrikan"
        loading={loadingDropdownPabrikan}
        option={dropdownPabrikan}
        defaultValue={
          values?.id_pabrikan
            ? {
                value: values?.id_pabrikan,
                label: values?.nama_pabrikan,
              }
            : undefined
        }
        onChange={(val) =>
          setValues({
            ...values,
            id_pabrikan: val?.value,
            nama_pabrikan: val?.label,
          })
        }
      />
    );
  }
  if (type === 'tanah') {
    return (
      <Input
        disabled={action === 'detail'}
        label="No. Sertifikat Tanah"
        name="no_sertifikat"
        placeholder="Masukan no. sertifikat tanah"
        value={values?.no_sertifikat}
        onChange={handleChange}
      />
    );
  }

  return '';
};

export const ModalRegistrasiAset = ({
  setModalConfig,
  modalConfig,
  type,
  action,
}) => {
  const {
    values,
    setValues,
    handleChange,
    setFieldValue,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
  } = useFormikContext();

  const checkTitle =
    type === 'alatMesin'
      ? 'Alat Mesin'
      : type === 'sistem'
      ? 'Sistem - sistem'
      : type;

  const { isFetching: loadingDetailPenerimaanAset } = useQuery(
    ['penerimaanAset', 'single', modalConfig?.id],
    () =>
      PenerimaanAsetApi.getSingle({
        id_penerimaan_aset: modalConfig?.id,
      }).then(({ data }) =>
        setValues({
          ...values,
          tgl_penerimaan_aset: data?.tgl_penerimaan_aset,
          no_penerimaan_aset: data?.no_penerimaan_aset,
          tgl_purchase_order_aset: data?.tgl_purchase_order_aset,
          no_purchase_order_aset: data?.no_purchase_order_aset,
          keperluan: data?.keperluan,
          nama_entitas_aset: data?.nama_entitas_aset,
          nama_grup_aset: data?.nama_grup_aset,
          nama_kategori_aset: data?.nama_kategori_aset,
          nama_jenis_aset: data?.nama_jenis_aset,
          nama_unit_organisasi: data?.nama_unit_organisasi,
          id_entitas_aset: data?.id_entitas_aset,
          id_grup_aset: data?.id_grup_aset,
          id_kategori_aset: data?.id_kategori_aset,
          id_jenis_aset: data?.id_jenis_aset,
          id_unit_organisasi: data?.id_unit_organisasi,
          nama_item_aset: data?.nama_aset,
          harga_perolehan: data?.harga_kesepakatan,
          id_penerimaan_aset: data?.id_penerimaan_aset,
        })
      ),
    { enabled: action !== 'detail' }
  );

  const { isFetching: loadingKode } = useQuery(
    ['penerimaanAset', 'kode', values?.tahun_perolehan],
    () =>
      PenerimaanAsetApi.generateNumberAset({
        id_entitas_aset: values?.id_entitas_aset,
        id_grup_aset: values?.id_grup_aset,
        id_kategori_aset: values?.id_kategori_aset,
        id_jenis_aset: values?.id_jenis_aset,
        tahun_perolehan: values?.tahun_perolehan,
      }).then(({ data }) => setFieldValue('kode_item_aset', data?.data)),
    { enabled: !!values?.id_grup_aset && !!values?.tahun_perolehan }
  );

  const { data: dropdownSatuanPakai, isFetching: loadingDropdownSatuanPakai } =
    useQuery(['satuanPakai', 'dropdown'], () =>
      PenerimaanAsetApi.getDropdownSatuanPakai()
    );

  const { data: dropdownKarywan, isFetching: loadingDropdownKaryawan } =
    useQuery(['karyawan', 'dropdown'], () =>
      PenerimaanAsetApi.getDropdownwPetugasPenerimaan()
    );

  const { data: dropdownKondisiAset, isFetching: loadingDropdownKondisiAset } =
    useQuery(['kondisiAset', 'dropdown'], () =>
      PenerimaanAsetApi.getDropdownKondisiAset({ tipe: 'kondisi_aset' })
    );

  const { data: dropdownPabrikan, isFetching: loadingDropdownPabrikan } =
    useQuery(['pabrikan', 'dropdown'], () =>
      PenerimaanAsetApi.getDropdownPabrikan()
    );

  const { data: dropdownProvinsi, isFetching: loadingDropdownProvinsi } =
    useQuery(['provinsi', 'dropdown'], () =>
      PenerimaanAsetApi.getProvinsi({ tipe: 'provinsi' })
    );

  const { data: dropdownKabupaten, isFetching: loadingDropdownKabupaten } =
    useQuery(
      ['kabupaten', 'dropdown', values.id_provinsi],
      () =>
        PenerimaanAsetApi.getKabupaten({
          tipe: 'kabupaten',
          id_provinsi: values.id_provinsi,
        }),
      { enabled: !!values.id_provinsi }
    );

  const { data: dropdownKecamatan, isFetching: loadingDropdownKecamatan } =
    useQuery(
      ['kecamatan', 'dropdown', values.id_kabupaten],
      () =>
        PenerimaanAsetApi.getKecamatan({
          tipe: 'kecamatan',
          id_kabupaten: values.id_kabupaten,
        }),
      { enabled: !!values.id_kabupaten }
    );

  const { data: dropdownDesa, isFetching: loadingDropdownDesa } = useQuery(
    ['desa', 'dropdown', values.id_kecamatan],
    () =>
      PenerimaanAsetApi.getDesa({
        tipe: 'desa',
        id_kecamatan: values.id_kecamatan,
      }),
    { enabled: !!values.id_kecamatan }
  );

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ show: false, data: {}, type: '' })}
      scrollable
      size="xl"
    >
      <Modal.Header closeButton>
        <div className="text-capitalize">
          Registrasi Aset {checkTitle} Yang Diterima
        </div>
      </Modal.Header>
      <Modal.Body>
        {loadingDetailPenerimaanAset ? (
          <DataStatus text="Memuat Data..." loading />
        ) : (
          <>
            {action !== 'detail' && (
              <>
                <InfoPenerimaanAset />
                <hr />
                <small className="font-weight-bold">Registrasi Aset</small>
              </>
            )}
            <Row className="mt-2">
              <Col>
                <Input
                  disabled
                  label="Entitas Aset"
                  name="nama_entitas_aset"
                  value={values?.nama_entitas_aset}
                />
                <Input
                  disabled
                  label="Grup Aset"
                  name="nama_entitas_aset"
                  value={values?.nama_grup_aset}
                />
                <Input
                  disabled
                  label="Kategori Aset"
                  name="nama_entitas_aset"
                  value={values?.nama_kategori_aset}
                />
                <Input
                  disabled
                  label="Jenis Aset"
                  name="nama_entitas_aset"
                  value={values?.nama_jenis_aset}
                />
                <Row>
                  <Col>
                    <SelectSearch
                      isDisabled={action === 'detail'}
                      label="Bulan Perolehan"
                      placeholder="Pilih Bulan Perolehan"
                      option={monthOptions}
                      defaultValue={monthOptions.find(
                        (val) => values?.bulan_perolehan === val.value
                      )}
                      onChange={(val) =>
                        setFieldValue('bulan_perolehan', val.value)
                      }
                      error={
                        errors.bulan_perolehan &&
                        touched.bulan_perolehan &&
                        true
                      }
                      errorText={
                        errors.bulan_perolehan &&
                        touched.bulan_perolehan &&
                        errors.bulan_perolehan
                      }
                    />
                  </Col>
                  <Col>
                    <Input
                      disabled={action === 'detail'}
                      label="Tahun Perolehan Aset"
                      type="text"
                      name="tahun_perolehan"
                      value={values.tahun_perolehan}
                      onChange={handleChange}
                      error={
                        errors.tahun_perolehan &&
                        touched.tahun_perolehan &&
                        true
                      }
                      errorText={errors.tahun_perolehan}
                    />
                  </Col>
                </Row>
                <CheckInput
                  values={values}
                  handleChange={handleChange}
                  action={action}
                  type={type}
                />
                <SelectSearch
                  isDisabled={action === 'detail'}
                  label="Satuan Pakai"
                  placeholder="Pilih satuan pakai"
                  loading={loadingDropdownSatuanPakai}
                  option={dropdownSatuanPakai}
                  defaultValue={
                    values?.id_satuan_pakai
                      ? {
                          value: values?.id_satuan_pakai,
                          label: values?.nama_satuan_pakai,
                        }
                      : undefined
                  }
                  onChange={(val) =>
                    setValues({
                      ...values,
                      id_satuan_pakai: val?.value,
                      nama_satuan_pakai: val?.label,
                    })
                  }
                  error={
                    errors.id_satuan_pakai && touched.id_satuan_pakai && true
                  }
                  errorText={
                    errors.id_satuan_pakai &&
                    touched.id_satuan_pakai &&
                    errors.id_satuan_pakai
                  }
                />
              </Col>
              <Col>
                <Input
                  label="Kode Aset"
                  disabled
                  // placeholder="Pilih tahun perolehan terlebih dahulu"
                  loading={loadingKode}
                  value={values?.kode_item_aset}
                />
                <Input
                  label="Nama Aset"
                  name="nama_item_aset"
                  value={values?.nama_item_aset}
                  onChange={handleChange}
                  disabled={action === 'detail'}
                />
                <SelectSearch
                  isDisabled={action === 'detail'}
                  label="Kondisi Aset"
                  placeholder="Pilih kondisi aset"
                  loading={loadingDropdownKondisiAset}
                  option={dropdownKondisiAset}
                  defaultValue={
                    values?.id_kondisi_aset
                      ? {
                          value: values?.id_kondisi_aset,
                          label: values?.nama_kondisi_aset,
                        }
                      : undefined
                  }
                  onChange={(val) =>
                    setValues({
                      ...values,
                      id_kondisi_aset: val?.value,
                      nama_kondisi_aset: val?.label,
                    })
                  }
                  error={
                    errors.id_kondisi_aset && touched.id_kondisi_aset && true
                  }
                  errorText={
                    errors.id_kondisi_aset &&
                    touched.id_kondisi_aset &&
                    errors.id_kondisi_aset
                  }
                />
                <SelectSearch
                  isDisabled={action === 'detail'}
                  label="Karyawan Penanggung Jawab Aset"
                  placeholder="Pilih karyawan penanggung jawab aset"
                  loading={loadingDropdownKaryawan}
                  option={dropdownKarywan}
                  defaultValue={
                    values?.id_karyawan
                      ? {
                          value: values?.id_karyawan,
                          label: values?.nama_karyawan,
                        }
                      : undefined
                  }
                  onChange={(val) =>
                    setValues({
                      ...values,
                      id_karyawan: val?.value,
                      nama_karyawan: val?.label,
                    })
                  }
                  error={errors.id_karyawan && touched.id_karyawan && true}
                  errorText={
                    errors.id_karyawan &&
                    touched.id_karyawan &&
                    errors.id_karyawan
                  }
                />
                <Input
                  disabled
                  label="Unit Organisasi Pengguna"
                  name="id_unit_organisasi"
                  value={values?.nama_unit_organisasi}
                />
                <CheckInputPerTab
                  action={action}
                  dropdownPabrikan={dropdownPabrikan}
                  loadingDropdownPabrikan={loadingDropdownPabrikan}
                  handleChange={handleChange}
                  setValues={setValues}
                  values={values}
                  type={type}
                />
                <div className="d-flex">
                  <label for="produksi">
                    <Field
                      type="checkbox"
                      name="produksi"
                      id="produksi"
                      disabled={action === 'detail'}
                    />
                    <span className="ml-1">Sebagai Produksi</span>
                  </label>
                  <label for="disewa" className="ml-2">
                    <Field
                      type="checkbox"
                      name="disewa"
                      id="disewa"
                      disabled={action === 'detail'}
                    />
                    <span className="ml-1">Dapat Disewa</span>
                  </label>
                </div>
              </Col>
            </Row>
            <TextArea
              disabled={action === 'detail'}
              label="Keterangan"
              name="keterangan"
              placeholder="Masukan keterangan"
              rows={4}
              onChange={handleChange}
              error={errors.keterangan && touched.keterangan && true}
              errorText={
                errors.keterangan && touched.keterangan && errors.keterangan
              }
            />
            <hr />
            <small className="font-weight-bold">Lokasi Aset</small>
            <Row>
              <Col>
                <SelectSearch
                  isDisabled={action === 'detail'}
                  label="Provinsi"
                  placeholder="Pilih provinsi"
                  loading={loadingDropdownProvinsi}
                  option={dropdownProvinsi}
                  defaultValue={
                    values?.id_provinsi
                      ? {
                          value: values?.id_provinsi,
                          label: values?.nama_provinsi,
                        }
                      : undefined
                  }
                  onChange={(val) => {
                    setValues({
                      ...values,
                      id_provinsi: val.value,
                      nama_provinsi: val.label,
                    });
                  }}
                  error={errors.id_provinsi && touched.id_provinsi && true}
                  errorText={
                    errors.id_provinsi &&
                    touched.id_provinsi &&
                    errors.id_provinsi
                  }
                />
                <SelectSearch
                  label="Kabupaten"
                  placeholder="Pilih kabupaten"
                  isDisabled={!values.id_provinsi || action === 'detail'}
                  loading={loadingDropdownKabupaten}
                  option={dropdownKabupaten}
                  defaultValue={
                    values?.id_kabupaten
                      ? {
                          value: values?.id_kabupaten,
                          label: values?.nama_kabupaten,
                        }
                      : undefined
                  }
                  onChange={(val) => {
                    setValues({
                      ...values,
                      id_kabupaten: val.value,
                      nama_kabupaten: val.label,
                    });
                  }}
                  error={errors.id_kabupaten && touched.id_kabupaten && true}
                  errorText={
                    errors.id_kabupaten &&
                    touched.id_kabupaten &&
                    errors.id_kabupaten
                  }
                />
              </Col>
              <Col>
                <SelectSearch
                  key={values?.id_kabupaten}
                  label="Kecamatan"
                  placeholder="Pilih kecamatan"
                  isDisabled={!values.id_kabupaten || action === 'detail'}
                  loading={loadingDropdownKecamatan}
                  option={dropdownKecamatan}
                  defaultValue={
                    values?.id_kecamatan
                      ? {
                          value: values?.id_kecamatan,
                          label: values?.nama_kecamatan,
                        }
                      : undefined
                  }
                  onChange={(val) => {
                    setValues({
                      ...values,
                      id_kecamatan: val.value,
                      nama_kecamatan: val.label,
                    });
                  }}
                  error={errors.id_kecamatan && touched.id_kecamatan && true}
                  errorText={
                    errors.id_kecamatan &&
                    touched.id_kecamatan &&
                    errors.id_kecamatan
                  }
                />
                <SelectSearch
                  label="Desa"
                  placeholder="Pilih desa"
                  isDisabled={!values.id_kecamatan || action === 'detail'}
                  loading={loadingDropdownDesa}
                  option={dropdownDesa}
                  defaultValue={
                    values?.id_desa
                      ? {
                          value: values?.id_desa,
                          label: values?.nama_desa,
                        }
                      : undefined
                  }
                  onChange={(val) => {
                    setValues({
                      ...values,
                      id_desa: val.value,
                      nama_desa: val.label,
                    });
                  }}
                  error={errors.id_desa && touched.id_desa && true}
                  errorText={
                    errors.id_desa && touched.id_desa && errors.id_desa
                  }
                />
              </Col>
            </Row>
            <TextArea
              disabled={action === 'detail'}
              label="Detail Lokasi"
              name="detail_lokasi"
              placeholder="Masukan detail lokasi"
              rows={4}
              onChange={handleChange}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonCreate
          text="Simpan"
          onClick={handleSubmit}
          loading={isSubmitting}
        />
      </Modal.Footer>
    </Modal>
  );
};
