import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { Row, Col, Card, Tab, Nav } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';
import {
  Input,
  InfoItemVertical,
  Select,
  TextArea,
  ButtonBack,
  ButtonCreate,
  Button,
} from 'components2';

import { InfoItemHorizontal } from 'components';
import { TableBuasoPermintaanWorkOrder } from './TableBuasoPermintaanWorkOrder';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DateConvert } from 'utilities';
import { ModalConfirm } from './ModalConfirm';
import { TableHistoryBuasoPermintaanWorkOrder } from './TableHistoryBuasoPermintaanWorkOrder';

export const FormPermintaanWorkOrderContent = ({
  action,
  setAlertConfig,
  alertConfig,
  refetch,
  setDefaultTab,
  defaultTab,
}) => {
  const { values } = useFormikContext();
  const history = useHistory();

  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
    type: '',
  });

  return (
    <Fragment>
      <div className="d-flex justify-content-between mb-2">
        <small className="font-weight-bold">
          {action === 'create'
            ? 'Tambah Data Maintenance Request'
            : action === 'update'
            ? 'Ubah Data Maintenance Request'
            : 'Detail Data Maintenance Request'}
        </small>
        <div>
          <Button
            size="sm"
            variant="danger"
            text="SELESAI PERMINTAAN"
            className="mr-2"
            onClick={() => setModalConfig({ show: true })}
          />

          <ButtonBack
            size="sm"
            onClick={() =>
              history.push('/transaksi/permintaan-work-order/work-order')
            }
          />
        </div>
      </div>

      <Card>
        <Card.Body>
          <Row>
            <Col>
              <InfoItemHorizontal
                label="Tgl. Work Order"
                text={
                  values?.tgl_work_order
                    ? DateConvert(new Date(values?.tgl_work_order)).detail
                    : '-'
                }
              />
              <InfoItemHorizontal
                label="No. Work Order"
                text={values?.no_work_order ?? '-'}
              />
              <InfoItemHorizontal
                label="Item Aset"
                text={values?.nama_item_aset ?? '-'}
              />
              <InfoItemHorizontal
                label="Petugas Maintenance"
                text={values?.nama_petugas ?? '-'}
              />
              <InfoItemHorizontal
                label="Status Petugas"
                text={
                  values?.is_mekanik_eksternal
                    ? 'Mekanik Eksternal'
                    : 'Mekanik Internal'
                }
              />
              <InfoItemHorizontal
                label="Vendor"
                text={values?.nama_vendor ?? '-'}
              />
            </Col>
            <Col>
              <InfoItemHorizontal
                label="Tgl. Maintenance Request"
                text={
                  values?.tgl_maintenance_request_master
                    ? DateConvert(
                        new Date(values?.tgl_maintenance_request_master)
                      ).detail
                    : '-'
                }
              />
              <InfoItemHorizontal
                label="No. Maintenance Request"
                text={values?.no_maintenance_request_master ?? '-'}
              />
              <InfoItemHorizontal
                label="Catatan"
                text={values?.catatan_maintenance_request ?? '-'}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="p-0 mt-3">
        <Tab.Container defaultActiveKey={defaultTab}>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="bahan">Bahan</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="upah">Upah</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="alat_mesin">Alat Mesin</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="subkon">Subkon</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="overhead">Overhead</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Tab.Content>
            <Tab.Pane eventKey="bahan" className="p-2">
              {/* list Data */}
              <TableBuasoPermintaanWorkOrder
                dataTable={values?.detail}
                action={action}
                type="bahan"
                setAlertConfig={setAlertConfig}
                setDefaultTab={setDefaultTab}
                alertConfig={alertConfig}
                refetch={refetch}
              />
              {/* Hostory */}
              <TableHistoryBuasoPermintaanWorkOrder
                dataTable={values?.detail}
                action={action}
                type="bahan"
                setAlertConfig={setAlertConfig}
                alertConfig={alertConfig}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="upah" className="p-2">
              {/* list Data */}
              <TableBuasoPermintaanWorkOrder
                dataTable={values?.detail}
                action={action}
                setDefaultTab={setDefaultTab}
                type="upah"
                setAlertConfig={setAlertConfig}
                alertConfig={alertConfig}
                refetch={refetch}
              />
              {/* Hostory */}
              <TableHistoryBuasoPermintaanWorkOrder
                dataTable={values?.detail}
                action={action}
                type="upah"
                setAlertConfig={setAlertConfig}
                alertConfig={alertConfig}
                refetch={refetch}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="alat_mesin" className="p-2">
              {/* list Data */}
              <TableBuasoPermintaanWorkOrder
                dataTable={values?.detail}
                action={action}
                setDefaultTab={setDefaultTab}
                type="alat mesin"
                setAlertConfig={setAlertConfig}
                alertConfig={alertConfig}
                refetch={refetch}
              />
              {/* Hostory */}
              <TableHistoryBuasoPermintaanWorkOrder
                dataTable={values?.detail}
                action={action}
                type="alat mesin"
                setAlertConfig={setAlertConfig}
                alertConfig={alertConfig}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="subkon" className="p-2">
              {/* list Data */}
              <TableBuasoPermintaanWorkOrder
                dataTable={values?.detail}
                action={action}
                setDefaultTab={setDefaultTab}
                type="subkon"
                setAlertConfig={setAlertConfig}
                alertConfig={alertConfig}
                refetch={refetch}
              />
              {/* Hostory */}
              <TableHistoryBuasoPermintaanWorkOrder
                dataTable={values?.detail}
                action={action}
                type="subkon"
                setAlertConfig={setAlertConfig}
                alertConfig={alertConfig}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="overhead" className="p-2">
              {/* list Data */}
              <TableBuasoPermintaanWorkOrder
                dataTable={values?.detail}
                action={action}
                setDefaultTab={setDefaultTab}
                type="overhead"
                setAlertConfig={setAlertConfig}
                alertConfig={alertConfig}
                refetch={refetch}
              />
              {/* Hostory */}
              <TableHistoryBuasoPermintaanWorkOrder
                dataTable={values?.detail}
                action={action}
                type="overhead"
                setAlertConfig={setAlertConfig}
                alertConfig={alertConfig}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>
      <ModalConfirm setModalConfig={setModalConfig} modalConfig={modalConfig} />
    </Fragment>
  );
};
