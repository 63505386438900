import {
  ButtonCreate,
  ButtonDelete,
  ButtonUpdate,
} from '@bhawanadevteam/react-core';
import {
  ActionButton,
  DataStatus,
  Input,
  SelectSearch,
  TBody,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
  Tr,
} from 'components';
import Thead from 'components/Table/THead';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { DateConvert, DecimalConvert } from 'utilities';

export const TableHistoryBuasoPermintaanWorkOrder = ({ type, action }) => {
  const typeAlatMesinCheck = type === 'alat mesin' ? 'alat_mesin' : type;
  const { values } = useFormikContext();

  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <small className="font-weight-bold text-capitalize mt-2">
          History Permintaan Work Order {type}
        </small>
      </div>
      <Table>
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed className="text-capitalize">
              Informasi Permintaan Work Order
            </ThFixed>
            <ThFixed className="text-capitalize">Kode {type}</ThFixed>
            <Th className="text-capitalize">Item {type}</Th>
            <Th width="150">Satuan</Th>
            <Th width="150">Qty. Permintaan Work Order</Th>
            <Th width="150">Qty. Telah Ditransfer</Th>
          </Tr>
        </Thead>
        <TBody>
          {values?.[`${typeAlatMesinCheck}_history`]?.length > 0 ? (
            values?.[`${typeAlatMesinCheck}_history`]?.map((val, ind) => (
              <Tr key={ind}>
                <Td>{ind + 1}</Td>
                <TdFixed>
                  <div>
                    {val?.[`tgl_permintaan_work_order_${typeAlatMesinCheck}`]
                      ? DateConvert(
                          new Date(
                            val?.[
                              `tgl_permintaan_work_order_${typeAlatMesinCheck}`
                            ]
                          )
                        ).custom
                      : '-'}
                  </div>
                  <div>
                    {val?.[`no_permintaan_work_order_${typeAlatMesinCheck}`] ??
                      '-'}
                  </div>
                </TdFixed>
                <Td>{val?.kode_item ?? '-'}</Td>
                <Td>{val?.nama_item ?? '-'}</Td>
                <Td>{val?.nama_satuan ?? '-'}</Td>
                <Td className="text-right">
                  {
                    DecimalConvert(
                      parseFloat(
                        val?.[
                          `qty_permintaan_work_order_${typeAlatMesinCheck}`
                        ] ?? 0
                      )
                    ).getWithComa
                  }
                </Td>
                <Td className="text-right">
                  {
                    DecimalConvert(
                      parseFloat(
                        val?.[
                          `qty_transfer_work_order_${typeAlatMesinCheck}`
                        ] ?? 0
                      )
                    ).getWithComa
                  }
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="8">
                <DataStatus text="Data Tidak Ada" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </div>
  );
};
