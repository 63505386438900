import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { FormAnalisaChecklistContent } from './__FormCheclistComps__';
import { useMutation, useQuery } from 'react-query';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from './__FormCheckListUtils__';
import { DataStatus } from '@bhawanadevteam/react-core';
import { Alert } from 'components';
import FormChecklistApi from './__FormChecklistApi__';

export const FormChecklistCreate = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const history = useHistory();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: '',
    variant: 'primary',
  });

  const {
    data: detailMaintenancaeRequest,
    isFetching: loadingDetailMaintenanceRequest,
  } = useQuery(
    ['formChecklist', 'maintenanceRequestSingle', id],
    () =>
      FormChecklistApi.getSingleMaintenanceRequest({
        id_maintenance_request_master: id,
      }),
    { enabled: !!id }
  );

  const createFormChecklist = useMutation((data) =>
    FormChecklistApi.create(data)
  );
  const formSubmitHandler = (value) => {
    createFormChecklist
      .mutateAsync(formSubmitValueMapper(value))
      .then(() =>
        history.push('/transaksi/form-checklist', {
          alert: {
            variant: 'primary',
            show: true,
            text: 'Data berhasil disimpan!!!',
          },
        })
      )
      .catch((err) =>
        setAlertConfig({
          variant: 'danger',
          show: true,
          text: 'Data gagal disimpan!!!',
        })
      );
  };

  useEffect(() => {
    setNavbarTitle('Form Checklist');
  }, []);

  return (
    <>
      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
        showCloseButton
      />
      {loadingDetailMaintenanceRequest ? (
        <DataStatus loading text="Memuat Data..." />
      ) : (
        <Formik
          initialValues={formInitialValues(detailMaintenancaeRequest?.data)}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          <FormAnalisaChecklistContent action="create" />
        </Formik>
      )}
    </>
  );
};
