import React, { useState, useEffect } from "react";
import {
  CRUDLayout,
  Input,
  InputSearch,
  ExportButton,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  UpdateButton,
  DeleteButton,
  Switch,
  Select,
  Alert,
  Pagination,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
} from "../../../components";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { PabrikanApi } from "../../../api";
import { PageNumber } from "../../../utilities";
import { ModalBody, ModalFooter, Row, Col, ButtonGroup } from "react-bootstrap";

const Pabrikan = ({ setNavbarTitle }) => {
  const title = "Master Pabrikan";
  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false); // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([]);

  // STATE MENAMPUNG KODE ITEM
  const [kodeItem, setKodeItem] = useState();
  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [alertConfigSearch, setAlertConfigSearch] = useState({
    data: false,
    variant: "primary",
    text: "",
  });

  const [searchTime, setSearchTime] = useState(null);

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchKey(key)
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        });
        setShowAlert(true)
      }, 750)
    );
  };

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);
    setShowAlert(false);

    Axios.all([PabrikanApi.getPage(page, dataLength, searchKey), PabrikanApi.getKodeItem()])
      .then(
        Axios.spread((res, kodeItem) => {
          setData(res.data.data);
          setKodeItem(kodeItem.data.data);
          setTotalPage(res.data.total_page);
          setDataCount(res.data.data_count);
        })
      )
      .catch((err) => alert(err.response.data.message))
      .finally(() => {
        // if (searchKey != "") {
        //   setAlertConfigSearch({
        //     data: true,
        //     variant: "primary",
        //     text: `Hasil Pencarian : ${searchKey}`,
        //   });
        // } else {
        //   setAlertConfigSearch({
        //     data: false,
        //     variant: "",
        //     text: "",
        //   });
        // }

        setIsLoading(false);
      });
  };

  // FUNCTION SWITCH HIDDEN DATA
  const changeDataStatus = (status, id) => {
    setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_pabrikan: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? PabrikanApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : PabrikanApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    kode_pabrikan: Yup.string()
      .required("Masukkan Kode Pabrikan Aset")
      .test("checkKode", "Kode Pabrikan Aset telah didaftarkan", (value) =>
        value == updateData.kode_pabrikan
          ? true
          : PabrikanApi.single({ kode_pabrikan: value })
              .then(() => false)
              .catch(() => true)
      ),
    nama_pabrikan: Yup.string().required("Masukkan Nama Pabrikan Aset"),
  });

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    // FORMIK INITIAL VALUES
    const formInitialValues = {
      kode_pabrikan: "",
      nama_pabrikan: "",
      keterangan: "",
    };

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      PabrikanApi.create(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
            // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsCreateForm(false);
          // TAMPILKAN ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Input
                  label="Kode Pabrikan"
                  type="text"
                  name="kode_pabrikan"
                  placeholder="Kode Pabrikan"
                  value={values.kode_pabrikan}
                  onChange={handleChange}
                  error={errors.kode_pabrikan && touched.kode_pabrikan && true}
                  errorText={errors.kode_pabrikan}
                />

                <Input
                  label="Nama Pabrikan"
                  type="text"
                  name="nama_pabrikan"
                  placeholder="Nama Pabrikan"
                  value={values.nama_pabrikan}
                  onChange={handleChange}
                  error={errors.nama_pabrikan && touched.nama_pabrikan && true}
                  errorText={errors.nama_pabrikan}
                />
                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  placeholder="Keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                />
              </ModalBody>

              <ModalFooter>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Tambah"
                  loading={isSubmitting}
                />
              </ModalFooter>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_pabrikan: updateData.id_pabrikan,
      kode_pabrikan: updateData.kode_pabrikan,
      nama_pabrikan: updateData.nama_pabrikan,
      keterangan: updateData.keterangan,
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      // SEND UPDATED DATA TO SERVER
      PabrikanApi.update(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
            // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          });
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false);
          // SHOW ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Input
                  label="Kode Pabrikan"
                  type="text"
                  name="kode_pabrikan"
                  placeholder="Kode Pabrikan"
                  value={values.kode_pabrikan}
                  onChange={handleChange}
                  error={errors.kode_pabrikan && touched.kode_pabrikan && true}
                  errorText={errors.kode_pabrikan}
                />

                <Input
                  label="Nama Pabrikan"
                  type="text"
                  name="nama_pabrikan"
                  placeholder="Pabrikan"
                  value={values.nama_pabrikan}
                  onChange={handleChange}
                  error={errors.nama_pabrikan && touched.nama_pabrikan && true}
                  errorText={errors.nama_pabrikan}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  placeholder="Keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                />
              </ModalBody>

              <ModalFooter>
                <ActionButton type="submit" variant="success" text="Ubah" loading={isSubmitting} />
              </ModalFooter>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_pabrikan: deleteData.id_pabrikan };
    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      PabrikanApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // FETCH DATA DARI SERVER
          getData();
          // TAMPIL ALERT
          setShowAlert(true);
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Pabrikan : {deleteData.kode_pabrikan}</div>
        <div>Nama Pabrikan : {deleteData.nama_pabrikan}</div>
      </DeleteModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Kode Pabrikan</ThFixed>
              <Th>Nama Pabrikan</Th>
              <Th>Keterangan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_pabrikan}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <ButtonGroup size="sm" className="mr-1">
                        <UpdateButton
                          onClick={() => {
                            setUpdateData(val);
                            setIsUpdateForm(true);
                          }}
                        />
                        <DeleteButton
                          onClick={() => {
                            setDeleteData(val);
                            setIsDeleteData(true);
                          }}
                        />
                        <Switch
                          id={val.id_pabrikan}
                          checked={val.is_hidden ? false : true}
                          onChange={() => changeDataStatus(val.is_hidden, val.id_pabrikan)}
                        />
                      </ButtonGroup>
                    </div>
                  </TdFixed>
                  <TdFixed>{val.kode_pabrikan}</TdFixed>
                  <Td>{val.nama_pabrikan}</Td>
                  <Td>{val.keterangan}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataNumber={page * dataLength - dataLength + 1}
            dataPage={page * dataLength}
            dataCount={dataCount}
            onDataLengthChange={(e) => {
              setPage(1);
              setDataLength(e.target.value);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch onChange={onInputSearch}/>
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Export */}
          {/* <ExportButton /> */}
          {/* Button Tambah */}
          <CreateButton onClick={() => setIsCreateForm(true)} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Alert
        show={alertConfigSearch.data}
        showCloseButton={true}
        variant={alertConfigSearch.variant}
        text={alertConfigSearch.text}
        onClose={() =>
          setAlertConfigSearch({
            data: false,
            variant: "primary",
            text: "",
          })
        }
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default Pabrikan;
