export const formInitialValues = (data = {}) => {
  return Object({
    id_analisa_checklist: data?.id_analisa_checklist ?? undefined,
    kode_item_aset: data?.kode_item_aset ?? "-",
    id_item_aset: data?.id_item_aset ?? undefined,
    nama_item_aset: data?.nama_item_aset ?? "-",
    nama_grup_aset: data?.nama_grup_aset ?? "-",
    nama_kategori_aset: data?.nama_kategori_aset ?? "-",
    nama_jenis_aset: data?.nama_jenis_aset ?? "-",
    detail_perlengkapan: data?.detail_perlengkapan
      ? data?.detail_perlengkapan
      : [],
    detail_fungsi: data?.detail_fungsi ? data?.detail_fungsi : [],
  });
};
