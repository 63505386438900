import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import { FormFile } from 'react-bootstrap';

export const InputBuktiPenerimaan = () => {
  const [nameFile, setNameFile] = useState('');
  const [preview, setPreview] = useState();

  const { values, setValues, errors, touched } = useFormikContext();

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setValues({ ...values, bukti_penerimaan: undefined });
      setNameFile(undefined);

      return;
    }

    setValues({ ...values, bukti_penerimaan: e?.target?.files[0] });
    setNameFile(e?.target?.files[0]?.name);

    const objectUrl = URL.createObjectURL(e?.target?.files[0]);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  };

  return (
    <>
      <small>Bukti Penerimaan Aset</small>
      <FormFile custom style={{ zIndex: 0 }}>
        <FormFile.Input
          size="sm"
          onChange={onSelectFile}
          accept="image/*"
          isInvalid={
            errors.bukti_penerimaan && touched.bukti_penerimaan && true
          }
        />
        <FormFile.Label style={{ fontSize: 14 }}>
          {nameFile ? nameFile : 'Pilih Gambar'}
        </FormFile.Label>
        <div className="invalid-feedback">
          {errors.bukti_penerimaan &&
            touched.bukti_penerimaan &&
            errors.bukti_penerimaan}
        </div>
      </FormFile>
      {values?.bukti_penerimaan && (
        <img
          style={{ maxWidth: 130 }}
          src={preview ? preview : values?.bukti_penerimaan}
          alt=""
          className="mt-4 mb-4 img-fluid"
        />
      )}
    </>
  );
};
