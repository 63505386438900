import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { Row, Col, Card } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';
import {
  Input,
  InfoItemHorizontal,
  Select,
  TextArea,
  ButtonBack,
  ButtonCreate,
} from 'components2';

import PermintaanAsetApi from '../__PermintaanAsetApi__';
import { DataStatus, DatePicker } from 'components';
import { TablePermintaanAset } from './TablePermintaanAset';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { ModalForm } from './ModalForm';
import * as Yup from 'yup';
import { DateConvert } from 'utilities';
import FileSaver from 'file-saver';

const InfoMaintenanceRequest = () => {
  const { values } = useFormikContext();
  return (
    <>
      <InfoItemHorizontal
        label="Tgl. Permintaan Aset"
        text={
          values?.tgl_permintaan_aset
            ? DateConvert(new Date(values?.tgl_permintaan_aset)).detail
            : '-'
        }
      />

      <InfoItemHorizontal
        label="No. Permintaan Aset"
        text={values?.no_permintaan_aset ?? '-'}
      />

      <InfoItemHorizontal
        label="Catatan Permintaan"
        text={values?.catatan_permintaan ?? '-'}
      />
    </>
  );
};

const CatatanApprovalCard = ({ dataStakeholder }) => {
  const InfoItem = ({ title1, value1, title2, value2 }) => (
    <div className="mb-2">
      <small>{title1}</small>
      <br />
      <b>{value1}</b>
      <div className="mt-1" />
      <small>{title2}</small>
      <br />
      <b>{value2}</b>
    </div>
  );

  return (
    <Card className="mt-4 mb-5">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Catatan Approval Permintaan Aset</b>
      </Card.Header>
      <Card.Body>
        {dataStakeholder ? (
          <Row>
            {dataStakeholder.map((val, index) => (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval === 'VER' ||
                    val.status_approval === 'REV'
                      ? `Pemeriksa ${
                          val.approval_level !== '0' ? val.approval_level : ''
                        }`
                      : val.status_approval === 'APP'
                      ? 'Pengesah'
                      : val.status_approval === 'PEN'
                      ? 'Pembuat'
                      : 'Di Tolak Oleh'
                  }
                  value1={val.nama_karyawan ?? '-'}
                  title2="Catatan"
                  value2={val.catatan ?? '-'}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <div className="d-flex justify-content-center">
            <DataStatus text="Tidak ada catatan" />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export const FormPermintaanAsetContent = ({ action }) => {
  const { values, handleSubmit, handleChange, setValues, errors, touched } =
    useFormikContext();
  const history = useHistory();

  const [loadingExport, setLoadingExport] = useState(false);

  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
    type: 'add',
    ind: undefined,
  });

  const { isFetching: loadingKode } = useQuery(
    ['maintenanceRequest', 'kode', values?.tgl_permintaan_aset],
    () =>
      PermintaanAsetApi.generateNumber({
        tanggal: values?.tgl_permintaan_aset,
      }).then((val) =>
        setValues({ ...values, no_permintaan_aset: val?.data?.data })
      ),
    {
      enabled: !!values?.tgl_permintaan_aset && action === 'create',
    }
  );

  const modalInitialValues = {
    idEntitasAset: modalConfig?.data?.idEntitasAset ?? undefined,
    namaEntitasAset: modalConfig?.data?.namaEntitasAset ?? '',
    idGrupAset: modalConfig?.data?.idGrupAset ?? undefined,
    namaGrupAset: modalConfig?.data?.namaGrupAset ?? '',
    idKategoriAset: modalConfig?.data?.idKategoriAset ?? undefined,
    namaKategoriAset: modalConfig?.data?.namaKategoriAset ?? '',
    idJenisAset: modalConfig?.data?.idJenisAset ?? undefined,
    namaJenisAset: modalConfig?.data?.namaJenisAset ?? '',
    idUnitProduksi: modalConfig?.data?.idUnitProduksi ?? undefined,
    namaUnitProduksi: modalConfig?.data?.namaUnitProduksi ?? '',
    keperluan: modalConfig?.data?.keperluan ?? undefined,
    keperluanLabel: modalConfig?.data?.keperluanLabel ?? '',
    metodePengadaan: modalConfig?.data?.metodePengadaan ?? undefined,
    metodePengadaanLabel: modalConfig?.data?.metodePengadaanLabel ?? '',
    namaAset: modalConfig?.data?.namaAset ?? '',
    keterangan: modalConfig?.data?.keterangan ?? '',
    file: modalConfig?.data?.file ?? [],
  };

  const modalValidationSchema = Yup.object().shape({
    idEntitasAset: Yup.string().required('Pilih entitas aset'),
    idGrupAset: Yup.string().required('Pilih grup aset'),
    idKategoriAset: Yup.string().required('Pilih kategori aset'),
    idJenisAset: Yup.string().required('Pilih jenis aset'),
    idUnitProduksi: Yup.string().required('Pilih unit produksi'),
    keperluan: Yup.string().required('Pilih keperluan'),
    metodePengadaan: Yup.string().required('Pilih metode pengadaan'),
    namaAset: Yup.string().required('Masukan item aset'),
  });

  const modalSubmitHandler = (value, { setSubmitting, resetForm }) => {
    modalConfig.type === 'add' &&
      setValues({ ...values, detail: [...values?.detail, value] });
    modalConfig.type === 'update' &&
      setValues({
        ...values,
        detail: values?.detail?.map((res, ind) =>
          ind === modalConfig?.ind ? value : res
        ),
      });

    setSubmitting(false);
    resetForm();
    setModalConfig({ ...modalConfig, show: false });
  };

  const exportData = () => {
    setLoadingExport(true);

    PermintaanAsetApi.exportPdf({
      id_permintaan_aset: values?.id_permintaan_aset,
    })
      .then((res) => {
        const data = res.data.data;
        const urlFile = data;
        FileSaver.saveAs(urlFile, data);
      })
      .catch(() => {})
      .finally(() => setLoadingExport(false));
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between mb-2">
        <small className="font-weight-bold">
          {action === 'create'
            ? 'Tambah Data Permintaan Aset'
            : action === 'update'
            ? 'Ubah Data Permintaan Aset'
            : 'Detail Data Permintaan Aset'}
        </small>
        <div>
          {action === 'detail' && (
            <ButtonCreate
              text="Cetak"
              size="sm"
              className="mr-2"
              loading={loadingExport}
              onClick={() => exportData()}
            />
          )}
          <ButtonBack
            size="sm"
            onClick={() => history.push('/transaksi/permintaan-aset')}
          />
        </div>
      </div>
      <Card>
        <Card.Body>
          {action === 'detail' ? (
            <InfoMaintenanceRequest />
          ) : (
            <Row>
              <Col lg="6" md="6">
                <DatePicker
                  label="Tgl. Permintaan Aset"
                  selected={
                    values?.tgl_permintaan_aset
                      ? new Date(values?.tgl_permintaan_aset)
                      : null
                  }
                  onChange={(val) =>
                    setValues({
                      ...values,
                      tgl_permintaan_aset: DateConvert(new Date(val)).default,
                    })
                  }
                  error={
                    errors.tgl_permintaan_aset &&
                    touched.tgl_permintaan_aset &&
                    true
                  }
                  errorText={
                    errors.tgl_permintaan_aset &&
                    touched.tgl_permintaan_aset &&
                    errors.tgl_permintaan_aset
                  }
                />
              </Col>
              <Col lg="6" md="6">
                <Input
                  label="No. Permintaan Aset"
                  disabled
                  placeholder="Pilih tanggal terlebih dahulu!!!"
                  loading={loadingKode}
                  value={values?.no_permintaan_aset}
                />
              </Col>
              <Col>
                <TextArea
                  label="Catatan Permintaan"
                  placeholder="Masukan catatan permintaan"
                  rows="4"
                  name="catatan_permintaan"
                  onChange={handleChange}
                  value={values?.catatan_permintaan ?? '-'}
                />
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-between mt-3">
        <small className="font-weight-bold">Detail Aset Yang Diminta</small>
      </div>
      <Card>
        <Card.Body>
          {action !== 'detail' && (
            <div className="d-flex justify-content-end mb-3">
              <ButtonCreate
                text="Tambah"
                variant="primary"
                onClick={() => {
                  setModalConfig({ ...modalConfig, show: true, type: 'add' });
                }}
              />
            </div>
          )}
          <TablePermintaanAset
            dataTable={values?.detail}
            action={action}
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
          />
        </Card.Body>
      </Card>
      {action !== 'create' && (
        <CatatanApprovalCard dataStakeholder={values?.stakeholder} />
      )}

      {action !== 'detail' && (
        <div className="d-flex justify-content-end mt-3">
          <ButtonCreate
            text="Simpan"
            variant={action === 'update' ? 'success' : 'primary'}
            onClick={handleSubmit}
          />
        </div>
      )}

      {modalConfig.show && (
        <Formik
          initialValues={modalInitialValues}
          validationSchema={modalValidationSchema}
          onSubmit={modalSubmitHandler}
        >
          <ModalForm
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
          />
        </Formik>
      )}
    </Fragment>
  );
};
