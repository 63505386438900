export const formSubmitValueMapper = (data = {}) => ({
  id_permintaan_aset: data.id_permintaan_aset,
  tgl_permintaan_aset: data.tgl_permintaan_aset,
  catatan_permintaan: data.catatan_permintaan,
  detail: data.detail.map((val) => ({
    id_permintaan_aset_detail: val?.id_permintaan_aset_detail ?? undefined,
    id_entitas_aset: val?.idEntitasAset ?? undefined,
    id_grup_aset: val?.idGrupAset ?? undefined,
    id_kategori_aset: val?.idKategoriAset ?? undefined,
    id_jenis_aset: val?.idJenisAset ?? undefined,
    id_unit_organisasi: val?.idUnitProduksi ?? undefined,
    keperluan: val?.keperluan ?? undefined,
    metode_pengadaan: val?.metodePengadaan ?? undefined,
    nama_aset: val?.namaAset ?? '',
    keterangan: val?.keterangan ?? '',
    file: val?.file ?? [],
  })),
});
