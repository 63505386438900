// React
import React from 'react';

// Form
import { Formik, useFormikContext } from 'formik';

// Components
import { FilterModal } from 'components';
import { Select } from 'components2';

// API
import { useQuery } from 'react-query';
import ItemCheclistApi from '../__AnalisaChecklistApi__';

export const ModalFilter = ({ toggle }) => {
  const { values, handleSubmit, setValues } = useFormikContext();
  const { data: grupAset, isFetching: loadingGrupAset } = useQuery(
    ['grupAset', 'dropdown'],
    () => ItemCheclistApi.getDropdownGrupAset()
  );

  const { data: kategoriAset, isFetching: loadingKategoriAset } = useQuery(
    ['kategoriAset', 'dropdown', values?.id_grup_aset],
    () =>
      ItemCheclistApi.getDropdownKategoriAset({
        id_grup_aset: values?.id_grup_aset,
      }),
    { enabled: !!values?.id_grup_aset }
  );

  const { data: jenisAset, isFetching: loadingJenisAset } = useQuery(
    ['jenisAset', 'dropdown', values?.id_kategori_aset],
    () =>
      ItemCheclistApi.getDropdownJenisAset({
        id_kategori_aset: values?.id_kategori_aset,
      }),
    { enabled: !!values?.id_kategori_aset }
  );

  const { data: itemAset, isFetching: loadingItemAset } = useQuery(
    ['itemAset', 'dropdown', values?.id_jenis_aset],
    () =>
      ItemCheclistApi.getDropdownItemAset({
        id_jenis_aset: values?.id_jenis_aset,
      }),
    { enabled: !!values?.id_jenis_aset }
  );

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    const myPromise = new Promise((resolve) => {
      setValues({
        id_grup_aset: undefined,
        id_kategori_aset: undefined,
        id_jenis_aset: undefined,
        id_item_aset: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => {});
  };

  return (
    <FilterModal
      isToggle
      toggleModal={toggle}
      onResetButtonClick={() => onResetButtonClick(setValues)}
      onFilterButtonClick={handleSubmit}
    >
      <Select
        label="Grup Aset"
        placeholder="Pilih grup aset"
        name="id_grup_aset"
        options={grupAset ?? []}
        loading={loadingGrupAset}
        onChange={async (val) =>
          setValues({
            ...values,
            id_grup_aset: val.value,
          })
        }
        defaultValue={grupAset?.find(
          (val) => val.value === values.id_grup_aset
        )}
      />

      <Select
        label="Kategori Aset"
        placeholder="Pilih kategori aset"
        name="id_kategori_aset"
        options={kategoriAset ?? []}
        loading={loadingKategoriAset}
        disable={!values?.id_grup_aset}
        onChange={async (val) =>
          setValues({
            ...values,
            id_kategori_aset: val.value,
          })
        }
        defaultValue={kategoriAset?.find(
          (val) => val.value === values.id_kategori_aset
        )}
      />

      <Select
        label="Jenis Aset"
        placeholder="Pilih jenis aset"
        name="id_jenis_aset"
        options={jenisAset ?? []}
        loading={loadingJenisAset}
        disable={!values?.id_kategori_aset}
        onChange={async (val) =>
          setValues({
            ...values,
            id_jenis_aset: val.value,
          })
        }
        defaultValue={jenisAset?.find(
          (val) => val.value === values.id_jenis_aset
        )}
      />

      <Select
        label="Item Aset"
        placeholder="Pilih item aset"
        name="id_item_aset"
        options={itemAset ?? []}
        loading={loadingJenisAset}
        disable={!values?.id_jenis_aset}
        onChange={async (val) =>
          setValues({
            ...values,
            id_item_aset: val.value,
          })
        }
        defaultValue={itemAset?.find(
          (val) => val.value === values.id_item_aset
        )}
      />
    </FilterModal>
  );
};
