import { StockOpnameApi } from "api";
import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";

export const AlertAlreadyStockOpname = ({ tanggal }) => {
  const [show, setShow] = useState(false);

  const checkStockOpname = () => {
    StockOpnameApi.alreadyStockOpname({ tanggal }).then(({ data }) => {
      setShow(data?.status);
    });
  };

  useEffect(() => {
    checkStockOpname();
  }, [tanggal]);

  return (
    <div>
      <Alert
        variant="danger"
        show={show}
        className="text-center font-weight-bold"
      >
        !Peringatan!
        <br />
        Sudah ada transaksi stock opname pada bulan dan tahun yang sudah
        dipilih, karena akan memengaruhi stok setelah stock opname
      </Alert>
    </div>
  );
};
