import { useFormikContext } from 'formik';
import {
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  DataStatus,
} from 'components';

export const TableRealisasiPekerjaan = ({ readOnly }) => {
  const { values } = useFormikContext();

  return (
    <>
      <div className="font-weight-bold" style={{ fontSize: '14px' }}>
        Realisasi Pekerjaan
      </div>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>Rincian Pekerjaan</Th>
            <Th width={150}>Progress s/d Saat Ini</Th>
          </Tr>
        </THead>

        <TBody>
          {values?.rincian_pekerjaan?.length ? (
            values?.rincian_pekerjaan?.map((val, index) => (
              <Tr key={val.id_work_order_rinc}>
                <TdFixed textCenter>{index + 1}</TdFixed>
                <Td>{val.nama_pekerjaan ?? '-'}</Td>
                <Td textRight>{parseFloat(val.akumulasi_progress ?? 0)}%</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={8}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
          {!!values?.rincian_pekerjaan?.length && (
            <Tr className="font-weight-bold">
              <Td textRight colSpan={2}>
                Total Progress Seluruh Rincian Pekerjaan
              </Td>
              <Td textRight>
                {calcTotalProgress(values?.rincian_pekerjaan ?? [])
                  .totalProgress ?? 0}
                %
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};

export const calcTotalProgress = (data = []) => {
  const allProgress = data?.reduce(
    (total, item) => total + parseFloat(item?.akumulasi_progress ?? 0),
    0
  );

  const totalProgress = allProgress / data?.length;

  return {
    allProgress,
    totalProgress,
  };
};
