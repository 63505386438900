import { useState, createContext } from 'react';
import { WorkOrderApi } from 'api';
import { useQuery } from 'react-query';

export const RealisasiWorkOrderContext = createContext();

export const RealisasiWorkOrderProvider = ({ children }) => {
  const STALE_DURATION_MINUTES = 4 * 60 * 1000; // 4 MINUTES

  const [modalWorkOrder, setModalWorkOrder] = useState({
    show: false,
    isCreate: false,
    data: null,
  });

  const [dataHistory, setDataHistory] = useState([
    {
      bahan: [],
      upah: [],
      alat_mesin: [],
      subkon: [],
      overhead: [],
    },
  ]);

  const dropdownSparepart = useQuery(
    ['dropdown', 'sparepart'],
    () =>
      WorkOrderApi.getDropdown({ id_buaso: '1', id_kelompok: '14' }).then(
        (res) => {
          const data = res.data.data.map((val) => ({
            ...val,
            value: val.id_item,
            label: `${val.kode_item} | ${val.nama_item}`,
          }));

          return data;
        }
      ),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownAset = useQuery(
    ['dropdown', 'aset'],
    () =>
      WorkOrderApi.getDropdownAset().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item_aset,
          label: val.nama_item_aset,
        }));

        return data;
      }),

    { staleTime: STALE_DURATION_MINUTES }
  );

  return (
    <RealisasiWorkOrderContext.Provider
      value={{
        modalWorkOrder,
        setModalWorkOrder,
        dataHistory,
        setDataHistory,
        dropdownAset,
        dropdownSparepart,
      }}
    >
      {children}
    </RealisasiWorkOrderContext.Provider>
  );
};

export const realisasiWorkOrderProvider = (Component) => (props) => {
  return (
    <RealisasiWorkOrderProvider>
      <Component {...props} />
    </RealisasiWorkOrderProvider>
  );
};
