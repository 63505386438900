import { ButtonBack, ButtonCreate } from '@bhawanadevteam/react-core';
import { InfoItemHorizontal } from 'components';
import { Formik, useFormikContext } from 'formik';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { TableChecklist } from './TableChecklist';
import { DateConvert } from 'utilities';
import * as Yup from 'yup';

export const ModalForm = ({ setModalConfig, modalConfig, action }) => {
  const { values: formValues, setValues: formSetValues } = useFormikContext();

  const dataAnalisaChecklistFiltered = (data, type) =>
    data
      ?.filter(
        (val) =>
          String(val?.nama_jenis)?.toUpperCase() === String(type)?.toUpperCase()
      )
      .map((val, ind) => ({
        ...val,
        status_value: val.status_value ?? undefined,
        status_label: val.status_label ?? '',
        kondisi_value: val.kondisi_value ?? undefined,
        kondisi_label: val.kondisi_label ?? '',
        keterangan: val.keterangan ?? '',
      }));

  const tableInitialValues = {
    perlengkapan: dataAnalisaChecklistFiltered(
      modalConfig?.data?.analisa_checklist,
      'perlengkapan'
    ),
    fungsi: dataAnalisaChecklistFiltered(
      modalConfig?.data?.analisa_checklist,
      'fungsi'
    ),
  };

  const tableValidationSchema = Yup.object({
    perlengkapan: Yup.array().of(
      Yup.object().shape({
        status_value: Yup.string().required('Status wajib diisi'),
        kondisi_value: Yup.string().required('Kondisi wajib diisi'),
      })
    ),
    fungsi: Yup.array().of(
      Yup.object().shape({
        status_value: Yup.string().required('Status wajib diisi'),
        kondisi_value: Yup.string().required('Kondisi wajib diisi'),
      })
    ),
  });

  const tableSubmitHandler = (value) => {
    formSetValues({
      ...formValues,
      detail_item_pemeliharaan_aset:
        formValues.detail_item_pemeliharaan_aset.map((val, ind) =>
          ind === modalConfig?.key
            ? {
                ...val,
                status_checklist: true,
                analisa_checklist: [...value?.perlengkapan, ...value?.fungsi],
              }
            : val
        ),
    });
    //

    setModalConfig({ show: false, data: {} });
  };

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ show: false, data: {}, type: '' })}
      size="xl"
    >
      <Modal.Header closeButton>
        {action === 'detail'
          ? 'Detail Form Checklist'
          : 'Tambah Form Checklist'}
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={tableInitialValues}
        validationSchema={tableValidationSchema}
        onSubmit={tableSubmitHandler}
      >
        {({ values, handleSubmit }) => (
          <>
            <Modal.Body>
              <InfoItemHorizontal
                label="Tgl. Maintenance Request"
                text={
                  formValues?.tgl_maintenance_request_master
                    ? DateConvert(
                        new Date(formValues?.tgl_maintenance_request_master)
                      ).detail
                    : '-'
                }
              />
              <InfoItemHorizontal
                label="No. Maintenance Request"
                text={formValues?.no_maintenance_request_master}
              />
              <InfoItemHorizontal
                label="Item Aset"
                text={modalConfig?.data?.nama_item_aset}
              />
              <InfoItemHorizontal
                label="Keterangan"
                text={modalConfig?.data?.keterangan}
              />
              <div className="mt-3">
                <small>
                  <b>Checklist Perlengkapan</b>
                </small>
                <TableChecklist
                  type="perlengkapan"
                  data={values?.perlengkapan ?? []}
                  action={action}
                />
              </div>

              <div className="mt-3">
                <small>
                  <b>Checklist Fungsi</b>
                </small>
                <TableChecklist
                  type="fungsi"
                  data={values?.fungsi ?? []}
                  action={action}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ButtonBack
                onClick={() =>
                  setModalConfig((prev) => ({ ...prev, show: false }))
                }
              />
              {action !== 'detail' && <ButtonCreate onClick={handleSubmit} />}
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
