import Services from 'services';

class TransferAlatMesinProduksiAsetApi {
  async getList(params) {
    const fetch = await Services.get(
      '/transfer_alat_mesin_produksi_aset/page',
      {
        params,
      }
    );
    return fetch.data;
  }

  async getSingle(params) {
    const fetch = await Services.get(
      '/transfer_alat_mesin_produksi_aset/single',
      {
        params,
      }
    );
    return fetch.data;
  }

  async dropdownItem(params) {
    const fetch = await Services.get(
      '/dropdown/item_alat_mesin_produksi_aset',
      {
        params,
      }
    );

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item,
      value: val.id_item,
    }));
  }
}

export default new TransferAlatMesinProduksiAsetApi();
