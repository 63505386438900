import { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Nav, Tab } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  DatePicker,
  Input,
} from 'components';
import { RealisasiWorkOrderApi } from 'api';
import {
  InfoSectionDetailWorkOrder,
  TabAlatMesinOverhead,
  TabBahan,
  TableRealisasiPekerjaan,
  TabUpahSubkon,
} from './components';
import {
  RealisasiWorkOrderContext,
  realisasiWorkOrderProvider,
} from './context';
import { formInitialValues, mapSparepartUpahSubkonFromServer } from './helpers';
import { DateConvert } from 'utilities';
import { AlertAlreadyStockOpname } from 'components/Alert';

export const TambahRealisasiWorkOrder = realisasiWorkOrderProvider(
  ({ setNavbarTitle }) => {
    const title = 'Realisasi Work Order';
    const location = useLocation();
    const { id_work_order } = useParams();
    const history = useHistory();
    const formikRef = useRef(null);
    const { setDataHistory } = useContext(RealisasiWorkOrderContext);

    const [data, setData] = useState({});
    const [page, setPage] = useState({
      loading: true,
      status: false,
    });
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: 'primary',
      text: '',
    });

    const getData = () => {
      setPage({
        loading: true,
        status: false,
      });

      RealisasiWorkOrderApi.getSingleWorkOrder({
        id_work_order,
      })
        .then((res) => {
          const { history, ...data } = res?.data?.data ?? {};

          setData(data);
          setDataHistory({
            ...history,
            sparepart_upah: mapSparepartUpahSubkonFromServer(
              history?.upah,
              'upah'
            ),
            sparepart_subkon: mapSparepartUpahSubkonFromServer(
              history?.subkon,
              'subkon'
            ),
          });

          setPage({
            loading: false,
            status: true,
          });
        })
        .catch(() => {
          setPage({
            loading: false,
            status: true,
          });
        });
    };

    const getNomor = (date) => {
      RealisasiWorkOrderApi.getNomor(date).then((res) => {
        formikRef.current.setFieldValue(
          'no_realisasi_work_order',
          res.data.data
        );
      });
    };

    const onSubmit = (values, { setSubmitting }) => {
      const { sparepart_upah, sparepart_subkon, ...data } = values;

      const mapData = {
        ...data,
        tgl_realisasi_work_order: DateConvert(
          new Date(data.tgl_realisasi_work_order)
        ).default,
        bahan: data?.bahan.filter((item) => item.qty_realisasi_bahan > 0),
        upah: data?.upah
          ?.filter((item) => item.qty_realisasi_upah > 0)
          ?.map((item) => ({
            ...item,
            sparepart_upah: sparepart_upah.filter(
              (sparepart) =>
                sparepart.id_transfer_work_order_upah ===
                item.id_transfer_work_order_upah
            ),
          })),
        alat_mesin: data?.alat_mesin?.filter(
          (item) => item.qty_realisasi_alat_mesin > 0
        ),
        subkon: data?.subkon?.map((item) => ({
          ...item,
          sparepart_subkon: sparepart_subkon.filter(
            (sparepart) =>
              sparepart.id_transfer_work_order_subkon ===
              item.id_transfer_work_order_subkon
          ),
        })),
        overhead: data?.overhead?.filter(
          (item) => item.qty_realisasi_overhead > 0
        ),
      };

      RealisasiWorkOrderApi.save(mapData)
        .then((res) => {
          history.replace('/transaksi/realisasi-work-order', location.state);
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            text: 'Tambah data gagal',
            variant: 'danger',
          });
        })
        .finally(() => setSubmitting(false));
    };

    useEffect(() => {
      setNavbarTitle(title);
      getData();

      // eslint-disable-next-line
    }, []);

    return (
      <>
        <div className="text-right">
          <BackButton
            onClick={() =>
              history.replace(
                '/transaksi/realisasi-work-order/list-work-order/',
                location.state
              )
            }
          />
        </div>

        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => {
            setAlertConfig({
              ...alertConfig,
              show: false,
            });
          }}
        />

        {page.loading || !page.status ? (
          <DataStatus
            loading={page.loading}
            text={
              page.loading
                ? 'Memuat data...'
                : 'Data gagal dimuat, tidak dapat menampilkan data'
            }
          />
        ) : (
          <Formik
            enableReinitialize
            innerRef={formikRef}
            initialValues={formInitialValues(data)}
            validationSchema={yup.object().shape({
              tgl_realisasi_work_order: yup
                .string()
                .required('Tgl. Realisasi Work Order diperlukan!'),
            })}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }) => {
              return (
                <>
                  <InfoSectionDetailWorkOrder data={data} />

                  <div
                    className="font-weight-bold "
                    style={{ fontSize: '14px' }}
                  >
                    Tambah Realisasi Work Order
                  </div>

                  <Card className="mb-3">
                    <Card.Body>
                      <Row>
                        <Col>
                          <DatePicker
                            label="Tgl. Realisasi Work Order"
                            selected={
                              values.tgl_realisasi_work_order
                                ? new Date(values.tgl_realisasi_work_order)
                                : null
                            }
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => {
                              setFieldValue('tgl_realisasi_work_order', date);
                              getNomor(date);
                            }}
                            error={
                              errors?.tgl_realisasi_work_order &&
                              touched?.tgl_realisasi_work_order
                            }
                            errorText={errors?.tgl_realisasi_work_order}
                          />
                        </Col>

                        <Col>
                          <Input
                            disabled
                            label="No. Realisasi Work Order"
                            value={values.no_realisasi_work_order}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>

                  <TableRealisasiPekerjaan />

                  <div
                    className="font-weight-bold"
                    style={{ fontSize: '14px' }}
                  >
                    Realisasi Biaya
                  </div>

                  <Card className="mb-3">
                    <Tab.Container defaultActiveKey="bahan">
                      <Card.Header>
                        <Nav variant="tabs" defaultActiveKey="bahan">
                          <NavTab eventKey="bahan" title="Bahan" />
                          <NavTab eventKey="upah" title="Upah" />
                          <NavTab eventKey="alat" title="Alat Mesin" />
                          <NavTab eventKey="subkon" title="Subkon" />
                          <NavTab eventKey="overhead" title="Overhead" />
                        </Nav>
                      </Card.Header>

                      {/* Tab Content */}
                      <Card.Body className="p-3">
                        <Tab.Content>
                          <TabPane eventKey="bahan" component={<TabBahan />} />
                          <TabPane
                            eventKey="upah"
                            component={<TabUpahSubkon buaso="upah" />}
                          />
                          <TabPane
                            eventKey="alat"
                            component={
                              <TabAlatMesinOverhead buaso="alat_mesin" />
                            }
                          />
                          <TabPane
                            eventKey="subkon"
                            component={<TabUpahSubkon buaso="subkon" />}
                          />
                          <TabPane
                            eventKey="overhead"
                            component={
                              <TabAlatMesinOverhead buaso="overhead" />
                            }
                          />
                        </Tab.Content>
                      </Card.Body>
                    </Tab.Container>
                  </Card>
                  <AlertAlreadyStockOpname
                    tanggal={values?.tgl_realisasi_work_order}
                  />

                  <Row>
                    <Col className="d-flex justify-content-end algin-items-end">
                      <ActionButton
                        text="Simpan"
                        loading={isSubmitting}
                        onClick={handleSubmit}
                      />
                    </Col>
                  </Row>
                </>
              );
            }}
          </Formik>
        )}
      </>
    );
  }
);

const NavTab = ({ eventKey, title }) => (
  <Nav.Item>
    <Nav.Link eventKey={eventKey}>{title}</Nav.Link>
  </Nav.Item>
);

const TabPane = ({ eventKey, component }) => (
  <Tab.Pane eventKey={eventKey}>{component}</Tab.Pane>
);
