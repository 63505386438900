import { useState, useContext } from 'react';
import { Formik } from 'formik';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { DropdownContext } from '../context/DropdownContext';

export const ModalFilterPage = ({ toggle, data, setData }) => {
  const [loading, setLoading] = useState(false);
  const { dropdownKaryawan, dropdownAlat } = useContext(DropdownContext);
  const option = { label: 'Semua Data', value: undefined };
  const optionKaryawan = [option].concat(dropdownKaryawan?.data ?? []);
  const optionAlat = [option].concat(dropdownAlat?.data ?? []);

  const formInitialValues = {
    tgl_transfer_work_order_alat_mesin_mulai:
      data.tgl_transfer_work_order_alat_mesin_mulai,
    tgl_transfer_work_order_alat_mesin_selesai:
      data.tgl_transfer_work_order_alat_mesin_selesai,

    tgl_permintaan_work_order_alat_mesin_mulai:
      data.tgl_permintaan_work_order_alat_mesin_mulai,
    tgl_permintaan_work_order_alat_mesin_selesai:
      data.tgl_permintaan_work_order_alat_mesin_selesai,

    id_item_alat_mesin: data.id_item_alat_mesin,
    id_karyawan: data.id_karyawan,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setData({
      ...data,
      ...values,
      active: checkActive,
      page: 1,
    });

    toggle();
  };

  const onResetButtonClick = (setValues) => {
    setLoading(true);

    const myPromise = new Promise((resolve) => {
      setValues({
        tgl_transfer_work_order_alat_mesin_mulai: undefined,
        tgl_transfer_work_order_alat_mesin_selesai: undefined,
        tgl_permintaan_work_order_alat_mesin_mulai: undefined,
        tgl_permintaan_work_order_alat_mesin_selesai: undefined,
        id_item_alat_mesin: undefined,
        id_karyawan: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => setLoading(false));
  };

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_mulai,
    value_selesai,
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_mulai]: startDate,
      [value_selesai]: endDate,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          isToggle
          toggleModal={toggle}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            monthsShown={2}
            label="Tgl. Transfer Alat Mesin Work Order"
            placeholderText="Pilih Tanggal Transfer Alat Mesin Work Order"
            startDate={
              values?.tgl_transfer_work_order_alat_mesin_mulai
                ? new Date(values?.tgl_transfer_work_order_alat_mesin_mulai)
                : ''
            }
            endDate={
              values?.tgl_transfer_work_order_alat_mesin_selesai
                ? new Date(values?.tgl_transfer_work_order_alat_mesin_selesai)
                : ''
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                'tgl_transfer_work_order_alat_mesin_mulai',
                'tgl_transfer_work_order_alat_mesin_selesai',
              )
            }
          />

          <DatePicker
            selectsRange
            label="Tgl. Permintaan Work Order"
            placeholderText={`Pilih Tanggal Permintaan Produksi`}
            startDate={
              values?.tgl_permintaan_work_order_alat_mesin_mulai
                ? new Date(values?.tgl_permintaan_work_order_alat_mesin_mulai)
                : ''
            }
            endDate={
              values?.tgl_permintaan_work_order_alat_mesin_selesai
                ? new Date(values?.tgl_permintaan_work_order_alat_mesin_selesai)
                : ''
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                `tgl_permintaan_work_order_alat_mesin_mulai`,
                `tgl_permintaan_work_order_alat_mesin_selesai`,
              )
            }
            monthsShown={2}
          />

          <SelectSearch
            key="1"
            label="Item Alat Mesin"
            placeholder="Pilih Item Alat Mesin"
            defaultValue={optionAlat?.find(
              (val) => val.value === values.id_item_alat_mesin,
            )}
            option={optionAlat}
            onChange={(val) => setFieldValue('id_item_alat_mesin', val.value)}
            loading={dropdownAlat?.isLoading || loading}
          />

          <SelectSearch
            key="2"
            label="Diserahkan Oleh"
            placeholder="Pilih Diserahkan Oleh"
            defaultValue={optionKaryawan.find(
              (val) => val.value === values.id_karyawan,
            )}
            option={optionKaryawan}
            onChange={(val) => setFieldValue('id_karyawan', val.value)}
            loading={dropdownKaryawan.isLoading || loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
