import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { FormAnalisaChecklistContent } from "./__AnalisaChecklistComps__";
import { useMutation, useQuery } from "react-query";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ItemChecklistApi from "./__AnalisaChecklistApi__";
import {
  formInitialValues,
  formSubmitValueMapper,
} from "./__AnalisaChecklistUtils__";
import { DataStatus } from "@bhawanadevteam/react-core";
import { Alert } from "components";

export const AnalisaChecklistDetail = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const history = useHistory();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: "",
    variant: "primary",
  });
  const { data: detailItemAset, isFetching: loadingItemAset } = useQuery(
    ["analisa_checklist", "detail"],
    () => ItemChecklistApi.getSingle({ id_analisa_checklist: id })
  );

  const detailAnalisaChecklist = useMutation((data) =>
    ItemChecklistApi.update(data)
  );
  const formSubmitHandler = (value) => {
    detailAnalisaChecklist
      .mutateAsync(formSubmitValueMapper(value))
      .then(() =>
        history.push("/asm/master/analisa-checklist", {
          alert: {
            variant: "primary",
            show: true,
            text: "Data berhasil diubah!!!",
          },
        })
      )
      .catch((err) =>
        setAlertConfig({
          variant: "danger",
          show: true,
          text: "Data gagal disimpan!!!",
        })
      );
  };

  useEffect(() => {
    setNavbarTitle("Analisa Checklist");
  }, []);

  return loadingItemAset ? (
    <DataStatus loading />
  ) : (
    <>
      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
        showCloseButton
      />
      <Formik
        initialValues={formInitialValues(detailItemAset?.data)}
        onSubmit={formSubmitHandler}
      >
        <FormAnalisaChecklistContent action="detail" />
      </Formik>
    </>
  );
};
