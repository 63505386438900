export const formInitialValues = (data = {}) => {
  return Object({
    id_item_checklist: data?.id_item_checklist ?? undefined,
    jenis_checklist: data?.jenis_checklist ?? "",
    nama_jenis_checklist: data?.nama_jenis_checklist ?? "",
    nama_item_checklist: data?.nama_item_checklist ?? "",
    keterangan: data?.keterangan ?? "",
    kode_item: data?.kode_item_checklist ?? "",
  });
};
