export const formInitialValues = (data) => {
  return Object({
    id_work_order: data?.id_work_order ?? undefined,
    tgl_work_order: data?.tgl_work_order ?? null,
    no_work_order: data?.no_work_order ?? '',
    nama_item_aset: data?.nama_item_aset ?? '',
    nama_petugas: data?.nama_petugas ?? '',
    nama_vendor: data?.nama_vendor ?? '',
    catatan_maintenance_request: data?.catatan_maintenance_request ?? '',
    tgl_maintenance_request_master:
      data?.tgl_maintenance_request_master ?? null,
    no_maintenance_request_master: data?.no_maintenance_request_master ?? '',
    bahan: data?.buaso?.bahan ?? [],
    upah: data?.buaso?.upah ?? [],
    alat_mesin: data?.buaso?.alat_mesin ?? [],
    subkon: data?.buaso?.subkon ?? [],
    overhead: data?.buaso?.overhead ?? [],
    bahan_history: data?.history?.bahan ?? [],
    upah_history: data?.history?.upah ?? [],
    alat_mesin_history: data?.history?.alat_mesin ?? [],
    subkon_history: data?.history?.subkon ?? [],
    overhead_history: data?.history?.overhead ?? [],
    is_mekanik_eksternal: data?.is_mekanik_eksternal,
  });
};
