import Services from 'services';

class WorkOrderApi {
  page(params) {
    return Services.get('/work_order/page', { params });
  }

  single(params) {
    return Services.get('/work_order/single', { params });
  }

  preform(params) {
    return Services.get('/work_order/maintenance', { params });
  }

  getSingleMaintenance(params) {
    return Services.get('/work_order/single_maintenance', { params });
  }

  getNomor(params) {
    return Services.get('/work_order/no_baru', { params });
  }

  getDropdown(params) {
    return Services.get('/dropdown/item_w_hps', { params });
  }

  getDropdownKaryawan() {
    return Services.get('/dropdown/karyawan');
  }

  getDropdownAset() {
    return Services.get('/analisa_item_checklist/item_aset');
  }

  getDropdownVendor() {
    return Services.get('/dropdown/vendor');
  }

  save(data) {
    return Services.post('/work_order', data);
  }
}

export default new WorkOrderApi();
