import React, { useState, useEffect } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
import {
  CRUDLayout,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  Pagination,
  Alert,
  ApprovalStatusButton,
} from 'components';
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonUpdate,
  ButtonFilter,
  ButtonDetail,
  Button,
} from 'components2';
import { tableNumber } from 'utilities2';
import { ModalFilter } from './__PermintaanAsetComps__';
import MaintenanceRequestApi from './__PermintaanAsetApi__';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { Formik } from 'formik';
import { DateConvert } from 'utilities';
import { getStatusApprovalLabel } from './__PermintaanAsetUtils__';

export const PermintaanAsetList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: '',
    variant: 'primary',
  });

  const [filter, setFilter] = useState({
    q: '',
    page: 1,
    per_page: 10,
    tgl_permintaan_aset_mulai: undefined,
    tgl_permintaan_aset_selesai: undefined,
    status_approval: undefined,
    active: false,
  });

  const [modalFilter, setModalFilter] = useState(false);

  const getPermintaanAset = useQuery(
    ['analisa_checklist', 'list', filter],
    () => MaintenanceRequestApi.getList(filter)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setFilter({
      ...filter,
      q: searchKey,
    });
    setAlertConfig({
      show: searchKey ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  const onPaginationChange = ({ selected }) =>
    setFilter({ ...filter, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setFilter({ ...filter, page: 1, per_page: e.target.value });

  const checkAlert = () => {
    if (state) {
      if (state.alert) {
        setAlertConfig({
          show: state?.alert?.show,
          text: state?.alert?.text,
          variant: state?.alert?.variant,
        });
      }
    }
  };

  // FORM VALUES FILTER
  const formInitialValuesFilter = {
    tgl_permintaan_aset_mulai: filter?.tgl_permintaan_aset_mulai,
    tgl_permintaan_aset_selesai: filter?.tgl_permintaan_aset_selesai,
    status_approval: filter?.status_approval,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  useEffect(() => {
    setNavbarTitle('Permintaan Aset');
    checkAlert();
  }, []);

  const ItemCollapse = ({ data }) => {
    const [isReadMoreClick, setisReadMoreClick] = useState(false);
    return (
      <>
        <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
          {data.map((brg, index) => {
            if (isReadMoreClick) {
              return (
                <li key={index} index={index} className="text-capitalize">
                  {brg?.nama_aset} | {brg?.keperluan} | {brg?.metode_pengadaan}
                </li>
              );
            } else {
              return (
                index < 2 && (
                  <li key={index} index={index} className="text-capitalize">
                    {brg?.nama_aset} | {brg?.keperluan} |{' '}
                    {brg?.metode_pengadaan}
                  </li>
                )
              );
            }
          })}
        </ul>
        {data.length > 2 && (
          <div
            className="text-primary"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => setisReadMoreClick((prev) => !prev)}
          >
            {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
          </div>
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonCreate
            size="md"
            icon
            onClick={() => history.push('permintaan-aset/tambah')}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getPermintaanAset.isLoading || getPermintaanAset.isError ? (
        <DataStatus
          loading={getPermintaanAset.isLoading}
          text={
            getPermintaanAset.isLoading ? 'Memuat . . .' : 'Data gagal dimuat'
          }
        />
      ) : (
        <>
          <Alert
            show={alertConfig?.show}
            text={alertConfig?.text}
            variant={alertConfig?.variant}
            showCloseButton
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Permintaan Aset
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <ThFixed>Informasi Permintaan Aset</ThFixed>
                <Th>Item Aset Yang Diminta</Th>
                <ThFixed>Status Approval</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {getPermintaanAset?.data?.data?.length > 0 ? (
                getPermintaanAset?.data?.data?.map((e, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: filter.page,
                        dataLength: filter.per_page,
                        index,
                      })}
                    </TdFixed>
                    <TdFixed>
                      <ButtonGroup>
                        <ButtonDetail
                          icon
                          noText
                          onClick={() =>
                            history.push(
                              '/transaksi/permintaan-aset/detail/' +
                                e?.id_permintaan_aset
                            )
                          }
                        />
                        {e?.status_approval === 'REV' && (
                          <ButtonUpdate
                            icon
                            noText
                            variant="success"
                            onClick={() =>
                              history.push(
                                '/transaksi/permintaan-aset/ubah/' +
                                  e?.id_permintaan_aset
                              )
                            }
                          />
                        )}
                      </ButtonGroup>
                    </TdFixed>
                    <Td>
                      <div>
                        {DateConvert(new Date(e?.tgl_permintaan_aset)).custom}
                      </div>
                      <div>{e.no_permintaan_aset ?? '-'}</div>
                    </Td>
                    <Td>
                      <ItemCollapse data={e.detail ?? []} />
                    </Td>
                    <Td>
                      <ApprovalStatusButton
                        variant={
                          getStatusApprovalLabel(e?.status_approval).variant
                        }
                      >
                        {getStatusApprovalLabel(e?.status_approval).label}
                      </ApprovalStatusButton>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={filter?.per_page}
            dataNumber={filter?.page * filter?.per_page - filter?.per_page + 1}
            dataPage={
              filter?.dataCount < filter?.per_page
                ? filter?.dataCount
                : filter?.page * filter?.per_page
            }
            dataCount={getPermintaanAset?.data?.data_count}
            currentPage={filter?.page}
            totalPage={getPermintaanAset?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter toggle={onFilterButtonClickkHandler} />
        </Formik>
      )}
    </CRUDLayout>
  );
};
