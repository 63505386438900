import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { Row, Col, Card, Tab, Nav } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';
import {
  Input,
  InfoItemHorizontal,
  Select,
  TextArea,
  ButtonBack,
  ButtonCreate,
  ButtonDetail,
  ButtonUpdate,
} from 'components2';

import PenerimaanAsetApi from '../__PenerimaanAsetApi__';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';

import { DateConvert } from 'utilities';
import TabGambar from '../../PermintaanAset/__PermintaanAsetComps__/TabGambar';
import TabFile from './TabFile';
import { DatePicker, InfoItemVertical, SelectSearch } from 'components';
import { InputBuktiPenerimaan } from './InputBuktiPenerimaan';
import { ModalRegistrasiAset } from './ModalRegistrasiAset';
import { formInitialValuesRegisterAset } from '../__PermintaanAsetUtils__';

const InfoPurchaseOrderAset = () => {
  const { values } = useFormikContext();
  return (
    <>
      <Row>
        <Col>
          <InfoItemHorizontal
            label="Tgl. Purchase Order Aset"
            text={
              values?.tgl_purchase_order_aset
                ? DateConvert(new Date(values?.tgl_purchase_order_aset)).detail
                : '-'
            }
          />

          <InfoItemHorizontal
            label="No. Purchase Order Aset"
            text={values?.no_purchase_order_aset ?? '-'}
          />

          <InfoItemHorizontal
            label="Tgl. Seleksi Vendor Aset"
            text={
              values?.tgl_seleksi_vendor_aset
                ? DateConvert(new Date(values?.tgl_seleksi_vendor_aset)).detail
                : '-'
            }
          />

          <InfoItemHorizontal
            label="No. Seleksi Vendor Aset"
            text={values?.no_seleksi_vendor_aset ?? '-'}
          />
        </Col>
        <Col>
          <InfoItemHorizontal
            label="Grup Aset"
            text={values?.nama_grup_aset ?? '-'}
          />
          <InfoItemHorizontal
            label="Kategori Aset"
            text={values?.nama_kategori_aset ?? '-'}
          />
          <InfoItemHorizontal
            label="Jenis Aset"
            text={values?.nama_jenis_aset ?? '-'}
          />
          <InfoItemHorizontal
            label="Item Aset Yang Diminta"
            text={values?.nama_aset ?? '-'}
          />
          <InfoItemHorizontal
            label="Keperluan"
            text={values?.keperluan ?? '-'}
          />
        </Col>
      </Row>
      <Card className="mt-3">
        <Tab.Container defaultActiveKey="tabGambar">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="tabGambar">Gambar</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabFile">File</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Tab.Content>
            <Tab.Pane eventKey="tabGambar">
              <div className="m-2">
                <TabGambar readOnly />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="tabFile">
              <div className="m-2">
                <TabFile readOnly />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>
    </>
  );
};

const InfoPenerimaanAset = () => {
  const [modalConfig, setModalConfig] = useState({
    show: false,
    id: undefined,
  });
  const { values } = useFormikContext();
  const { state } = useLocation();
  console.log(state);

  const checkSinglePerTab = () => {
    if (state.type === 'tanah') {
      return PenerimaanAsetApi.getSingleRegisterTanah({
        id_item_aset: modalConfig?.id,
      });
    } else if (state.type === 'bangunan') {
      return PenerimaanAsetApi.getSingleRegisterBangunan({
        id_item_aset: modalConfig?.id,
      });
    } else if (state.type === 'kendaraan') {
      return PenerimaanAsetApi.getSingleRegisterKendaraan({
        id_item_aset: modalConfig?.id,
      });
    } else if (state.type === 'alatMesin') {
      return PenerimaanAsetApi.getSingleRegisterAlatMesin({
        id_item_aset: modalConfig?.id,
      });
    } else if (state.type === 'inventaris') {
      return PenerimaanAsetApi.getSingleRegisterInventaris({
        id_item_aset: modalConfig?.id,
      });
    } else if (state.type === 'sistem') {
      return PenerimaanAsetApi.getSingleRegisterSistem({
        id_item_aset: modalConfig?.id,
      });
    }
    return PenerimaanAsetApi.getSingleRegisterLainnya({
      id_item_aset: modalConfig?.id,
    });
  };

  const { data: detailRegisterAset, isFetching: loadingDetailRegisterAset } =
    useQuery(
      ['registerAset', 'single', modalConfig?.id],
      () => checkSinglePerTab(),
      { enabled: !!modalConfig?.id }
    );

  return (
    <>
      <Row>
        <Col>
          <InfoItemVertical
            label="Tgl. Penerimaan Aset"
            text={
              values?.tgl_penerimaan_aset
                ? DateConvert(new Date(values?.tgl_penerimaan_aset)).detail
                : '-'
            }
          />
          <InfoItemVertical
            label="No. Penerimaan Aset"
            text={values?.no_penerimaan_aset ?? '-'}
          />
          <InfoItemVertical
            label="Surat Jalan Vendor"
            text={values?.no_surat_jalan_vendor ?? '-'}
          />
          <InfoItemVertical
            label="Pentugas Penerimaan"
            text={values?.nama_petugas_penerimaan ?? '-'}
          />
          <InfoItemVertical
            label="Detail Aset Yang Diterima"
            text={
              <ButtonDetail
                noText
                icon
                disabled={!values?.id_aset_yang_diterima}
                variant="primary"
                onClick={() =>
                  setModalConfig({
                    id: values?.id_aset_yang_diterima,
                    show: true,
                  })
                }
              />
            }
          />
        </Col>
        <Col>
          {values?.bukti_penerimaan && (
            <img
              style={{ maxWidth: 180 }}
              src={values?.bukti_penerimaan}
              alt=""
              className="mb-4 img-fluid"
            />
          )}
        </Col>
      </Row>

      {!loadingDetailRegisterAset && modalConfig?.show ? (
        <Formik
          initialValues={formInitialValuesRegisterAset(
            detailRegisterAset?.data ?? {}
          )}
        >
          <ModalRegistrasiAset
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
            type={state?.type}
            action="detail"
          />
        </Formik>
      ) : (
        ''
      )}
    </>
  );
};

export const FormPermintaanAsetContent = ({ action }) => {
  const {
    values,
    setValues,
    handleChange,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
  } = useFormikContext();
  const history = useHistory();

  const { isFetching: loadingKode } = useQuery(
    ['penerimaanAset', 'kode', values?.tgl_penerimaan_aset],
    () =>
      PenerimaanAsetApi.generateNumber({
        tanggal: values?.tgl_penerimaan_aset,
      }).then((val) =>
        setValues({ ...values, no_penerimaan_aset: val?.data?.data })
      ),
    {
      enabled: !!values?.tgl_penerimaan_aset && action === 'create',
    }
  );

  const {
    data: dropdownPetugasPenerimaan,
    isFetching: loadingDropdownPetugasPenerimaan,
  } = useQuery(['petugasPenerimaan', 'dropdown'], () =>
    PenerimaanAsetApi.getDropdownwPetugasPenerimaan()
  );

  return (
    <Fragment>
      <div className="d-flex justify-content-between mb-2">
        <small className="font-weight-bold">
          {action === 'create'
            ? 'Tambah Data Penerimaan Aset'
            : action === 'update'
            ? 'Ubah Data Penerimaan Aset'
            : 'Detail Data Penerimaan Aset'}
        </small>

        <ButtonBack
          size="sm"
          onClick={() =>
            action === 'create'
              ? history.push('/transaksi/penerimaan-aset/purchase-order')
              : history.push('/transaksi/penerimaan-aset')
          }
        />
      </div>
      <Card>
        <Card.Body>
          <InfoPurchaseOrderAset />
          <hr />
          {action === 'detail' ? (
            <InfoPenerimaanAset />
          ) : (
            <Row>
              <Col>
                <DatePicker
                  label="Tgl. Penerimaan Aset"
                  selected={
                    values?.tgl_penerimaan_aset
                      ? new Date(values?.tgl_penerimaan_aset)
                      : null
                  }
                  onChange={(val) =>
                    setValues({
                      ...values,
                      tgl_penerimaan_aset: DateConvert(new Date(val)).default,
                    })
                  }
                  error={
                    errors.tgl_penerimaan_aset &&
                    touched.tgl_penerimaan_aset &&
                    true
                  }
                  errorText={
                    errors.tgl_penerimaan_aset &&
                    touched.tgl_penerimaan_aset &&
                    errors.tgl_penerimaan_aset
                  }
                />

                <Input
                  label="No. Penerimaan Aset"
                  disabled
                  placeholder="Pilih tanggal terlebih dahulu!!!"
                  loading={loadingKode}
                  value={values?.no_penerimaan_aset}
                />

                <Input
                  label="No. Surat Jalan Vendor"
                  type="text"
                  name="no_surat_jalan_vendor"
                  placeholder="Masukan no. surat jalan vendor"
                  value={values?.no_surat_jalan_vendor}
                  onChange={handleChange}
                  error={
                    errors.no_surat_jalan_vendor &&
                    touched.no_surat_jalan_vendor &&
                    true
                  }
                  errorText={
                    errors.no_surat_jalan_vendor &&
                    touched.no_surat_jalan_vendor &&
                    errors.no_surat_jalan_vendor
                  }
                />

                <SelectSearch
                  label="Petugas Penerimaan"
                  name="id_petugas_penerimaan"
                  placeholder="Pilih petugas penerimaan"
                  defaultValue={
                    values?.id_petugas_penerimaan
                      ? {
                          value: values?.id_petugas_penerimaan ?? undefined,
                          label: values?.nama_petugas_penerimaan ?? '',
                        }
                      : undefined
                  }
                  option={dropdownPetugasPenerimaan}
                  loading={loadingDropdownPetugasPenerimaan}
                  onChange={(val) =>
                    setValues({
                      ...values,
                      id_petugas_penerimaan: val.value,
                      nama_petugas_penerimaan: val.label,
                    })
                  }
                  error={
                    errors.id_petugas_penerimaan &&
                    touched.id_petugas_penerimaan &&
                    true
                  }
                  errorText={
                    errors.id_petugas_penerimaan &&
                    touched.id_petugas_penerimaan &&
                    errors.id_petugas_penerimaan
                  }
                />
              </Col>
              <Col>
                <InputBuktiPenerimaan />
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-end mt-3">
        {action === 'create' && (
          <ButtonCreate
            text="Simpan"
            size="sm"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        )}
        {action === 'update' && (
          <ButtonUpdate
            variant="success"
            size="sm"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        )}
      </div>
    </Fragment>
  );
};
