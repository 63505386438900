import Services from 'services';

class MaintenanceRequestApi {
  async getList(params) {
    const fetch = await Services.get('/permintaan_aset/page', {
      params,
    });
    return fetch.data;
  }

  async getSingle(params) {
    const fetch = await Services.get('/permintaan_aset/single', {
      params,
    });
    return fetch.data;
  }

  async getDropdownItemChecklist(params) {
    const fetch = await Services.get('/item_checklist', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item_checklist,
      value: val.id_item_checklist,
      kode: val.kode_item_checklist,
    }));
  }

  async getDropdownJenisChecklist() {
    return [
      {
        value: '1',
        label: 'Perlengkapan',
      },
      {
        value: '2',
        label: 'Fungsi',
      },
    ];
  }

  async getDropdownKeperluan() {
    return [
      {
        value: 'produksi',
        label: 'Produksi',
      },
      {
        value: 'operasional',
        label: 'Operasional',
      },
    ];
  }

  async getDropdownMetodePengadaan() {
    return [
      {
        value: 'produksi',
        label: 'Produksi',
      },
      {
        value: 'pembelian',
        label: 'Pembelian',
      },
    ];
  }

  async getDropdownUnitProduksi(params) {
    const fetch = await Services.get('/dropdown/unit_organisasi', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_unit_organisasi,
      value: val.id_unit_organisasi,
    }));
  }

  async getDropdownEntitasAset(params) {
    const fetch = await Services.get('/entitas_aset', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_entitas_aset,
      value: val.id_entitas_aset,
    }));
  }

  async getDropdownGrupAset(params) {
    const fetch = await Services.get('/grupaset', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_grup_aset,
      value: val.id_grup_aset,
    }));
  }

  async getDropdownKategoriAset(params) {
    const fetch = await Services.get('/kategoriaset', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_kategori_aset,
      value: val.id_kategori_aset,
    }));
  }

  async getDropdownJenisAset(params) {
    const fetch = await Services.get('/jenisaset', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_jenis_aset,
      value: val.id_jenis_aset,
    }));
  }

  async getDropdownItemAset(params) {
    const fetch = await Services.get('/analisa_item_checklist/item_aset', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item_aset,
      value: val.id_item_aset,
      kode: val.kode_item_aset,
    }));
  }

  generateNumber(params) {
    return Services.get('/permintaan_aset/no_baru', { params });
  }

  create(data) {
    return Services.post('/permintaan_aset', data);
  }

  update(data) {
    return Services.put('/permintaan_aset', data);
  }

  upload(data) {
    return Services.post('/upload/permintaan_aset_multiple', data);
  }

  exportPdf(params) {
    return Services.get('/permintaan_aset/export', { params });
  }
}

export default new MaintenanceRequestApi();
