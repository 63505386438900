import { useContext, useState } from 'react';
import { Form, Modal, Row, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ActionButton,
  Alert,
  InfoItemVertical,
  InfoItemHorizontal,
  DatePicker,
  Input,
  SelectSearch,
  InputQtyDecimal,
  TextArea,
} from 'components';
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { TransferAlatMesinWorkOrderApi } from 'api';
import { DateConvert, DecimalConvert } from 'utilities';
import { DropdownContext } from '../context/DropdownContext';

export const ModalFormTransfer = ({
  data,
  toggle,
  isCreate,
  title,
  toggleSuccess,
  getLastQtyTransfer,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  return (
    <Modal show={true} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h6 className="m-0">
            {isCreate ? 'Tambah' : 'Detail'} {title}
          </h6>
        </Modal.Title>
      </Modal.Header>

      <Formik
        enableReinitialize
        initialValues={{
          ...data,
          tgl_transfer_work_order_alat_mesin: isCreate
            ? undefined
            : data?.tgl_transfer_work_order_alat_mesin,
          no_transfer_work_order_alat_mesin: isCreate
            ? 'Pilih tanggal terlebih dahulu'
            : data?.no_transfer_work_order_alat_mesin,
          qty_transfer_work_order_alat_mesin: isCreate
            ? '0'
            : data?.qty_transfer_work_order_alat_mesin,
          keterangan_transfer: isCreate ? '' : data?.keterangan_transfer,
          qty_telah_ditransfer:
            data?.qty_transfer_work_order_alat_mesin ??
            data?.qty_telah_ditransfer_work_order_alat_mesin ??
            0,
          id_karyawan: '',
        }}
        validationSchema={yup.object().shape({
          tgl_permintaan_work_order_alat_mesin: yup
            .date()
            .typeError('Masukkan Tanggal')
            .required('Masukkan Tanggal'),
          id_karyawan: yup.string().required('Pilih Diserahkan Oleh'),
          qty_transfer_work_order_alat_mesin: yup
            .number()
            .max(
              parseFloat(data?.qty_permintaan_work_order_alat_mesin ?? 0) -
                parseFloat(data?.qty_transfer_work_order_alat_mesin ?? 0),
              `Qty. Transfer wajib diisi maksimal ${
                parseFloat(data?.qty_permintaan_work_order_alat_mesin ?? 0) -
                parseFloat(data?.qty_transfer_work_order_alat_mesin ?? 0)
              }`
            )
            .required('Qty. Transfer wajib diisi'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (isCreate) {
            TransferAlatMesinWorkOrderApi.save(values)
              .then(() => {
                if (getLastQtyTransfer?.length <= 1) {
                  history.replace(
                    '/transaksi/transfer-alat-mesin-work-order',
                    location.state
                  );
                } else {
                  toggleSuccess();
                  toggle();
                }
              })
              .catch(() => {
                setAlertConfig({
                  show: true,
                  text: 'Tambah data gagal',
                  variant: 'danger',
                });
              })
              .finally(() => setSubmitting(false));
          }
        }}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Alert
                show={alertConfig.show}
                variant={alertConfig.variant}
                text={alertConfig.text}
                showCloseButton={true}
                onClose={() => {
                  setAlertConfig({
                    ...alertConfig,
                    show: false,
                  });
                }}
              />

              <InfoItemHorizontal
                label="Tgl. Permintaan Work Order"
                text={
                  values?.tgl_permintaan_work_order_alat_mesin
                    ? DateConvert(
                        new Date(values.tgl_permintaan_work_order_alat_mesin)
                      ).detail
                    : '-'
                }
              />

              <InfoItemHorizontal
                label="No. Permintaan Work Order"
                text={values.no_permintaan_work_order_alat_mesin ?? '-'}
              />

              <InfoItemHorizontal
                label="Item Alat Mesin"
                text={values.nama_item ?? '-'}
              />

              <InfoItemHorizontal
                label="Satuan"
                text={values.nama_satuan ?? '-'}
              />

              <InfoItemHorizontal
                label="Qty. Permintaan Work Order"
                text={
                  DecimalConvert(
                    values.qty_permintaan_work_order_alat_mesin ?? 0
                  ).getWithComa
                }
              />

              <InfoItemHorizontal
                label="Qty. Telah Ditransfer"
                text={
                  DecimalConvert(data?.qty_transfer_work_order_alat_mesin ?? 0)
                    .getWithComa
                }
              />

              <hr />

              <FormSection isCreate={isCreate} />
            </Modal.Body>

            {isCreate && (
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  text="Simpan"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const FormSection = ({ isCreate }) => {
  const { values, setFieldValue, errors, touched, handleChange } =
    useFormikContext();

  const { dropdownKaryawan } = useContext(DropdownContext);

  const getNomor = (tgl) => {
    TransferAlatMesinWorkOrderApi.no_baru(tgl).then((res) => {
      setFieldValue('no_transfer_work_order_alat_mesin', res.data.data ?? '');
    });
  };

  if (!isCreate) {
    return (
      <>
        <Row>
          <Col md={6} sm={6}>
            <InfoItemVertical
              className="text-capitalize"
              label="Tgl. Transfer Alat Mesin Work Order"
              text={
                values?.tgl_transfer_work_order_alat_mesin
                  ? DateConvert(
                      new Date(values?.tgl_transfer_work_order_alat_mesin)
                    ).detail
                  : '-'
              }
            />
          </Col>

          <Col md={6} sm={6}>
            <InfoItemVertical
              className="text-capitalize"
              label="No. Transfer Alat Mesin Work Order"
              text={values?.no_transfer_work_order_alat_mesin ?? '-'}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <InfoItemVertical
              label="Diserahkan Oleh"
              text={values.nama_karyawan ?? '-'}
            />
          </Col>

          <Col md={6}>
            <InfoItemVertical
              label="Qty. Transfer"
              text={`${
                DecimalConvert(values.qty_transfer_work_order_alat_mesin ?? 0)
                  .getWithComa
              } ${values.nama_satuan ?? ''}`}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <InfoItemVertical
              label="Keterangan"
              text={values.keterangan_transfer ?? '-'}
            />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <Row>
      <Col md={6}>
        <DatePicker
          label="Tgl. Transfer Alat Mesin Work Order"
          selected={
            values.tgl_transfer_work_order_alat_mesin
              ? new Date(values.tgl_transfer_work_order_alat_mesin)
              : ''
          }
          placeholderText="Pilih Tgl. Transfer Alat Mesin Work Order "
          onChange={(date) => {
            const tanggal = DateConvert(new Date(date)).default;

            setFieldValue('tgl_transfer_work_order_alat_mesin', tanggal);
            if (isCreate) getNomor(tanggal);
          }}
          error={
            Boolean(errors.tgl_transfer_work_order_alat_mesin) &&
            touched.tgl_transfer_work_order_alat_mesin
          }
          errorText={
            Boolean(errors.tgl_transfer_work_order_alat_mesin) &&
            touched.tgl_transfer_work_order_alat_mesin &&
            errors.tgl_transfer_work_order_alat_mesin
          }
        />
      </Col>

      <Col md={6}>
        <Input
          label="No. Transfer Alat Mesin Work Order"
          placeholder="No. Transfer Alat Mesin Work Order"
          value={values.no_transfer_work_order_alat_mesin}
          readOnly
        />
      </Col>

      <Col md={8}>
        <SelectSearch
          label="Diserahkan Oleh"
          placeholder="Pilih Diserahkan Oleh"
          defaultValue={dropdownKaryawan?.data?.find(
            (e) => e.value === values.id_karyawan
          )}
          onChange={(val) => setFieldValue('id_karyawan', val.value)}
          option={dropdownKaryawan?.data ?? []}
          loading={dropdownKaryawan?.loading}
          error={Boolean(errors.id_karyawan) && touched.id_karyawan}
          errorText={
            Boolean(errors.id_karyawan) &&
            touched.id_karyawan &&
            errors.id_karyawan
          }
        />
      </Col>

      <Col md={4}>
        <InputQtyDecimal
          label="Qty. Transfer"
          value={values.qty_transfer_work_order_alat_mesin}
          onChange={(e) => {
            setFieldValue('qty_transfer_work_order_alat_mesin', e);
          }}
          error={
            Boolean(errors.qty_transfer_work_order_alat_mesin) &&
            touched.qty_transfer_work_order_alat_mesin
          }
          errorText={
            Boolean(errors.qty_transfer_work_order_alat_mesin) &&
            touched.qty_transfer_work_order_alat_mesin &&
            errors.qty_transfer_work_order_alat_mesin
          }
        />
      </Col>

      <Col md={12}>
        <TextArea
          label="Keterangan Transfer"
          name="keterangan_transfer"
          rows={4}
          placeholder="Keterangan Transfer"
          value={values.keterangan_transfer}
          onChange={handleChange}
          error={
            Boolean(errors.keterangan_transfer) && touched.keterangan_transfer
          }
          errorText={
            Boolean(errors.keterangan_transfer) &&
            touched.keterangan_transfer &&
            errors.keterangan_transfer
          }
        />
      </Col>
    </Row>
  );
};
