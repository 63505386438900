export const formSubmitValueMapper = (data = {}) => {
  return {
    tgl_form_checklist: data?.tgl_form_checklist,
    catatan: data?.catatan,
    id_maintenance_request: data?.id_maintenance_request_master,
    status_checklist: data?.status_checklist,
    detail: data?.detail_item_pemeliharaan_aset
      ?.map((item) =>
        item.analisa_checklist.map((val) => ({
          ...val,
          id_maintenance_request_detail: item?.id_maintenance_request_detail,
        }))
      )
      .flat()
      .filter(
        (checklist) =>
          checklist.kondisi_value !== undefined &&
          checklist.status_value !== undefined
      )
      .map((val) => ({
        status_perlengkapan: val?.status_value ?? undefined,
        kondisi_perlengkapan: val?.kondisi_value ?? undefined,
        keterangan: val?.keterangan ?? '-',
        id_item_checklist: val?.id_item_checklist ?? undefined,
        id_maintenance_request_detail:
          val?.id_maintenance_request_detail ?? undefined,
      })),
  };
};
