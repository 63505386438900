import React, { useState, useEffect } from "react";
import {
  CRUDLayout,
  Input,
  InputSearch,
  ExportButton,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  UpdateButton,
  DeleteButton,
  Switch,
  Select,
  Alert,
  Pagination,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
} from "../../../components";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { KategoriAsetApi } from "../../../api";
import { PageNumber } from "../../../utilities";
import { ModalBody, ModalFooter, Row, Col, ButtonGroup } from "react-bootstrap";

const KategoriAset = ({ setNavbarTitle }) => {
  const title = "Master Kategori Aset";
  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false); // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([]);

  // STATE MENAMPUNG GRUP ASET DATA
  const [grupAsetData, setGrupAsetData] = useState([]);
  // STATE MENAMPUNG ID GRUP & KODE ITEM
  const [dataKodeGrup, setdataKodeGrup] = useState({});
  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [alertConfigSearch, setAlertConfigSearch] = useState({
    data: false,
    variant: "primary",
    text: "",
  });

  const [searchTime, setSearchTime] = useState(null)

  const onInputSearch = (text) =>{
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchKey(key)
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        });
        setShowAlert(true)
      }, 750)
    );
  };

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);
    setShowAlert(false);

    Axios.all([
      KategoriAsetApi.getPage(page, dataLength, searchKey),
      KategoriAsetApi.getGrupAset(),
      KategoriAsetApi.getKodeGrup(),
    ])
      .then(
        Axios.spread((res, grupAset, dataKodeGrup) => {
          setData(res.data.data);
          setGrupAsetData(grupAset.data.data);
          setdataKodeGrup({ id_grup_aset: "1", kode: dataKodeGrup.data.data });
          setTotalPage(res.data.total_page);
          setDataCount(res.data.data_count);
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        // if (searchKey != "") {
        //   setAlertConfigSearch({
        //     data: true,
        //     variant: "primary",
        //     text: `Hasil Pencarian : ${searchKey}`,
        //   });
        // } else {
        //   setAlertConfigSearch({
        //     data: false,
        //     variant: "",
        //     text: "",
        //   });
        // }

        setIsLoading(false);
      });
  };

  // FUNCTION SWITCH HIDDEN DATA
  // TODO: CHECK SWITCH SHOW HIDDEN BUG API
  const changeDataStatus = (status, id) => {
    setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_kategori_aset: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? KategoriAsetApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : KategoriAsetApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    const [dataCek, setDataCek] = useState({
      id_grup_aset: "",
    });

    // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
    const formValidationSchema = Yup.object().shape({
      id_grup_aset: Yup.string().required("Pilih Grup Aset"),
      kode_kategori_aset: Yup.string()
        .required("Masukkan Kode Kategori Aset")
        .test("checkKode", "Kode Kategori Aset telah didaftarkan", (value) =>
          updateData.kode_kategori_aset === value
            ? true
            : KategoriAsetApi.single({
                id_grup_aset: dataCek.id_grup_aset,
                kode_kategori_aset: value,
              })
                .then(() => false)
                .catch(() => true)
        ),
      nama_kategori_aset: Yup.string().required("Masukkan nama kategori aset"),
    });

    // FORMIK INITIAL VALUES
    const formInitialValues = {
      id_grup_aset: "",
      kode_kategori_aset: "",
      nama_kategori_aset: "",
      keterangan: "",
    };

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      KategoriAsetApi.create(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
            // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsCreateForm(false);
          // FETCH DATA DARI SERVER
          getData();
          // TAMPILKAN ALERT
          setShowAlert(true);
        });
    };

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit, setValues }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Select
                  label="Grup Aset"
                  name="id_grup_aset"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      id_grup_aset: e.target.value,
                    });

                    setDataCek({
                      ...dataCek,
                      id_grup_aset: e.target.value,
                    });
                  }}
                  defaultValue={values.id_grup_aset} // ISI DEFAULT VALUE DENGAN id_grup_aset yang memiliki value bahan
                  error={errors.id_grup_aset && touched.id_grup_aset && true}
                  errorText={errors.id_grup_aset}
                >
                  {/* OPTIONS */}
                  <option value="" hidden>
                    Pilih Grup Aset
                  </option>
                  {grupAsetData
                    ? grupAsetData.map((e) => (
                        <option key={e.id_grup_aset} value={e.id_grup_aset}>
                          {e.id_grup_aset} - {e.nama_grup_aset}
                        </option>
                      ))
                    : "Data Not Found"}
                </Select>

                <Input
                  label="Kode Kategori Aset"
                  type="text"
                  name="kode_kategori_aset"
                  value={values.kode_kategori_aset}
                  onChange={handleChange}
                  error={errors.kode_kategori_aset && touched.kode_kategori_aset && true}
                  errorText={errors.kode_kategori_aset}
                />

                <Input
                  label="Nama Kategori Aset"
                  type="text"
                  name="nama_kategori_aset"
                  value={values.nama_kategori_aset}
                  onChange={handleChange}
                  error={errors.nama_kategori_aset && touched.nama_kategori_aset && true}
                  errorText={errors.nama_kategori_aset}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                />
              </ModalBody>

              <ModalFooter>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Tambah"
                  loading={isSubmitting}
                />
              </ModalFooter>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const [dataCek, setDataCek] = useState({
      id_grup_aset: "",
    });

    useEffect(() => {
      setDataCek({
        ...dataCek,
        id_grup_aset: updateData.id_grup_aset,
      });
    }, [updateData]);

    // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
    const formValidationSchema = Yup.object().shape({
      id_grup_aset: Yup.string().required("Pilih Grup Aset"),
      kode_kategori_aset: Yup.string()
        .required("Masukkan Kode Kategori Aset")
        .test("checkKode", "Kode Kategori Aset telah didaftarkan", (value) =>
          updateData.kode_kategori_aset === value
            ? true
            : KategoriAsetApi.single({
                id_grup_aset: dataCek.id_grup_aset,
                kode_kategori_aset: value,
              })
                .then(() => false)
                .catch(() => true)
        ),
      nama_kategori_aset: Yup.string().required("Masukkan nama kategori aset"),
    });

    const formInitialValues = {
      id_kategori_aset: updateData.id_kategori_aset,
      id_grup_aset: updateData.id_grup_aset,
      kode_kategori_aset: updateData.kode_kategori_aset,
      nama_kategori_aset: updateData.nama_kategori_aset,
      keterangan: updateData.keterangan,
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      // SEND UPDATED DATA TO SERVER
      KategoriAsetApi.update(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
            // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          });
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false);
          // FETCH DATA DARI SERVER
          getData();
          // SHOW ALERT
          setShowAlert(true);
        });
    };

    return (
      <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit, setValues }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Select
                  label="Grup Aset"
                  name="id_grup_aset"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      id_grup_aset: e.target.value,
                    });

                    setDataCek({
                      ...values,
                      id_grup_aset: e.target.value,
                    });
                  }}
                  defaultValue={values.id_grup_aset} // ISI DEFAULT VALUE DENGAN id_grup_aset yang memiliki value bahan
                  error={errors.id_grup_aset && touched.id_grup_aset && true}
                  errorText={errors.id_grup_aset}
                >
                  {/* OPTIONS */}
                  <option value="" hidden>
                    Pilih Grup Aset
                  </option>
                  {grupAsetData
                    ? grupAsetData?.map((e) => (
                        <option key={e.id_grup_aset} value={e.id_grup_aset}>
                          {e.id_grup_aset} - {e.nama_grup_aset}
                        </option>
                      ))
                    : "Data not found"}
                </Select>

                <Input
                  label="Kode Kategori Aset"
                  type="text"
                  name="kode_kategori_aset"
                  placeholder="Kode Kategori Aset"
                  value={values.kode_kategori_aset}
                  onChange={handleChange}
                  error={errors.kode_kategori_aset && touched.kode_kategori_aset && true}
                  errorText={errors.kode_kategori_aset}
                />

                <Input
                  label="Nama Kategori Aset"
                  type="text"
                  name="nama_kategori_aset"
                  value={values.nama_kategori_aset}
                  onChange={handleChange}
                  error={errors.nama_kategori_aset && touched.nama_kategori_aset && true}
                  errorText={errors.nama_kategori_aset}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  placeholder="Keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                />
              </ModalBody>

              <ModalFooter>
                <ActionButton type="submit" variant="success" text="Ubah" loading={isSubmitting} />
              </ModalFooter>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_kategori_aset: deleteData.id_kategori_aset };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      KategoriAsetApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // FETCH DATA DARI SERVER
          getData();
          // TAMPIL ALERT
          setShowAlert(true);
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Kategori Aset : {deleteData.kode_kategori_aset}</div>
        <div>Nama Kategori Aset : {deleteData.nama_kategori_aset}</div>
      </DeleteModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Kode Kategori Aset</ThFixed>
              <Th>Kategori Aset</Th>
              <Th>Grup Aset</Th>
              <Th>Keterangan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_kategori_aset}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <ButtonGroup size="sm" className="mr-1">
                        <UpdateButton
                          onClick={() => {
                            setUpdateData(val);
                            setIsUpdateForm(true);
                          }}
                        />
                        <DeleteButton
                          onClick={() => {
                            setDeleteData(val);
                            setIsDeleteData(true);
                          }}
                        />
                        <Switch
                          id={val.id_kategori_aset}
                          checked={val.is_hidden ? false : true}
                          onChange={() => changeDataStatus(val.is_hidden, val.id_kategori_aset)}
                        />
                      </ButtonGroup>
                    </div>
                  </TdFixed>
                  <TdFixed>{val.kode_kategori_aset}</TdFixed>
                  <Td>{val.nama_kategori_aset}</Td>
                  <Td>{val.nama_grup_aset}</Td>
                  <Td>{val.keterangan}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataNumber={page * dataLength - dataLength + 1}
            dataPage={page * dataLength}
            dataCount={dataCount}
            onDataLengthChange={(e) => {
              setPage(1);
              setDataLength(e.target.value);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch onChange={onInputSearch}/>
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Export */}
          {/* <ExportButton /> */}
          {/* Button Tambah */}
          <CreateButton
            onClick={() => {
              setUpdateData({
                id_grup_aset: "",
                kode_kategori_aset: "",
                nama_kategori_aset: "",
                keterangan: "",
              });

              setIsCreateForm(true);
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Alert
        show={alertConfigSearch.data}
        showCloseButton={true}
        variant={alertConfigSearch.variant}
        text={alertConfigSearch.text}
        onClose={() =>
          setAlertConfigSearch({
            data: false,
            variant: "primary",
            text: "",
          })
        }
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default KategoriAset;
