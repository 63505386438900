export const formInitialValues = (data) => {
  return Object({
    id_maintenance_request_master:
      data?.id_maintenance_request_master ?? undefined,
    tgl_form_checklist: data?.tgl_form_checklist ?? null,
    tgl_maintenance_request_master:
      data?.tgl_maintenance_request_master ??
      data?.maintenance?.tgl_maintenance_request_master ??
      null,
    no_form_checklist: data?.no_form_checklist ?? '',
    no_maintenance_request_master:
      data?.no_maintenance_request_master ??
      data?.maintenance?.no_maintenance_request_master ??
      '',
    catatan: data?.catatan ?? '',
    catatan_maintenance_request: data?.catatan ?? '',
    detail_item_pemeliharaan_aset:
      data?.detail?.map((val) => ({ ...val, status_checklist: false })) ??
      data?.maintenance?.detail_maintenance ??
      [],
    detail_checklist: data?.detail_checklist ?? [],
    status_checklist: data?.status_checklist ?? '',
  });
};
