import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { FormPermintaanAsetContent } from './__PermintaanAsetComps__';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MaintenanceRequestApi from './__PermintaanAsetApi__';
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from './__PermintaanAsetUtils__';
import { Alert } from 'components';

export const PermintaanAsetCreate = ({ setNavbarTitle }) => {
  const history = useHistory();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: '',
    variant: 'primary',
  });

  const createMaintenanceRequest = useMutation((data) =>
    MaintenanceRequestApi.create(data)
  );
  const formSubmitHandler = (value) => {
    createMaintenanceRequest
      .mutateAsync(formSubmitValueMapper(value))
      .then(() =>
        history.push('/transaksi/permintaan-aset', {
          alert: {
            variant: 'primary',
            show: true,
            text: 'Data berhasil disimpan!!!',
          },
        })
      )
      .catch((err) =>
        setAlertConfig({
          variant: 'danger',
          show: true,
          text: 'Data gagal disimpan!!!',
        })
      );
  };

  useEffect(() => {
    setNavbarTitle('Permintaan Aset');
    return () => {};
  }, []);

  return (
    <>
      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
        showCloseButton
      />
      <Formik
        initialValues={formInitialValues()}
        onSubmit={formSubmitHandler}
        validationSchema={formValidationSchema}
      >
        <FormPermintaanAsetContent action="create" />
      </Formik>
    </>
  );
};
