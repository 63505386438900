import Services from '../../../services';

class RekapitulasiPenyusutanAset {
  getReport(params) {
    return Services.get('/rekapitulasi_nilai_penyusutan_aset', { params });
  }

  getDropdown(params) {
    // params : grup | kategori | jenis | item_aset
    return Services.get('/laporan_daftar_aset/dropdown', { params });
  }

  getDropdownnProvinsi() {
    return Services.get('/dropdown/provinsi');
  }

  getDropdownnKabupaten(params) {
    return Services.get('/dropdown/kabupaten', { params });
  }

  getDropdownnKecamatan(params) {
    return Services.get('/dropdown/kecamatan', { params });
  }

  getDropdownnDesa(params) {
    return Services.get('/dropdown/desa', { params });
  }

  export(params) {
    return Services.get('/rekapitulasi_nilai_penyusutan_aset/export', {
      params,
    });
  }
}

export default new RekapitulasiPenyusutanAset();
