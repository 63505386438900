// React
import React from 'react';

// Form
import { Formik, useFormikContext } from 'formik';

// Components
import { DatePicker, FilterModal } from 'components';
import { Select } from 'components2';

// API
import { useQuery } from 'react-query';
import { DateConvert } from 'utilities';
import PenerimaanAsetApi from '../__PenerimaanAsetApi__';

export const ModalFilterPreFormPage = ({ toggle }) => {
  const { values, handleSubmit, setValues } = useFormikContext();

  const { data: dropdownKeperluan, isFetching: loadingDropdownKeperluan } =
    useQuery(['keperluan', 'dropdown', 'filter'], () =>
      PenerimaanAsetApi.getDropdownKeperluan()
    );

  const { data: dropdownVendor, isFetching: loadingDropdownVendor } = useQuery(
    ['vendor', 'dropdown'],
    () => PenerimaanAsetApi.getDropdownVendor()
  );

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    const myPromise = new Promise((resolve) => {
      setValues({
        tgl_purchase_order_aset_mulai: undefined,
        tgl_purchase_order_aset_selesai: undefined,
        tgl_seleksi_vendor_aset_mulai: undefined,
        tgl_seleksi_vendor_aset_selesai: undefined,
        id_vendor: undefined,
        keperluan: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => {});
  };

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  return (
    <FilterModal
      isToggle
      toggleModal={toggle}
      onResetButtonClick={() => onResetButtonClick(setValues)}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        label="Tgl. Purchase Order Aset"
        placeholderText="Pilih tgl. purchaser order aset"
        startDate={
          values?.tgl_purchase_order_aset_mulai
            ? new Date(values?.tgl_purchase_order_aset_mulai)
            : ''
        }
        endDate={
          values?.tgl_purchase_order_aset_selesai
            ? new Date(values?.tgl_purchase_order_aset_selesai)
            : ''
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            'tgl_purchase_order_aset_mulai',
            'tgl_purchase_order_aset_selesai'
          )
        }
        monthsShown={2}
      />

      <DatePicker
        selectsRange
        label="Tgl. Seleksi Vendor Aset"
        placeholderText="Pilih tgl. seleksi vendor aset"
        startDate={
          values?.tgl_seleksi_vendor_aset_mulai
            ? new Date(values?.tgl_seleksi_vendor_aset_mulai)
            : ''
        }
        endDate={
          values?.tgl_seleksi_vendor_aset_selesai
            ? new Date(values?.tgl_seleksi_vendor_aset_selesai)
            : ''
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            'tgl_seleksi_vendor_aset_mulai',
            'tgl_seleksi_vendor_aset_selesai'
          )
        }
        monthsShown={2}
      />

      <Select
        label="Vendor"
        placeholder="Pilih vendor"
        name="id_vendor"
        loading={loadingDropdownVendor}
        options={dropdownVendor ?? []}
        onChange={async (val) =>
          setValues({
            ...values,
            id_vendor: val.value,
          })
        }
        defaultValue={dropdownVendor?.find(
          (val) => val.value === values.id_vendor
        )}
      />

      <Select
        label="Keperluan"
        placeholder="Pilih keperluan"
        name="keperluan"
        loading={loadingDropdownKeperluan}
        options={dropdownKeperluan ?? []}
        onChange={async (val) =>
          setValues({
            ...values,
            keperluan: val.value,
          })
        }
        defaultValue={dropdownKeperluan?.find(
          (val) => val.value === values.keperluan
        )}
      />
    </FilterModal>
  );
};
