import React, { useState, useEffect } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { debounce } from 'lodash';
import {
  CRUDLayout,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  Pagination,
  BackButton,
} from 'components';
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonUpdate,
  ButtonFilter,
  ButtonDetail,
  Button,
} from 'components2';
import { tableNumber } from 'utilities2';
import { ModalFilter } from './__PermintaanWorkOrderComps__';
import PermintaanWorkOrderApi from './__PermintaanWorkOrderApi__';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Formik } from 'formik';
import { DateConvert } from 'utilities';

export const ListWorkOrderPermintaanWorkOrder = ({ setNavbarTitle }) => {
  const history = useHistory();

  const [filter, setFilter] = useState({
    q: '',
    page: 1,
    per_page: 10,
    tgl_work_order_mulai: undefined,
    tgl_work_order_selesai: undefined,
    tgl_maintenance_mulai: undefined,
    tgl_maintenance_selesai: undefined,
    id_item_aset: undefined,
    selesai_permintaan: undefined,
    active: false,
  });
  const [collapsedIndex, setCollapsedIndex] = useState(-1);
  const [modalFilter, setModalFilter] = useState(false);

  const getItemAsetList = useQuery(
    ['item_aset_analisa_checklist', 'list', filter],
    () => PermintaanWorkOrderApi.getListWorkOrder(filter)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setFilter({
      ...filter,
      q: searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  const onPaginationChange = ({ selected }) =>
    setFilter({ ...filter, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setFilter({ ...filter, page: 1, per_page: e.target.value });

  const formInitialValuesFilter = {
    tgl_work_order_mulai: filter?.tgl_work_order_mulai,
    tgl_work_order_selesai: filter?.tgl_work_order_selesai,
    tgl_maintenance_mulai: filter?.tgl_maintenance_mulai,
    tgl_maintenance_selesai: filter?.tgl_maintenance_selesai,
    id_item_aset: filter?.id_item_aset,
    selesai_permintaan: filter?.selesai_permintaan,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  useEffect(() => setNavbarTitle('Permintaan Work Order'), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            size="sm"
            icon
            onClick={() => history.push('/transaksi/permintaan-work-order')}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getItemAsetList.isLoading || getItemAsetList.isError ? (
        <DataStatus
          loading={getItemAsetList.isLoading}
          text={
            getItemAsetList.isLoading ? 'Memuat . . .' : 'Data gagal dimuat'
          }
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Work Order Yang Siap Dibuatkan Permintaan
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Informasi Work Order</ThFixed>
                <ThFixed>Informasi Maintenance Request</ThFixed>
                <Th className="p-1">Item Aset</Th>
                <Th className="p-1">Rincian Pekerjaan</Th>
                <ThFixed>Status Permintaan</ThFixed>
                <ThFixed>Aksi</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {getItemAsetList?.data?.data?.length > 0 ? (
                getItemAsetList?.data?.data?.map((e, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: filter.page,
                        dataLength: filter.per_page,
                        index,
                      })}
                    </TdFixed>
                    <Td>
                      <div>
                        {e.tgl_work_order
                          ? DateConvert(new Date(e.tgl_work_order)).custom
                          : '-'}
                      </div>
                      <div>{e.no_work_order ?? '-'}</div>
                    </Td>
                    <Td>
                      <div>
                        {e.tgl_maintenance_request_master
                          ? DateConvert(
                              new Date(e.tgl_maintenance_request_master)
                            ).custom
                          : '-'}
                      </div>
                      <div>{e.no_maintenance_request_master ?? '-'}</div>
                    </Td>
                    <Td>{e.nama_item_aset ?? '-'}</Td>
                    <Td>
                      {e.rincian_pekerjaan?.length === 0 ? (
                        '-'
                      ) : (
                        <>
                          <ul className="pl-3 m-0">
                            {e?.rincian_pekerjaan
                              ?.filter((_val, indexPermintaan) => {
                                if (index === collapsedIndex) {
                                  return (
                                    indexPermintaan <=
                                    e?.rincian_pekerjaan?.length
                                  );
                                } else {
                                  return indexPermintaan <= 2;
                                }
                              })
                              .map((e, index) => (
                                <li key={index}>{e.nama_pekerjaan ?? '-'}</li>
                              ))}
                          </ul>
                          {e?.rincian_pekerjaan?.length > 3 &&
                            index !== collapsedIndex && (
                              <Button
                                variant="link"
                                className="pt-0"
                                onClick={() => setCollapsedIndex(index)}
                              >
                                Selengkapnya...
                              </Button>
                            )}
                        </>
                      )}
                    </Td>
                    <Td className="text-nowrap">
                      <Button
                        variant={
                          e.selesai_permintaan
                            ? 'outline-success'
                            : 'outline-danger'
                        }
                        text={
                          e.selesai_permintaan
                            ? 'SUDAH DIMINTA'
                            : 'BELUM DIMINTA'
                        }
                        disabled
                      />
                    </Td>
                    <TdFixed>
                      <ButtonGroup>
                        <ButtonCreate
                          icon
                          noText
                          onClick={() =>
                            history.push('work-order/tambah/' + e.id_work_order)
                          }
                        />
                      </ButtonGroup>
                    </TdFixed>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={filter?.per_page}
            dataNumber={filter?.page * filter?.per_page - filter?.per_page + 1}
            dataPage={
              filter?.dataCount < filter?.per_page
                ? filter?.dataCount
                : filter?.page * filter?.per_page
            }
            dataCount={getItemAsetList?.data?.data_count}
            currentPage={filter?.page}
            totalPage={getItemAsetList?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter
            data={filter}
            setData={setFilter}
            toggle={onFilterButtonClickkHandler}
          />
        </Formik>
      )}
    </CRUDLayout>
  );
};
