import { useState } from 'react';
import {
  Input,
  Table,
  TBody,
  Th,
  THead,
  ThFixed,
  Tr,
  Td,
  TdFixed,
  DataStatus,
  ActionButton,
  UpdateButton,
  DeleteButton,
} from 'components';
import { useFormik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { ButtonGroup } from 'react-bootstrap';
import {
  IoAddOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
} from 'react-icons/io5';

export const TableRincianPekerjaan = ({ createable = false }) => {
  const { values, setValues } = useFormikContext();

  const [editIndex, setEditIndex] = useState(null);

  const onEditItem = (index) => {
    setEditIndex(index);
  };

  const onCloseEditing = () => {
    setEditIndex(null);
  };

  const onDeleteItem = (index) => {
    setValues((prev) => ({
      ...prev,
      rincian_pekerjaan: prev.rincian_pekerjaan.toSpliced(index, 1),
    }));
  };

  const data = values.rincian_pekerjaan ?? [];

  return (
    <>
      <small className="font-weight-bold my-1">Rincian Pekerjaan</small>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>Rincian Pekerjaan</Th>
            <Th>Keterangan</Th>
            {createable && <ThFixed>Aksi</ThFixed>}
          </Tr>
        </THead>
        <TBody>
          {createable && <InputRincianPekerjaan />}
          {data?.length ? (
            data.map((val, index) => {
              if (editIndex === index) {
                return (
                  <InputRincianPekerjaan
                    isEditing={true}
                    index={index}
                    data={val}
                    onCloseEditing={onCloseEditing}
                  />
                );
              }

              return (
                <Tr>
                  <TdFixed textCenter>{index + 1}</TdFixed>
                  <Td>{val.nama_pekerjaan ?? '-'}</Td>
                  <Td>{val.keterangan ?? '-'}</Td>
                  {createable && (
                    <td className="p-0 m-0">
                      <ButtonGroup>
                        <UpdateButton
                          size="sm"
                          onClick={() => onEditItem(index)}
                        />
                        <DeleteButton
                          size="sm"
                          onClick={() => onDeleteItem(index)}
                        />
                      </ButtonGroup>
                    </td>
                  )}
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={createable ? 4 : 3}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};

export const InputRincianPekerjaan = ({
  isEditing = false,
  index,
  data = null,
  onCloseEditing,
}) => {
  const formikContext = useFormikContext();

  const validationSchema = yup.object().shape({
    nama_pekerjaan: yup.string().required(),
    keterangan: yup.string().required(),
  });

  const { values, setFieldValue, errors, touched, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      nama_pekerjaan: data?.nama_pekerjaan ?? '',
      keterangan: data?.keterangan ?? '',
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (isEditing) {
        formikContext.setValues((prev) => ({
          ...prev,
          rincian_pekerjaan: prev.rincian_pekerjaan.toSpliced(index, 1, values),
        }));

        onCloseEditing();
        return;
      }

      formikContext.setValues((prev) => ({
        ...prev,
        rincian_pekerjaan: prev.rincian_pekerjaan.toSpliced(0, 0, values),
      }));
      resetForm();
    },
  });

  return (
    <tr className="p-0 m-0">
      <td colSpan={2} className="p-0 m-0">
        <Input
          noMargin
          placeholder="Nama Pekerjaan"
          value={values?.nama_pekerjaan}
          onChange={(e) => setFieldValue('nama_pekerjaan', e.target.value)}
          error={errors.nama_pekerjaan && touched.nama_pekerjaan}
        />
      </td>
      <td className="p-0 m-0">
        <Input
          noMargin
          placeholder="Keterangan Pekerjaan"
          value={values?.keterangan}
          onChange={(e) => setFieldValue('keterangan', e.target.value)}
          error={errors.keterangan && touched.keterangan}
        />
      </td>
      <td className="p-0 m-0">
        <ButtonGroup className="btn-block">
          {isEditing ? (
            <>
              <ActionButton
                size="sm"
                className="bg-success"
                text={<IoCheckmarkOutline />}
                onClick={handleSubmit}
              />
              <ActionButton
                size="sm"
                className="bg-danger"
                text={<IoCloseOutline />}
                onClick={onCloseEditing}
              />
            </>
          ) : (
            <ActionButton
              size="sm"
              className="btn-block"
              text={<IoAddOutline />}
              onClick={handleSubmit}
            />
          )}
        </ButtonGroup>
      </td>
    </tr>
  );
};
