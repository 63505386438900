// React
import React, { useState, useEffect } from "react";

// Form
import { Formik, Field } from "formik";
import * as Yup from "yup";

// API
import Axios from "axios";
import { AsetMesinDanAlatApi } from "../../../../../../api";

// Component
import _ from "lodash";
import {
  ActionButton,
  DataStatus,
  Alert,
  Input,
  TextArea,
  SelectSearch,
  AlertModal,
} from "../../../../../../components";
import { Col, Row } from "react-bootstrap";

const ColumnData = ({ title, className, children }) => (
  <div className={className ? className : "mb-4"}>
    <h6
      className="ml-1 text-dark"
      style={{
        fontSize: "17px",
        marginBottom: "12.5px",
      }}
    >
      <b>{title}</b>
    </h6>
    <div className="bg-white p-2 px-3 shadow-sm rounded">{children}</div>
  </div>
);

const InformasiUmum = ({ tipe, data, setData, setPage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataInduk, setDataInduk] = useState({
    entitas: [],
    kategori: [],
    kondisi: [],
    karyawan: [],
    unit_organisasi: [],
    provinsi: [],
    pabrikan: [],
    satuan: [],
  });
  const monthOptions = [
    { label: "Januari", value: "01" },
    { label: "Februari", value: "02" },
    { label: "Maret", value: "03" },
    { label: "April", value: "04" },
    { label: "Mei", value: "05" },
    { label: "Juni", value: "06" },
    { label: "Juli", value: "07" },
    { label: "Agustus", value: "08" },
    { label: "September", value: "09" },
    { label: "Oktober", value: "10" },
    { label: "November", value: "11" },
    { label: "Desember", value: "12" },
  ];

  const [dataKabupaten, setDataKabupaten] = useState([]);
  const [dataKecamatan, setDataKecamatan] = useState([]);
  const [dataDesa, setDataDesa] = useState([]);
  const [dataJenis, setDataJenis] = useState([]);
  const [loading, setLoading] = useState({
    jenis: false,
    kabupaten: false,
    kecamatan: false,
    desa: false,
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  const getDataInduk = () => {
    setIsLoading(true);

    Axios.all([
      AsetMesinDanAlatApi.dropdown({ tipe: "entitas_aset" }),
      AsetMesinDanAlatApi.dropdown({ tipe: "kategori_aset" }),
      AsetMesinDanAlatApi.dropdown({ tipe: "kondisi_aset" }),
      AsetMesinDanAlatApi.dropdown({ tipe: "karyawan" }),
      AsetMesinDanAlatApi.dropdown({ tipe: "unit_organisasi" }),
      AsetMesinDanAlatApi.dropdown({ tipe: "provinsi" }),
      AsetMesinDanAlatApi.dropdown({ tipe: "pabrikan" }),
      AsetMesinDanAlatApi.dropdown({ tipe: "satuan" }),
    ])
      .then(
        Axios.spread(
          (
            entitas,
            kategori,
            kondisi,
            karyawan,
            unit_organisasi,
            provinsi,
            pabrikan,
            satuan
          ) => {
            setDataInduk({
              entitas: entitas.data.data,
              kategori: kategori.data.data,
              kondisi: kondisi.data.data,
              karyawan: karyawan.data.data,
              unit_organisasi: unit_organisasi.data.data,
              provinsi: provinsi.data.data,
              pabrikan: pabrikan.data.data,
              satuan: satuan.data.data,
            });
          }
        )
      )
      .finally(() => setIsLoading(false));
  };

  const getDataJenis = (value) => {
    setLoading({
      ...loading,
      jenis: true,
    });

    setDataJenis([]);

    AsetMesinDanAlatApi.dropdown({
      tipe: "jenis_aset",
      id_kategori_aset: value,
    })
      .then((res) => {
        setDataJenis(res.data.data);
      })
      .finally(() =>
        setLoading({
          ...loading,
          jenis: false,
        })
      );
  };

  const getDataKode = (value, setFieldValue) => {
    AsetMesinDanAlatApi.no_baru(value).then((res) =>
      setFieldValue("kode_item_aset", res.data.data)
    );
  };

  const getDataKabupaten = (value) => {
    setLoading({
      ...loading,
      kabupaten: true,
      kecamatan: true,
      desa: true,
    });

    setDataKecamatan([]);
    setDataDesa([]);

    AsetMesinDanAlatApi.dropdown({
      tipe: "kabupaten",
      id_provinsi: value,
    })
      .then((res) => setDataKabupaten(res.data.data))
      .finally(() =>
        setLoading({
          ...loading,
          kabupaten: false,
          kecamatan: false,
          desa: false,
        })
      );
  };

  const getDataKecamatan = (value) => {
    setLoading({
      ...loading,
      kecamatan: true,
      desa: true,
    });

    setDataDesa([]);

    AsetMesinDanAlatApi.dropdown({
      tipe: "kecamatan",
      id_kabupaten: value,
    })
      .then((res) => setDataKecamatan(res.data.data))
      .finally(() =>
        setLoading({
          ...loading,
          kecamatan: false,
          desa: false,
        })
      );
  };

  const getDataDesa = (value) => {
    setLoading({
      ...loading,
      desa: true,
    });

    AsetMesinDanAlatApi.dropdown({
      tipe: "desa",
      id_kecamatan: value,
    })
      .then((res) => setDataDesa(res.data.data))
      .finally(() =>
        setLoading({
          ...loading,
          desa: false,
        })
      );
  };

  useEffect(() => {
    getDataInduk();
    data.id_kategori_aset != "" &&
      data.id_kategori_aset != null &&
      getDataJenis(data.id_kategori_aset);
    data.id_provinsi != "" &&
      data.id_provinsi != null &&
      getDataKabupaten(data.id_provinsi);

    setTimeout(() => {
      data.id_kabupaten != "" &&
        data.id_kabupaten != null &&
        getDataKecamatan(data.id_kabupaten);
    }, 500);

    setTimeout(() => {
      data.id_kecamatan != "" &&
        data.id_kecamatan != null &&
        getDataDesa(data.id_kecamatan);
    }, 1000);
  }, []);

  const [saveAlert, setSaveAlert] = useState(false);
  const [saveAlertConfig, setsaveAlertConfig] = useState({
    textColor: "",
    title: "",
    message: "",
    status: "",
  });

  const formInitialValues = {
    id_item_aset: data.id_item_aset,
    id_entitas_aset: data.id_entitas_aset == null ? "" : data.id_entitas_aset,
    nama_entitas_aset:
      data.nama_entitas_aset == "" || data.nama_entitas_aset == null
        ? "Pilih Entitas Aset"
        : data.nama_entitas_aset,
    id_grup_aset: data.id_grup_aset,
    nama_grup_aset: "4 - Aset Alat dan Mesin",
    id_kategori_aset:
      data.id_kategori_aset == null ? "" : data.id_kategori_aset,
    nama_kategori_aset:
      data.nama_kategori_aset == "" || data.nama_kategori_aset == null
        ? "Pilih Kategori Aset"
        : data.nama_kategori_aset,
    id_jenis_aset: data.id_jenis_aset == null ? "" : data.id_jenis_aset,
    nama_jenis_aset:
      data.nama_jenis_aset == "" || data.nama_jenis_aset == null
        ? "Pilih Jenis Aset"
        : data.nama_jenis_aset,
    tahun_perolehan: data.tahun_perolehan == null ? "" : data.tahun_perolehan,
    kode_item_aset: data.kode_item_aset == null ? "" : data.kode_item_aset,
    nama_item_aset: data.nama_item_aset == null ? "" : data.nama_item_aset,
    id_kondisi_aset: data.id_kondisi_aset == null ? "" : data.id_kondisi_aset,
    nama_kondisi_aset:
      data.nama_kondisi_aset == "" || data.nama_kondisi_aset == null
        ? "Pilih Kondisi Aset"
        : data.nama_kondisi_aset,
    id_karyawan: data.id_karyawan == null ? "" : data.id_karyawan,
    nama_karyawan:
      data.nama_karyawan == "" || data.nama_karyawan == null
        ? "Pilih Penanggung Jawab"
        : data.nama_karyawan,
    id_unit_organisasi:
      data.id_unit_organisasi == null ? "" : data.id_unit_organisasi,
    nama_unit_organisasi:
      data.nama_unit_organisasi == "" || data.nama_unit_organisasi == null
        ? "Pilih Unit Organisasi"
        : data.nama_unit_organisasi,
    keterangan: data.keterangan,
    id_provinsi: data.id_provinsi == null ? "" : data.id_provinsi,
    nama_provinsi:
      data.nama_provinsi == "" || data.nama_provinsi == null
        ? "Pilih Provinsi"
        : data.nama_provinsi,
    id_kabupaten: data.id_kabupaten == null ? "" : data.id_kabupaten,
    nama_kabupaten:
      data.nama_kabupaten == "" || data.nama_kabupaten == null
        ? "Pilih Kabupaten"
        : data.nama_kabupaten,
    id_kecamatan: data.id_kecamatan == null ? "" : data.id_kecamatan,
    nama_kecamatan:
      data.nama_kecamatan == "" || data.nama_kecamatan == null
        ? "Pilih Kecamatan"
        : data.nama_kecamatan,
    id_desa: data.id_desa == null ? "" : data.id_desa,
    nama_desa:
      data.nama_desa == "" || data.nama_desa == null
        ? "Pilih Desa"
        : data.nama_desa,
    detail_lokasi: data.detail_lokasi,
    no_seri: data.no_seri,
    id_pabrikan: data.id_pabrikan == null ? "" : data.id_pabrikan,
    nama_pabrikan:
      data.nama_pabrikan == "" || data.nama_pabrikan == null
        ? "Pilih Pabrikan Aset"
        : data.nama_pabrikan,
    id_satuan_pakai: "84",
    nama_satuan: "Depreciation Value",
    // data.nama_satuan == "" || data.nama_satuan == null ? "Pilih Satuan Aset" : data.nama_satuan,
    qr_code: data.qr_code,
    barcode: data.barcode,
    produksi: data.produksi,
    disewa: data.disewa,
    bulan_perolehan: data?.bulan_perolehan ?? "",
  };

  // skema validasi form
  const formValidationSchema = Yup.object().shape({
    id_entitas_aset: Yup.string().required("Pilih Entitas Aset"),
    id_kategori_aset: Yup.string().required("Pilih Kategori Aset"),
    id_jenis_aset: Yup.string().required("Pilih Jenis Aset"),
    tahun_perolehan: Yup.number()
      .min("1900", "Tahun Perolehan tidak dapat kurang dari tahun 1900")
      .max(
        new Date().getFullYear(),
        `Tahun Perolehan tidak dapat lebih dari tahun ${new Date().getFullYear()}`
      ),
    kode_item_aset: Yup.string().required("Kode Aset wajib diisi"),
    nama_item_aset: Yup.string()
      .required("Nama Aset wajib diisi")
      .test("checkNama", "Nama Aset telah didaftarkan", (value) =>
        value == data.nama_item_aset
          ? true
          : AsetMesinDanAlatApi.singleCheck({ nama_item_aset: value })
              .then(() => false)
              .catch(() => true)
      ),
    id_kondisi_aset: Yup.string().required("Pilih Kondisi Aset"),
    id_karyawan: Yup.string().required("Pilih Panggung Jawab"),
    id_unit_organisasi: Yup.string().required("Unit Organisasi wajib diisi"),
    id_provinsi: Yup.string().required("Pilih Provinsi"),
    id_kabupaten: Yup.string().required("Pilih Kabupaten"),
    id_kecamatan: Yup.string().required("Pilih Kecamatan"),
    id_desa: Yup.string().required("Pilih Desa"),
    detail_lokasi: Yup.string().required("Detail Lokasi wajib diisi"),
    id_pabrikan: Yup.string().required("Pilih Pabrikan Aset"),
    id_satuan_pakai: Yup.string().required("Pilih Satuan Aset"),
  });

  const formSubmitHandler = (values) => {
    let id_grup_aset =
      values.id_grup_aset == null || values.id_grup_aset == ""
        ? "4"
        : values.id_grup_aset;

    if (tipe == "tambah") {
      AsetMesinDanAlatApi.create({
        id_entitas_aset: values.id_entitas_aset,
        id_grup_aset: id_grup_aset,
        id_kategori_aset: values.id_kategori_aset,
        id_jenis_aset: values.id_jenis_aset,
        tahun_perolehan: values.tahun_perolehan,
        kode_item_aset: values.kode_item_aset,
        nama_item_aset: values.nama_item_aset,
        id_kondisi_aset: values.id_kondisi_aset,
        id_karyawan: values.id_karyawan,
        id_unit_organisasi: values.id_unit_organisasi,
        keterangan: values.keterangan,
        id_provinsi: values.id_provinsi,
        id_kabupaten: values.id_kabupaten,
        id_kecamatan: values.id_kecamatan,
        id_desa: values.id_desa,
        detail_lokasi: values.detail_lokasi,
        no_seri: values.no_seri,
        id_pabrikan: values.id_pabrikan,
        id_satuan_pakai: values.id_satuan_pakai,
        qr_code: values.qr_code,
        barcode: values.barcode,
        produksi: values.produksi,
        disewa: values.disewa,
        bulan_perolehan: values?.bulan_perolehan,
      }).then((res) => {
        setData({
          ...data,
          ...res.data.id_item_aset,
        });

        setPage(2);
      });
    } else {
      AsetMesinDanAlatApi.update({
        id_item_aset: values.id_item_aset,
        id_entitas_aset: values.id_entitas_aset,
        id_grup_aset: id_grup_aset,
        id_kategori_aset: values.id_kategori_aset,
        id_jenis_aset: values.id_jenis_aset,
        tahun_perolehan: values.tahun_perolehan,
        kode_item_aset: values.kode_item_aset,
        nama_item_aset: values.nama_item_aset,
        id_kondisi_aset: values.id_kondisi_aset,
        id_karyawan: values.id_karyawan,
        id_unit_organisasi: values.id_unit_organisasi,
        keterangan: values.keterangan,
        id_provinsi: values.id_provinsi,
        id_kabupaten: values.id_kabupaten,
        id_kecamatan: values.id_kecamatan,
        id_desa: values.id_desa,
        detail_lokasi: values.detail_lokasi,
        no_seri: values.no_seri,
        id_pabrikan: values.id_pabrikan,
        id_satuan_pakai: values.id_satuan_pakai,
        qr_code: values.qr_code,
        barcode: values.barcode,
        produksi: values.produksi,
        disewa: values.disewa,
        bulan_perolehan: values.bulan_perolehan,
      })
        .then(() => {
          AsetMesinDanAlatApi.single({
            id_item_aset: values.id_item_aset,
          }).then((ress) => {
            setData({
              ...data,
              ...ress.data.data,
            });
          });

          setsaveAlertConfig({
            textColor: "text-success",
            title: "Simpan data berhasil",
            message: "Data berhasil di ubah",
            status: true,
          });
        })
        .catch((err) => {
          setsaveAlertConfig({
            textColor: "text-danger",
            title: "Tambah data gagal",
            message: err.response.data.message,
            status: false,
          });
        })
        .finally(() => {
          setSaveAlert(true);
        });
    }
  };

  return (
    <>
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : (
        <>
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <ColumnData title="Data Dasar Aset">
                  <Row>
                    <Col lg>
                      <SelectSearch
                        label="Entitas Aset"
                        name="id_entitas_aset"
                        defaultValue={{
                          value: values.id_entitas_aset,
                          label: values.nama_entitas_aset,
                        }}
                        option={dataInduk.entitas.map((val) => {
                          return {
                            value: val.id_entitas_aset,
                            label: val.kode_entitas_aset
                              ? `${val.kode_entitas_aset} - ${val.nama_entitas_aset}`
                              : val.nama_entitas_aset,
                          };
                        })}
                        onChange={(val) => {
                          setFieldValue("id_entitas_aset", val.value);
                          setFieldValue("nama_entitas_aset", val.label);
                          setFieldValue("tahun_perolehan", "");

                          getDataKode(
                            {
                              id_entitas_aset: val.value,
                              id_kategori_aset: values.id_kategori_aset,
                              id_jenis_aset: values.id_jenis_aset,
                              tahun_perolehan: values.tahun_perolehan,
                            },
                            setFieldValue
                          );
                        }}
                        error={
                          errors.id_entitas_aset &&
                          touched.id_entitas_aset &&
                          true
                        }
                        errorText={
                          errors.id_entitas_aset &&
                          touched.id_entitas_aset &&
                          errors.id_entitas_aset
                        }
                        isDisabled={tipe == "detail" ? true : false}
                      />

                      <Input
                        label="Grup Aset"
                        type="text"
                        name="nama_grup_aset"
                        value={values.nama_grup_aset}
                        readOnly={true}
                        onChange={handleChange}
                      />

                      <SelectSearch
                        label="Kategori Aset"
                        name="id_kategori_aset"
                        defaultValue={{
                          value: values.id_kategori_aset,
                          label: values.nama_kategori_aset,
                        }}
                        option={dataInduk.kategori.map((val) => {
                          return {
                            value: val.id_kategori_aset,
                            label: val.kode_kategori_aset
                              ? `${val.kode_kategori_aset} - ${val.nama_kategori_aset}`
                              : val.nama_kategori_aset,
                          };
                        })}
                        onChange={(val) => {
                          setFieldValue("id_kategori_aset", val.value);
                          setFieldValue("nama_kategori_aset", val.label);
                          setFieldValue("id_jenis_aset", "");
                          setFieldValue("nama_jenis_aset", "Pilih Jenis Aset");
                          setFieldValue("tahun_perolehan", "");

                          getDataJenis(val.value);
                          getDataKode(
                            {
                              id_entitas_aset: values.id_entitas_aset,
                              id_kategori_aset: values.id_kategori_aset,
                              id_jenis_aset: values.id_jenis_aset,
                              tahun_perolehan: values.tahun_perolehan,
                            },
                            setFieldValue
                          );
                        }}
                        error={
                          errors.id_kategori_aset &&
                          touched.id_kategori_aset &&
                          true
                        }
                        errorText={
                          errors.id_kategori_aset &&
                          touched.id_kategori_aset &&
                          errors.id_kategori_aset
                        }
                        isDisabled={tipe == "detail" ? true : false}
                      />

                      <SelectSearch
                        label="Jenis Aset"
                        name="id_jenis_aset"
                        defaultValue={{
                          value: values.id_jenis_aset,
                          label: values.nama_jenis_aset,
                        }}
                        option={dataJenis.map((val) => {
                          return {
                            value: val.id_jenis_aset,
                            label: val.kode_jenis_aset
                              ? `${val.kode_jenis_aset} - ${val.nama_jenis_aset}`
                              : val.nama_jenis_aset,
                          };
                        })}
                        onChange={(val) => {
                          setFieldValue("id_jenis_aset", val.value);
                          setFieldValue("nama_jenis_aset", val.label);
                          setFieldValue("tahun_perolehan", "");

                          getDataKode(
                            {
                              id_entitas_aset: values.id_entitas_aset,
                              id_kategori_aset: values.id_kategori_aset,
                              id_jenis_aset: val.value,
                              tahun_perolehan: values.tahun_perolehan,
                            },
                            setFieldValue
                          );
                        }}
                        error={
                          errors.id_jenis_aset && touched.id_jenis_aset && true
                        }
                        errorText={
                          errors.id_jenis_aset &&
                          touched.id_jenis_aset &&
                          errors.id_jenis_aset
                        }
                        loading={loading.jenis}
                        isDisabled={
                          tipe != "detail"
                            ? values.id_kategori_aset == "" ||
                              values.id_kategori_aset == null
                              ? true
                              : false
                            : true
                        }
                      />

                      <Row>
                        <Col>
                          <SelectSearch
                            label="Bulan Perolehan"
                            placeholder="Pilih Bulan Perolehan"
                            isDisabled={
                              tipe != "detail"
                                ? values.id_entitas_aset != "" &&
                                  values.id_kategori_aset != "" &&
                                  values.id_jenis_aset != ""
                                  ? false
                                  : true
                                : true
                            }
                            option={monthOptions}
                            defaultValue={monthOptions.find(
                              (val) => values.bulan_perolehan === val.value
                            )}
                            onChange={(val) =>
                              setFieldValue("bulan_perolehan", val.value)
                            }
                            error={
                              errors.bulan_perolehan &&
                              touched.bulan_perolehan &&
                              true
                            }
                            errorText={
                              errors.bulan_perolehan &&
                              touched.bulan_perolehan &&
                              errors.bulan_perolehan
                            }
                          />
                        </Col>
                        <Col>
                          <Input
                            label="Tahun Perolehan Aset"
                            type="text"
                            name="tahun_perolehan"
                            value={values.tahun_perolehan}
                            onChange={(e) => {
                              setFieldValue("tahun_perolehan", e.target.value);

                              e.target.value != "" &&
                                getDataKode(
                                  {
                                    id_entitas_aset: values.id_entitas_aset,
                                    id_kategori_aset: values.id_kategori_aset,
                                    id_jenis_aset: values.id_jenis_aset,
                                    tahun_perolehan: e.target.value,
                                  },
                                  setFieldValue
                                );
                            }}
                            error={
                              errors.tahun_perolehan &&
                              touched.tahun_perolehan &&
                              true
                            }
                            errorText={errors.tahun_perolehan}
                            readOnly={
                              tipe != "detail"
                                ? values.id_entitas_aset != "" &&
                                  values.id_kategori_aset != "" &&
                                  values.id_jenis_aset != ""
                                  ? false
                                  : true
                                : true
                            }
                          />
                        </Col>
                      </Row>

                      <Input
                        label="No Seri"
                        type="text"
                        name="no_seri"
                        value={values.no_seri}
                        onChange={handleChange}
                        error={errors.no_seri && touched.no_seri && true}
                        errorText={errors.no_seri}
                        readOnly={tipe != "detail" ? false : true}
                      />

                      <SelectSearch
                        label="Satuan Pakai"
                        name="id_satuan_pakai"
                        defaultValue={{
                          value: values.id_satuan_pakai,
                          label: values.nama_satuan,
                        }}
                        option={dataInduk.satuan.map((val) => {
                          return {
                            value: val.id_satuan,
                            label: val.kode_satuan
                              ? `${val.kode_satuan} - ${val.nama_satuan}`
                              : val.nama_satuan,
                          };
                        })}
                        onChange={(val) => {
                          setFieldValue("id_satuan_pakai", val.value);
                          setFieldValue("nama_satuan", val.label);
                        }}
                        error={
                          errors.id_satuan_pakai &&
                          touched.id_satuan_pakai &&
                          true
                        }
                        errorText={
                          errors.id_satuan_pakai &&
                          touched.id_satuan_pakai &&
                          errors.id_satuan_pakai
                        }
                        isDisabled={true}
                      />
                    </Col>
                    {console.log(values.kode_item_aset)}
                    <Col lg>
                      <Input
                        key={values.kode_item_aset}
                        label="Kode Aset"
                        type="text"
                        name="kode_item_aset"
                        value={values.kode_item_aset}
                        onChange={handleChange}
                        error={
                          errors.kode_item_aset &&
                          touched.kode_item_aset &&
                          true
                        }
                        errorText={errors.kode_item_aset}
                        readOnly={tipe == "detail" ? true : false}
                      />

                      <Input
                        label="Nama Aset"
                        type="text"
                        name="nama_item_aset"
                        value={values.nama_item_aset}
                        onChange={handleChange}
                        error={
                          errors.nama_item_aset &&
                          touched.nama_item_aset &&
                          true
                        }
                        errorText={errors.nama_item_aset}
                        readOnly={tipe == "detail" ? true : false}
                      />

                      <SelectSearch
                        label="Kondisi Aset"
                        name="id_kondisi_aset"
                        defaultValue={{
                          value: values.id_kondisi_aset,
                          label: values.nama_kondisi_aset,
                        }}
                        option={dataInduk.kondisi.map((val) => {
                          return {
                            value: val.id_kondisi_aset,
                            label: val.nama_kondisi_aset,
                          };
                        })}
                        onChange={(val) => {
                          setFieldValue("id_kondisi_aset", val.value);
                          setFieldValue("nama_kondisi_aset", val.label);
                        }}
                        error={
                          errors.id_kondisi_aset &&
                          touched.id_kondisi_aset &&
                          true
                        }
                        errorText={
                          errors.id_kondisi_aset &&
                          touched.id_kondisi_aset &&
                          errors.id_kondisi_aset
                        }
                        isDisabled={tipe == "detail" ? true : false}
                      />

                      <SelectSearch
                        label="Karyawan Penanggung Jawab Aset"
                        name="id_karyawan"
                        defaultValue={{
                          value: values.id_karyawan,
                          label: values.nama_karyawan,
                        }}
                        option={dataInduk.karyawan.map((val) => {
                          return {
                            value: val.id_karyawan,
                            label: val.nama_karyawan,
                          };
                        })}
                        onChange={(val) => {
                          setFieldValue("id_karyawan", val.value);
                          setFieldValue("nama_karyawan", val.label);
                        }}
                        error={
                          errors.id_karyawan && touched.id_karyawan && true
                        }
                        errorText={
                          errors.id_karyawan &&
                          touched.id_karyawan &&
                          errors.id_karyawan
                        }
                        isDisabled={tipe == "detail" ? true : false}
                      />

                      <SelectSearch
                        label="Unit Organisasi Pengguna"
                        name="id_unit_organisasi"
                        defaultValue={{
                          value: values.id_unit_organisasi,
                          label: values.nama_unit_organisasi,
                        }}
                        option={dataInduk.unit_organisasi.map((val) => {
                          return {
                            value: val.id_unit_organisasi,
                            label: val.nama_unit_organisasi,
                          };
                        })}
                        onChange={(val) => {
                          setFieldValue("id_unit_organisasi", val.value);
                          setFieldValue("nama_unit_organisasi", val.label);
                        }}
                        error={
                          errors.id_unit_organisasi &&
                          touched.id_unit_organisasi &&
                          true
                        }
                        errorText={
                          errors.id_unit_organisasi &&
                          touched.id_unit_organisasi &&
                          errors.id_unit_organisasi
                        }
                        isDisabled={tipe == "detail" ? true : false}
                      />

                      <SelectSearch
                        label="Pabrikan"
                        name="id_pabrikan"
                        defaultValue={{
                          value: values.id_pabrikan,
                          label: values.nama_pabrikan,
                        }}
                        option={dataInduk.pabrikan.map((val) => {
                          return {
                            value: val.id_pabrikan,
                            label: val.nama_pabrikan,
                          };
                        })}
                        onChange={(val) => {
                          setFieldValue("id_pabrikan", val.value);
                          setFieldValue("nama_pabrikan", val.label);
                        }}
                        error={
                          errors.id_pabrikan && touched.id_pabrikan && true
                        }
                        errorText={
                          errors.id_pabrikan &&
                          touched.id_pabrikan &&
                          errors.id_pabrikan
                        }
                        isDisabled={tipe == "detail" ? true : false}
                      />
                      <div className="d-flex">
                        <label for="produksi">
                          <Field
                            type="checkbox"
                            name="produksi"
                            id="produksi"
                            disabled={tipe != "detail" ? false : true}
                          />
                          <span className="ml-1">Sebagai Produksi</span>
                        </label>
                        <label for="disewa" className="ml-2">
                          <Field
                            type="checkbox"
                            name="disewa"
                            id="disewa"
                            disabled={tipe != "detail" ? false : true}
                          />
                          <span className="ml-1">Dapat Disewa</span>
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <TextArea
                    label="Keterangan"
                    type="text"
                    name="keterangan"
                    value={values.keterangan}
                    onChange={handleChange}
                    rows={2}
                    readOnly={tipe == "detail" ? true : false}
                  />
                </ColumnData>

                <ColumnData title="Data Lokasi Aset">
                  <Row>
                    <Col lg="6">
                      <SelectSearch
                        label="Provinsi"
                        name="id_provinsi"
                        defaultValue={{
                          value: values.id_provinsi,
                          label: values.nama_provinsi,
                        }}
                        option={dataInduk.provinsi.map((val) => {
                          return {
                            value: val.id_provinsi,
                            label: val.nama_provinsi,
                          };
                        })}
                        onChange={(val) => {
                          setFieldValue("id_provinsi", val.value);
                          setFieldValue("nama_provinsi", val.label);
                          setFieldValue("id_kabupaten", "");
                          setFieldValue("nama_kabupaten", "Pilih Kabupaten");
                          setFieldValue("id_kecamatan", "");
                          setFieldValue("nama_kecamatan", "Pilih Kecamatan");
                          setFieldValue("id_desa", "");
                          setFieldValue("nama_desa", "Pilih Desa");

                          getDataKabupaten(val.value);
                        }}
                        error={
                          errors.id_provinsi && touched.id_provinsi && true
                        }
                        errorText={
                          errors.id_provinsi &&
                          touched.id_provinsi &&
                          errors.id_provinsi
                        }
                        isDisabled={tipe == "detail" ? true : false}
                      />

                      <SelectSearch
                        label="Kabupaten"
                        name="id_kabupaten"
                        defaultValue={{
                          value: values.id_kabupaten,
                          label: values.nama_kabupaten,
                        }}
                        option={dataKabupaten.map((val) => {
                          return {
                            value: val.id_kabupaten,
                            label: val.nama_kabupaten,
                          };
                        })}
                        onChange={(val) => {
                          setFieldValue("id_kabupaten", val.value);
                          setFieldValue("nama_kabupaten", val.label);
                          setFieldValue("id_kecamatan", "");
                          setFieldValue("nama_kecamatan", "Pilih Kecamatan");
                          setFieldValue("id_desa", "");
                          setFieldValue("nama_desa", "Pilih Desa");

                          getDataKecamatan(val.value);
                        }}
                        error={
                          errors.id_kabupaten && touched.id_kabupaten && true
                        }
                        errorText={
                          errors.id_kabupaten &&
                          touched.id_kabupaten &&
                          errors.id_kabupaten
                        }
                        loading={loading.kabupaten}
                        isDisabled={
                          tipe != "detail"
                            ? (values.id_provinsi != null) &
                              (values.id_provinsi != "")
                              ? false
                              : true
                            : true
                        }
                      />
                    </Col>

                    <Col lg="6">
                      <SelectSearch
                        label="Kecamatan"
                        name="id_kecamatan"
                        defaultValue={{
                          value: values.id_kecamatan,
                          label: values.nama_kecamatan,
                        }}
                        option={dataKecamatan.map((val) => {
                          return {
                            value: val.id_kecamatan,
                            label: val.nama_kecamatan,
                          };
                        })}
                        onChange={(val) => {
                          setFieldValue("id_kecamatan", val.value);
                          setFieldValue("nama_kecamatan", val.label);
                          setFieldValue("id_desa", "");
                          setFieldValue("nama_desa", "Pilih Desa");

                          getDataDesa(val.value);
                        }}
                        error={
                          errors.id_kecamatan && touched.id_kecamatan && true
                        }
                        errorText={
                          errors.id_kecamatan &&
                          touched.id_kecamatan &&
                          errors.id_kecamatan
                        }
                        loading={loading.kecamatan}
                        isDisabled={
                          tipe != "detail"
                            ? (values.id_kabupaten != null) &
                              (values.id_kabupaten != "")
                              ? false
                              : true
                            : true
                        }
                      />

                      <SelectSearch
                        label="Desa"
                        name="id_desa"
                        defaultValue={{
                          value: values.id_desa,
                          label: values.nama_desa,
                        }}
                        option={dataDesa.map((val) => {
                          return {
                            value: val.id_desa,
                            label: val.nama_desa,
                          };
                        })}
                        onChange={(val) => {
                          setFieldValue("id_desa", val.value);
                          setFieldValue("nama_desa", val.label);
                        }}
                        error={errors.id_desa && touched.id_desa && true}
                        errorText={
                          errors.id_desa && touched.id_desa && errors.id_desa
                        }
                        loading={loading.desa}
                        isDisabled={
                          tipe != "detail"
                            ? (values.id_kecamatan != null) &
                              (values.id_kecamatan != "")
                              ? false
                              : true
                            : true
                        }
                      />
                    </Col>
                  </Row>

                  <TextArea
                    label="Detail Lokasi"
                    type="text"
                    name="detail_lokasi"
                    value={values.detail_lokasi}
                    onChange={handleChange}
                    rows={2}
                    error={
                      errors.detail_lokasi && touched.detail_lokasi && true
                    }
                    errorText={
                      errors.detail_lokasi &&
                      touched.detail_lokasi &&
                      errors.detail_lokasi
                    }
                    readOnly={tipe == "detail" ? true : false}
                  />
                </ColumnData>

                <ColumnData title="Data Pencarian Aset">
                  <Input
                    label="QR Code"
                    type="text"
                    name="qr_code"
                    value={values.qr_code}
                    onChange={handleChange}
                    error={errors.qr_code && touched.qr_code && true}
                    errorText={errors.qr_code}
                    readOnly={tipe == "detail" ? true : false}
                  />

                  <Input
                    label="Barcode"
                    type="text"
                    name="barcode"
                    value={values.barcode}
                    onChange={handleChange}
                    error={errors.barcode && touched.barcode && true}
                    errorText={errors.barcode}
                    readOnly={tipe == "detail" ? true : false}
                  />
                </ColumnData>

                {tipe != "detail" && (
                  <div className="text-right">
                    <ActionButton
                      type="submit"
                      variant="success"
                      text={tipe.toUpperCase()}
                      className="mt-2 px-4"
                      loading={isSubmitting}
                    />
                  </div>
                )}
              </form>
            )}
          </Formik>
          <AlertModal
            show={saveAlert}
            onHide={() => setSaveAlert(false)}
            textColor={saveAlertConfig.textColor}
            title={saveAlertConfig.title}
            message={saveAlertConfig.message}
            link="/asm/master/item-aset/aset-alat-dan-mesin"
            status={saveAlertConfig.status}
          />
        </>
      )}
    </>
  );
};

export default InformasiUmum;
