import { Button, ButtonDetail } from '@bhawanadevteam/react-core';
import {
  CRUDLayout,
  Pagination,
  THead,
  ThFixed,
  Th,
  TBody,
  Tr,
  TdFixed,
  Td,
  Table,
} from 'components';
import React, { useState } from 'react';
import { DateConvert, DecimalConvert, PageNumber } from 'utilities';
import { ModalDetailPermintaanWorkOrder } from './ModalDetailPermintaanWorkOrder';

export const ListTableData = ({ setParams, params, type, data }) => {
  const [modalConfig, setModalConfig] = useState({ show: false, data: {} });
  return (
    <div>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Permintaan Work Order</ThFixed>
            <ThFixed>Informasi Work Order</ThFixed>
            <Th>Item Permintaan Work Order</Th>
            <Th>Qty. Permintaan Produksi</Th>
            <Th>Qty. Telah Ditransfer</Th>
            <ThFixed>Status Permintaan</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => {
            const checkStatus =
              parseFloat(val?.[`qty_permintaan_work_order_${type}`] ?? 0) >
              parseFloat(val?.[`qty_transfer_work_order_${type}`] ?? 0);

            return (
              <Tr key={index}>
                <TdFixed>
                  {PageNumber(
                    params?.[type]?.page,
                    params?.[type]?.per_page,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <ButtonDetail
                    icon
                    onClick={() => setModalConfig({ show: true, data: val })}
                  />
                </TdFixed>
                <TdFixed>
                  <div>
                    {val?.[`tgl_permintaan_work_order_${type}`]
                      ? DateConvert(
                          new Date(val?.[`tgl_permintaan_work_order_${type}`])
                        ).defaultDMY
                      : '-'}
                  </div>
                  <div>{val?.[`no_permintaan_work_order_${type}`] ?? '-'}</div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {val?.tgl_work_order
                      ? DateConvert(new Date(val?.tgl_work_order)).defaultDMY
                      : '-'}
                  </div>
                  <div>{val?.no_work_order ?? '-'}</div>
                </TdFixed>
                <Td>{val.nama_item ?? '-'}</Td>
                <Td className="text-right">
                  {
                    DecimalConvert(
                      parseFloat(
                        val?.[`qty_permintaan_work_order_${type}`] ?? 0
                      )
                    ).getWithComa
                  }
                </Td>
                <Td className="text-right">
                  {
                    DecimalConvert(
                      parseFloat(val?.[`qty_transfer_work_order_${type}`] ?? 0)
                    ).getWithComa
                  }
                </Td>
                <TdFixed className="text-center">
                  <Button
                    variant={
                      checkStatus ? 'outline-warning' : 'outline-success'
                    }
                    text={checkStatus ? 'DIPROSES' : 'SELESAI'}
                    disabled
                  />
                </TdFixed>
              </Tr>
            );
          })}
        </TBody>
      </Table>
      <Pagination
        dataLength={params?.[type]?.per_page}
        dataPage={
          params?.[type]?.totalData <= 10
            ? data.length
            : data.map((_res, index) => {
                if (index === data.length - 1) {
                  return PageNumber(
                    params?.[type]?.page,
                    params?.[type]?.per_page,
                    index
                  );
                } else {
                  return null;
                }
              })
        }
        dataNumber={data.map((_res, index) => {
          if (index === 0) {
            return PageNumber(
              params?.[type]?.page,
              params?.[type]?.per_page,
              index
            );
          } else {
            return null;
          }
        })}
        dataCount={params?.[type]?.totalData}
        onDataLengthChange={(e) => {
          setParams({
            ...params,
            [type]: {
              ...params?.[type],
              per_page: e.target.value,
              page: 1,
            },
          });
        }}
        currentPage={params?.[type]?.page}
        totalPage={params?.[type]?.totalPage}
        onPaginationChange={({ selected }) =>
          setParams({
            ...params,
            [type]: {
              ...params?.[type],
              page: selected + 1,
            },
          })
        }
      />
      {modalConfig.show && (
        <ModalDetailPermintaanWorkOrder
          setModalConfig={setModalConfig}
          modalConfig={modalConfig}
          type={type}
        />
      )}
    </div>
  );
};
