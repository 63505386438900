export const formSubmitValueMapper = (data = {}) => ({
  id_penerimaan_aset: data?.id_penerimaan_aset,
  tgl_penerimaan_aset: data?.tgl_penerimaan_aset,
  no_surat_jalan_vendor: data?.no_surat_jalan_vendor,
  id_purchase_order_aset_detail: data?.id_purchase_order_aset_detail,
  id_petugas_penerimaan: data?.id_petugas_penerimaan,
});

export const formSubmitValueMapperRegisterAset = (data = {}) => ({
  id_entitas_aset: data?.id_entitas_aset,
  id_grup_aset: data?.id_grup_aset,
  id_kategori_aset: data?.id_kategori_aset,
  id_jenis_aset: data?.id_jenis_aset,
  id_unit_organisasi: data?.id_unit_organisasi,
  harga_perolehan: data?.harga_perolehan,
  id_penerimaan_aset: data?.id_penerimaan_aset,
  id_kondisi_aset: data?.id_kondisi_aset,
  id_karyawan: data?.id_karyawan,
  id_provinsi: data?.id_provinsi,
  id_kabupaten: data?.id_kabupaten,
  id_kecamatan: data?.id_kecamatan,
  id_desa: data?.id_desa,
  detail_lokasi: data?.detail_lokasi,
  bulan_perolehan: data?.bulan_perolehan,
  tahun_perolehan: data?.tahun_perolehan,
  id_satuan_pakai: data?.id_satuan_pakai,
  id_pabrikan: data?.id_pabrikan,
  no_sertifikat: data?.no_sertifikat,
  disewa: data?.disewa,
  produksi: data?.produksi,
  nama_item_aset: data?.nama_item_aset,
  kode_item_aset: data?.kode_item_aset,
  plat_nomor: data?.plat_nomor,
  no_seri: data?.no_seri,
  keterangan: data?.keterangan,
});
