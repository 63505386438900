import { Table, TBody, Th, THead, ThFixed, Tr, Td, TdFixed } from 'components';
import { useFormikContext } from 'formik';
import { RupiahConvert } from 'utilities';

export const TableSummaryBiaya = () => {
  const { values } = useFormikContext();

  const calculateSubtotal = (data = [], buaso) => {
    return data?.reduce(
      (total, item) =>
        total +
        Math.round(
          parseFloat(item?.[`qty_work_order_${buaso}`] ?? 0) *
            parseFloat(item.harga_satuan),
        ),
      0,
    );
  };

  const calculateTotal = (data = []) => {
    return data?.reduce(
      (total, { jumlah }) => total + Math.round(jumlah ?? 0),
      0,
    );
  };

  const data = [
    { label: 'Bahan', jumlah: calculateSubtotal(values.bahan, 'bahan') },
    { label: 'Upah', jumlah: calculateSubtotal(values.upah, 'upah') },
    {
      label: 'Alat',
      jumlah: calculateSubtotal(values.alat_mesin, 'alat_mesin'),
    },
    { label: 'Subkon', jumlah: calculateSubtotal(values.subkon, 'subkon') },
    {
      label: 'Overhead',
      jumlah: calculateSubtotal(values.overhead, 'overhead'),
    },
  ];

  return (
    <>
      <small className="font-weight-bold my-1">Summary Biaya</small>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>Buaso</Th>
            <Th>Jumlah</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr>
              <TdFixed textCenter>{index + 1}</TdFixed>
              <Td>{val.label ?? '-'}</Td>
              <Td textRight>
                {RupiahConvert(String(val.jumlah ?? 0)).getWithComa}
              </Td>
            </Tr>
          ))}

          <Tr>
            <Td colSpan={2} textRight>
              Total
            </Td>
            <Td textRight>
              {RupiahConvert(String(calculateTotal(data))).getWithComa}
            </Td>
          </Tr>
        </TBody>
      </Table>
    </>
  );
};
