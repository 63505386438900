import Services from 'services';

class PermintaanWorkOrderApi {
  async getList(params) {
    const fetch = await Services.get('/permintaan_work_order/page', {
      params,
    });
    return fetch.data;
  }

  async getListWorkOrder(params) {
    const fetch = await Services.get('/permintaan_work_order/work_order', {
      params,
    });
    return fetch.data;
  }

  async getSingleWorkOrder(params) {
    const fetch = await Services.get(
      '/permintaan_work_order/single_work_order',
      {
        params,
      }
    );
    return fetch.data;
  }

  async getSingle(params) {
    const fetch = await Services.get('/permintaan_work_order/single', {
      params,
    });
    return fetch.data;
  }

  async getDropdownItem(params) {
    const fetch = await Services.get('/permintaan_work_order/dropdown_item', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item,
      value: val.id_item,
    }));
  }

  async getDropdownItemFilter(params) {
    const fetch = await Services.get('/permintaan_work_order/dropdown_item', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item_aset,
      value: val.id_item_aset,
    }));
  }

  async getDropdownStatusPermintaan() {
    return [
      {
        value: true,
        label: 'SUDAH DIMINTA',
      },
      {
        value: false,
        label: 'BELUM DIMINTA',
      },
    ];
  }

  generateNumber(params) {
    return Services.get('/permintaan_work_order/no_baru', { params });
  }

  create(data) {
    return Services.post('/permintaan_work_order', data);
  }

  selesai(data) {
    return Services.put('/permintaan_work_order/selesai', data);
  }
}

export default new PermintaanWorkOrderApi();
