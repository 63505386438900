import { useFormikContext } from 'formik';
import { Card, Col, Row } from 'react-bootstrap';
import { DatePicker, InfoItemHorizontal, Input } from 'components';
import { CloseWorkOrderApi } from 'api';
import { DateConvert } from 'utilities';

export const FormCloseProduksi = ({ readOnly }) => {
  const { values, setFieldValue, errors, touched } = useFormikContext();

  const getNomor = (date) => {
    CloseWorkOrderApi.getNomor(date).then((res) => {
      setFieldValue('no_close_work_order', res.data.data);
    });
  };

  return (
    <>
      <div className="font-weight-bold" style={{ fontSize: '14px' }}>
        Close Work Order
      </div>

      <Card className="mb-3">
        <Card.Body>
          {readOnly ? (
            <>
              <InfoItemHorizontal
                label="Tgl. Close Work Order"
                text={
                  values.tgl_close_work_order
                    ? DateConvert(values.tgl_close_work_order).detail
                    : null
                }
              />

              <InfoItemHorizontal
                label="No. Close Work Order"
                text={values.no_close_work_order}
              />
            </>
          ) : (
            <Row>
              <Col>
                <DatePicker
                  label="Tgl. Close Work Order"
                  selected={
                    values.tgl_close_work_order
                      ? new Date(values.tgl_close_work_order)
                      : null
                  }
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => {
                    setFieldValue('tgl_close_work_order', date);
                    getNomor(date);
                  }}
                  error={
                    errors?.tgl_close_work_order &&
                    touched?.tgl_close_work_order
                  }
                  errorText={errors?.tgl_close_work_order}
                />
              </Col>

              <Col>
                <Input
                  disabled
                  label="No. Close Work Order"
                  value={values.no_close_work_order}
                />
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
