// React
import React from 'react';

// Form
import { Formik, useFormikContext } from 'formik';

// Components
import { DatePicker, FilterModal } from 'components';
import { Select } from 'components2';

// API
import { useQuery } from 'react-query';
import ItemCheclistApi from '../__PenerimaanAsetApi__';
import { DateConvert } from 'utilities';
import PenerimaanAsetApi from '../__PenerimaanAsetApi__/PenerimaanAsetApi';

export const ModalFilter = ({ toggle }) => {
  const { values, handleSubmit, setValues } = useFormikContext();

  const { data: dropdownVendor, isFetching: loadingDropdownVendor } = useQuery(
    ['vendor', 'dropdown'],
    () => PenerimaanAsetApi.getDropdownVendor()
  );

  const dataStatusChecklist = [
    { label: 'Approve', value: 'APP' },
    { label: 'Verify', value: 'VER' },
    { label: 'Revisi', value: 'REV' },
    { label: 'Reject', value: 'REJ' },
    { label: 'Pending', value: 'PEN' },
  ];

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    const myPromise = new Promise((resolve) => {
      setValues({
        tgl_penerimaan_aset_mulai: undefined,
        tgl_penerimaan_aset_selesai: undefined,
        tgl_purchase_order_aset_mulai: undefined,
        tgl_purchase_order_aset_selesai: undefined,
        id_vendor: undefined,
        status_approval: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => {});
  };

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  return (
    <FilterModal
      isToggle
      toggleModal={toggle}
      onResetButtonClick={() => onResetButtonClick(setValues)}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        label="Tgl. Penerimaan Aset"
        placeholderText="Pilih tgl. penerimaan aset"
        startDate={
          values?.tgl_penerimaan_aset_mulai
            ? new Date(values?.tgl_penerimaan_aset_mulai)
            : ''
        }
        endDate={
          values?.tgl_penerimaan_aset_selesai
            ? new Date(values?.tgl_penerimaan_aset_selesai)
            : ''
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            'tgl_penerimaan_aset_mulai',
            'tgl_penerimaan_aset_selesai'
          )
        }
        monthsShown={2}
      />

      <DatePicker
        selectsRange
        label="Tgl. Purchase Order Aset"
        placeholderText="Pilih tgl. purchase order aset"
        startDate={
          values?.tgl_purchase_order_aset_mulai
            ? new Date(values?.tgl_purchase_order_aset_mulai)
            : ''
        }
        endDate={
          values?.tgl_purchase_order_aset_selesai
            ? new Date(values?.tgl_purchase_order_aset_selesai)
            : ''
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            'tgl_purchase_order_aset_mulai',
            'tgl_purchase_order_aset_selesai'
          )
        }
        monthsShown={2}
      />

      <Select
        label="Vendor"
        placeholder="Pilih status approval"
        name="id_vendor"
        loading={loadingDropdownVendor}
        options={dropdownVendor ?? []}
        onChange={async (val) =>
          setValues({
            ...values,
            id_vendor: val.value,
          })
        }
        defaultValue={dropdownVendor?.find(
          (val) => val.value === values.id_vendor
        )}
      />

      <Select
        label="Status Approval"
        placeholder="Pilih status approval"
        name="status_approval"
        options={dataStatusChecklist ?? []}
        onChange={async (val) =>
          setValues({
            ...values,
            status_approval: val.value,
          })
        }
        defaultValue={dataStatusChecklist?.find(
          (val) => val.value === values.status_approval
        )}
      />
    </FilterModal>
  );
};
