import { useFormikContext } from 'formik';
import { Card, Col, Modal, Row } from 'react-bootstrap';
import { ActionButton, InfoItemHorizontal } from 'components';
import { AlertCloseWorkOrder } from './AlertCloseWorkOrder';
import { FormCloseProduksi } from './FormCloseProduksi';
import { InfoMaintenanceRequest } from './InfoMaintenanceRequest';
import { TableRealisasiPekerjaan } from './TableRealisasiPekerjaan';
import { TabRealisasiBiaya } from './TabRealisasiBiaya';

export const ModalCreateWorkOrder = ({
  show,
  data,
  onClose,
  infoMaintenance,
}) => {
  const { handleSubmit } = useFormikContext();

  return (
    <>
      <Modal scrollable show={show} size="xl" onHide={onClose}>
        <Modal.Header closeButton> Close Work Order </Modal.Header>
        <Modal.Body>
          <>
            <div className="font-weight-bold " style={{ fontSize: '14px' }}>
              Detail Data Work Order
            </div>

            <Card className="mb-3">
              <Card.Body>
                <Row>
                  <Col>
                    <InfoItemHorizontal
                      label="Tgl. Work Order"
                      text={data?.tgl_work_order}
                    />

                    <InfoItemHorizontal
                      label="No. Work Order"
                      text={data?.no_work_order}
                    />

                    <InfoItemHorizontal
                      label="Item Aset"
                      text={data?.nama_item_aset}
                    />

                    <InfoItemHorizontal
                      label="Petugas Maintenance"
                      text={data?.nama_petugas ?? '-'}
                    />

                    <InfoItemHorizontal
                      label="Status Petugas Maintenance"
                      text={
                        data?.is_mekanik_eksternal
                          ? 'Mekanik Eksternal'
                          : 'Mekanik Internal'
                      }
                    />

                    <InfoItemHorizontal
                      label="Vendor"
                      text={data?.nama_vendor ?? 'AJB'}
                    />
                  </Col>

                  <Col>
                    <InfoMaintenanceRequest data={infoMaintenance} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <FormCloseProduksi readOnly={true} />

            <TableRealisasiPekerjaan />

            <TabRealisasiBiaya />

            <AlertCloseWorkOrder />

            <div className="text-right mt-4">
              <ActionButton
                text={'Close Work Order'}
                variant={'danger'}
                onClick={handleSubmit}
              />
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
