import { useState, createContext } from 'react';
import { WorkOrderApi } from 'api';
import { useQuery } from 'react-query';

export const WorkOrderContext = createContext();

export const WorkOrderProvider = ({ children }) => {
  const STALE_DURATION_MINUTES = 4 * 60 * 1000; // 4 MINUTES

  const [modalWorkOrder, setModalWorkOrder] = useState({
    show: false,
    isCreate: false,
    data: null,
  });

  const dropdownBahan = useQuery(
    ['dropdown', 'bahan'],
    () =>
      WorkOrderApi.getDropdown({ id_buaso: '1' }).then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item,
          label: `${val.kode_item} | ${val.nama_item}`,
        }));

        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownUpah = useQuery(
    ['dropdown', 'upah'],
    () =>
      WorkOrderApi.getDropdown({ id_buaso: '2' }).then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item,
          label: `${val.kode_item} | ${val.nama_item}`,
        }));

        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownAlat = useQuery(
    ['dropdown', 'alat_mesin'],
    () =>
      WorkOrderApi.getDropdown({ id_buaso: '3' }).then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item,
          label: `${val.kode_item} | ${val.nama_item}`,
        }));

        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownSubkon = useQuery(
    ['dropdown', 'subkon'],
    () =>
      WorkOrderApi.getDropdown({ id_buaso: '4' }).then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item,
          label: `${val.kode_item} | ${val.nama_item}`,
        }));

        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownOverhead = useQuery(
    ['dropdown', 'overhead'],
    () =>
      WorkOrderApi.getDropdown({ id_buaso: '5' }).then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item,
          label: `${val.kode_item} | ${val.nama_item}`,
        }));

        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownKaryawan = useQuery(
    ['dropdown', 'karyawan'],
    () =>
      WorkOrderApi.getDropdownKaryawan().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_karyawan,
          label: val.nama_karyawan,
        }));

        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownAset = useQuery(
    ['dropdown', 'aset'],
    () =>
      WorkOrderApi.getDropdownAset().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item_aset,
          label: val.nama_item_aset,
        }));

        return data;
      }),

    { staleTime: STALE_DURATION_MINUTES },
  );

  const dropdownVendor = useQuery(
    ['dropdown', 'vendor'],
    () =>
      WorkOrderApi.getDropdownVendor()
        .then((res) => {
          const data = res.data.data.map((val) => ({
            ...val,
            value: val.id_vendor,
            label: val.nama_vendor,
          }));

          return data;
        })
        .catch(() => {
          return [
            {
              value: '89',
              label: 'BAPAK DARMANTO',
            },
            {
              value: '56',
              label: 'BUDI JAYA MANDIRI',
            },
          ];
        }),
    { staleTime: STALE_DURATION_MINUTES },
  );

  return (
    <WorkOrderContext.Provider
      value={{
        modalWorkOrder,
        setModalWorkOrder,
        dropdownBahan,
        dropdownUpah,
        dropdownAlat,
        dropdownSubkon,
        dropdownOverhead,
        dropdownKaryawan,
        dropdownVendor,
        dropdownAset,
      }}
    >
      {children}
    </WorkOrderContext.Provider>
  );
};

export const workOrderProvider = (Component) => (props) => {
  return (
    <WorkOrderProvider>
      <Component {...props} />
    </WorkOrderProvider>
  );
};
