import React, { useState, useEffect, memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { dateConvert, numberConvert, rupiahConvert } from 'utilities2';
import { Formik } from 'formik';
import { range } from 'lodash';
import {
  Button,
  ChartBarHorizontal,
  ChartDoughnut,
  Select,
  ChartPie,
} from 'components2';
import { DatePicker } from '../../components';
import DashboardApi from './api';
import { useQuery } from 'react-query';
import { RupiahConvert } from 'utilities';
import {
  AkumulasiRekapitulasiPenyusutan,
  AsetChartLine,
  TotalKendaraanChart,
} from './Component';

const date = dateConvert();
const yearNow = date.getYear(new Date());
const monthNow = date.getMonth(new Date());
const rupiah = rupiahConvert();
const number = numberConvert();
const defaultEndDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 1,
  new Date().getDate()
);

const monthOptions = [
  { label: 'Januari', value: '01' },
  { label: 'Februari', value: '02' },
  { label: 'Maret', value: '03' },
  { label: 'April', value: '04' },
  { label: 'Mei', value: '05' },
  { label: 'Juni', value: '06' },
  { label: 'Juli', value: '07' },
  { label: 'Agustus', value: '08' },
  { label: 'September', value: '09' },
  { label: 'Oktober', value: '10' },
  { label: 'November', value: '11' },
  { label: 'Desember', value: '12' },
];

const yearOptions = range(2000, parseInt(yearNow + 1))
  .map((val) => ({
    label: val,
    value: val,
  }))
  .reverse();

const InfoItem = memo(
  ({
    title,
    percent,
    value = 100,
    loading,
    color,
    data,
    chartBgColor: backgroundColor,
  }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center bg-white rounded border shadow-sm px-4 pt-2 pb-3"
        style={{ height: '100%' }}
      >
        <div className="position-relative">
          <div
            style={{
              position: 'absolute',
              top: 38,
              left:
                percent?.toString()?.length > 2
                  ? 28
                  : value?.toString()?.length > 2
                  ? 28
                  : 32,
              color: color,
            }}
          >
            <b style={{ fontSize: 14 }}>
              {loading ? '-' : percent ? percent : value}%
            </b>
          </div>
          <ChartDoughnut
            height={90}
            width={90}
            data={{
              datasets: [
                {
                  data: data,
                  backgroundColor,
                },
              ],
            }}
          />
        </div>
        <div className="text-right">
          <div>{title}</div>
          {loading ? (
            <small>Memuat data . . .</small>
          ) : (
            <b style={{ fontSize: 40, fontWeight: 1200, lineHeight: 1 }}>
              {value}
            </b>
          )}
        </div>
      </div>
    );
  }
);

const InfoItemNilaiPerolehan = memo(({ title, value, loading }) => {
  return (
    <div className="d-flex justify-content-between align-items-center bg-white rounded border shadow-sm p-3 mb-3">
      <div
        style={{ fontSize: 50, pointerEvents: 'none', color: 'black' }}
        className="btn btn-primary font-weight-bold"
      >
        Rp
      </div>
      <div className="d-flex flex-column align-items-end justify-content-end">
        <div>{title}</div>
        {loading ? (
          <small>Memuat data . . .</small>
        ) : (
          <b style={{ fontSize: 28, fontWeight: 1200, lineHeight: 1.2 }}>
            {value}
          </b>
        )}
      </div>
    </div>
  );
});

const Dashboard = ({ setNavbarTitle }) => {
  const [filter, setFilter] = useState({
    startMonth: monthNow,
    endMonth: date.getMonth(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        new Date().getDate()
      )
    ),
    year: date.getYear(new Date()),
    convertMonth: {
      startDate: '',
      endDate: '',
    },
  });

  const { data: dataItemAset, isFetching: loadingItemAset } = useQuery(
    ['dataItemAset', filter],
    () => DashboardApi.getItemAset()
  );

  const { data: dataNilaiPerolehan, isFetching: loadingNilaiPerolehan } =
    useQuery(['dataNilaiPerolehan', filter], () =>
      DashboardApi.getNilaiPerolehan({
        tahun: filter?.year,
        bulan_mulai: filter?.startMonth,
        bulan_selesai: filter?.endMonth,
      })
    );

  const { data: dataKondisiAset, isFetching: loadingKondisiAset } = useQuery(
    ['dataKondisiAset', filter],
    () => DashboardApi.getKondisiAset()
  );

  const { data: dataPembelianAset, isFetching: loadingPembelianAset } =
    useQuery(['dataPemebilanAset', filter], () =>
      DashboardApi.getPembelianAset({
        tahun: filter?.year,
      })
    );

  const { data: dataProduksiAset, isFetching: loadingProduksiAset } = useQuery(
    ['dataProduksiAset', filter],
    () =>
      DashboardApi.getProduksiAset({
        tahun: filter?.year,
      })
  );

  const generateMaxDate = (values) => {
    const date = new Date(values.startMonth);
    date.setMonth(date.getMonth() + 12);
    return date;
  };

  const convertDate = (startDate, endDate) => {
    const dateFilter = [startDate, endDate];

    const monthName = dateFilter.map((val) => {
      const monthValue = val; // Angka bulan (contoh: 10 untuk Oktober)
      const currentYear = new Date().getFullYear(); // Mendapatkan tahun saat ini
      const dayOfMonth = date.getDay(new Date()); // Contoh hari 1, Anda bisa menggantinya sesuai kebutuhan

      const todayDate = date.getDetailMonth(
        new Date(currentYear, monthValue - 1, dayOfMonth)
      );

      return todayDate;
    });

    return monthName;
  };

  useEffect(() => {
    setNavbarTitle('Dashboard');
  }, [setNavbarTitle]);

  return (
    <>
      <Formik
        initialValues={{
          startMonth: new Date(),
          endMonth: defaultEndDate,
          year: filter.year,
        }}
        onSubmit={(val) => {
          const startMonth = date.getMonth(new Date(val.startMonth));
          const endMonth = date.getMonth(new Date(val.endMonth));
          const monthName = convertDate(startMonth, endMonth);

          setFilter({
            startMonth,
            endMonth,
            year: val.year,
            convertMonth: {
              startDate: monthName[0],
              endDate: monthName[1],
            },
          });
        }}
      >
        {({ values, setValues, handleSubmit }) => (
          <div className="d-flex justify-content-center justify-content-lg-end">
            <div className="d-flex flex-column flex-md-row">
              {/* Bulan */}
              <div className="d-flex align-items-center mr-2">
                <div style={{ width: 150 }}>
                  <DatePicker
                    selectsRange
                    dateFormat="MMMM"
                    showMonthYearPicker
                    selected={values.startMonth}
                    startDate={values.startMonth}
                    maxDate={values.startMonth ? generateMaxDate(values) : null}
                    endDate={values.endMonth}
                    onChange={([startMonth, endMonth]) =>
                      setValues({
                        ...values,
                        startMonth: startMonth,
                        endMonth: endMonth,
                      })
                    }
                  />
                </div>
              </div>

              {/* Tahun */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Tahun</span>
                <div style={{ width: 150 }}>
                  <Select
                    noMargin
                    options={yearOptions}
                    defaultValue={yearOptions.find(
                      (val) => val.value === values.year
                    )}
                    onChange={(val) =>
                      setValues((prev) => ({ ...prev, year: val.value }))
                    }
                  />
                </div>
              </div>

              {/* Button */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <Button
                  disabled={!values.startMonth || !values.endMonth}
                  text="Filter"
                  className="px-4"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>
      <Row>
        <Col lg="3" className="mb-3">
          <InfoItem
            loading={loadingItemAset}
            type="default"
            title="Total Aset"
            percent={100}
            value={dataItemAset?.data?.total_item_aset}
            data={[0, dataItemAset?.data?.total_item_aset]}
            chartBgColor={['#0073b9', '#00abda']}
          />
        </Col>
        {dataItemAset?.data?.grup_aset.map((val) => (
          <Col lg="3" className="mb-3">
            <InfoItem
              loading={loadingItemAset}
              type="default"
              title={`Total ${val.nama_grup_aset}`}
              percent={val?.persentase}
              value={val?.total}
              data={[
                parseInt(dataItemAset?.data?.total_item_aset) -
                  parseInt(val?.total),
                parseInt(val?.total),
              ]}
              chartBgColor={['#0073b9', '#00abda']}
            />
          </Col>
        ))}
        <Col lg="6">
          <div className="p-3 bg-white border rounded shadow-sm">
            <div className="text-center">
              <b>Kondisi Aset</b>
            </div>
            {loadingKondisiAset ? (
              <div className="d-flex align-items-center justify-content-center my-5">
                Memuat data . . .
              </div>
            ) : (
              <>
                <ChartBarHorizontal
                  showLegend={false}
                  data={{
                    labels:
                      dataKondisiAset?.data?.map(
                        (val) => val?.nama_kondisi_aset
                      ) ?? [],
                    datasets: [
                      {
                        data:
                          dataKondisiAset?.data?.map((val) => val?.total) ?? [],
                        backgroundColor: '#0073b9',
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (val) =>
                            number.getWithComa(val.raw, {
                              minimumFractionDigits: 0,
                            }),
                        },
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          callback: (val) =>
                            number.getWithComa(val, {
                              minimumFractionDigits: 0,
                            }),
                        },
                      },
                    },
                  }}
                />
              </>
            )}
          </div>
          <AsetChartLine
            title="Pembelian Aset Tahun"
            data={dataPembelianAset}
            loading={loadingPembelianAset}
            filter={filter}
            monthOptions={monthOptions}
            rupiah={rupiah}
          />
          <TotalKendaraanChart
            monthOptions={monthOptions}
            startMonth={filter.startMonth}
            endMonth={filter.endMonth}
            year={filter.year}
          />
        </Col>
        <Col lg="6">
          <InfoItemNilaiPerolehan
            loading={loadingNilaiPerolehan}
            title={`Nilai Perolehan ${filter?.convertMonth?.startDate} - ${filter?.convertMonth?.endDate} ${filter.year}`}
            value={
              RupiahConvert(
                parseFloat(
                  dataNilaiPerolehan?.data?.total_nilai_perolehan
                ).toString()
              ).getWithComa
            }
          />
          <AkumulasiRekapitulasiPenyusutan
            monthOptions={monthOptions}
            startMonth={filter.startMonth}
            endMonth={filter.endMonth}
            year={filter.year}
          />
          <AsetChartLine
            title="Produksi Aset Tahun"
            data={dataProduksiAset}
            loading={loadingProduksiAset}
            filter={filter}
            monthOptions={monthOptions}
            rupiah={rupiah}
          />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
