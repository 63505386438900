// Components
import { Card } from "react-bootstrap"

const ProduksiSection = ({ children, title = "Komponen Utama" }) => (
	<>
		<b>{title}</b>

		<Card>
			{children}
		</Card>
	</>
)

export default ProduksiSection