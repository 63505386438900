// Components
import {
  DatePicker,
  InfoItemVertical,
  Input,
  NumberFormat,
  SelectSearch,
  TextArea,
} from "components";
import { Col, Row } from "react-bootstrap";
import { DateConvert, DecimalConvert } from "utilities";

// Form
import { useFormikContext } from "formik";
import { TransferAlatMesinJobMixApi } from "api";

const FormSection = ({ readOnly, buaso, dropdown }) => {
  // Variables
  const { values, setFieldValue, handleChange, errors, touched } =
    useFormikContext();

  const getDataNomor = (value) => {
    TransferAlatMesinJobMixApi.no_baru(value).then((res) => {
      setFieldValue("no_transfer_produksi_bahan", res.data.data);
    });
  };

  if (readOnly) {
    return (
      <Row>
        <Col md={6}>
          <InfoItemVertical
            className="text-capitalize"
            label={`Tgl. Transfer Alat & Mesin Produksi`}
            text={
              values[`tgl_transfer_produksi_${buaso}`]
                ? DateConvert(
                    new Date(values[`tgl_transfer_produksi_${buaso}`])
                  ).detail
                : "-"
            }
          />
        </Col>

        <Col md={6}>
          <InfoItemVertical
            className="text-capitalize"
            label={`No. Transfer Alat & Mesin Produksi`}
            text={values[`no_transfer_produksi_${buaso}`] ?? "-"}
          />
        </Col>

        <Col md={6}>
          <InfoItemVertical
            label="Diserahkan Oleh"
            text={values.nama_karyawan ?? "-"}
          />
        </Col>

        <Col md={6}>
          <InfoItemVertical
            label="Qty. Transfer"
            text={`${DecimalConvert(values.qty_transfer ?? 0).getWithComa} ${
              values.kode_satuan ?? ""
            }`}
          />
        </Col>

        <Col md={6}>
          <InfoItemVertical
            label="Keterangan Transfer"
            text={values.keterangan_transfer ?? "-"}
          />
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col md={6}>
          <DatePicker
            label="Tgl. Transfer Alat & Mesin Produksi"
            selected={
              values.tgl_transfer_produksi_alat_mesin
                ? new Date(values.tgl_transfer_produksi_alat_mesin)
                : ""
            }
            placeholderText="Pilih Tgl. Transfer Alat & Mesin Produksi"
            onChange={(date) => {
              const tanggal = DateConvert(new Date(date)).default;

              setFieldValue("tgl_transfer_produksi_alat_mesin", tanggal);
              getDataNomor(tanggal);
            }}
            error={
              Boolean(errors.tgl_transfer_produksi_alat_mesin) &&
              touched.tgl_transfer_produksi_alat_mesin
            }
            errorText={
              Boolean(errors.tgl_transfer_produksi_alat_mesin) &&
              touched.tgl_transfer_produksi_alat_mesin &&
              errors.tgl_transfer_produksi_alat_mesin
            }
          />
        </Col>

        <Col md={6}>
          <Input
            label="No. Transfer Alat & Mesin Produksi"
            placeholder="No. Transfer Alat & Mesin Produksi"
            value={values.no_transfer_produksi_alat_mesin}
            readOnly
          />
        </Col>

        <Col md={8}>
          <SelectSearch
            label="Diserahkan Oleh"
            placeholder="Pilih Karyawan"
            defaultValue={dropdown.karyawan.find(
              (e) => e.value === values.id_karyawan
            )}
            onChange={(val) => setFieldValue("id_karyawan", val.value)}
            option={dropdown.karyawan}
            error={Boolean(errors.id_karyawan) && touched.id_karyawan}
            errorText={
              Boolean(errors.id_karyawan) &&
              touched.id_karyawan &&
              errors.id_karyawan
            }
          />
        </Col>

        <Col md={4}>
          {/* <Input
            label="Qty. Transfer"
            type="text"
            name="qty_transfer"
            placeholder="Qty. Transfer"
            value={values.qty_transfer}
            onChange={handleChange}
            error={Boolean(errors.qty_transfer) && touched.qty_transfer}
            errorText={
              Boolean(errors.qty_transfer) &&
              touched.qty_transfer &&
              errors.qty_transfer
            }
          /> */}
          <NumberFormat
            label="Qty. Transfer"
            value={values.qty_transfer}
            onChange={(e) => setFieldValue("qty_transfer", e)}
            error={Boolean(errors.qty_transfer) && touched.qty_transfer}
            errorText={
              Boolean(errors.qty_transfer) &&
              touched.qty_transfer &&
              errors.qty_transfer
            }
          />
        </Col>

        <Col md={12}>
          <TextArea
            label="Keterangan Transfer"
            name="keterangan_transfer"
            rows={4}
            placeholder="Keterangan Transfer"
            value={values.keterangan_transfer}
            onChange={handleChange}
            error={
              Boolean(errors.keterangan_transfer) && touched.keterangan_transfer
            }
            errorText={
              Boolean(errors.keterangan_transfer) &&
              touched.keterangan_transfer &&
              errors.keterangan_transfer
            }
          />
        </Col>
      </Row>
    );
  }
};

export default FormSection;
