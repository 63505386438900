import { Button } from '@bhawanadevteam/react-core';
import { InfoItemHorizontal, InfoItemVertical } from 'components';
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { DateConvert, DecimalConvert } from 'utilities';

export const ModalDetailPermintaanWorkOrder = ({
  setModalConfig,
  modalConfig,
  type,
}) => {
  const checkStatus =
    parseFloat(modalConfig?.data?.[`qty_permintaan_work_order_${type}`] ?? 0) >
    parseFloat(modalConfig?.data?.[`qty_transfer_work_order_${type}`] ?? 0);
  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={() => setModalConfig({ show: false, data: {} })}
        scrollable
      >
        <Modal.Header closeButton>Detail Permintaan Work Order</Modal.Header>
        <Modal.Body>
          <InfoItemHorizontal
            label="Tgl. Work Order"
            text={
              modalConfig?.data?.tgl_work_order
                ? DateConvert(new Date(modalConfig?.data?.tgl_work_order))
                    .detail
                : '-'
            }
          />
          <InfoItemHorizontal
            label="No. Work Order"
            text={modalConfig?.data?.no_work_order ?? '-'}
          />
          <InfoItemHorizontal
            label="Catatan"
            text={modalConfig?.data?.keterangan ?? '-'}
          />
          <hr />
          <Row>
            <Col>
              <InfoItemVertical
                label="Tgl. Permintaan Work Order"
                text={
                  modalConfig?.data?.[`tgl_permintaan_work_order_${type}`]
                    ? DateConvert(
                        new Date(
                          modalConfig?.data?.[
                            `tgl_permintaan_work_order_${type}`
                          ]
                        )
                      ).defaultDMY
                    : '-'
                }
              />
            </Col>
            <Col>
              <InfoItemVertical
                label="No. Permintaan Work Order"
                text={
                  modalConfig?.data?.[`no_permintaan_work_order_${type}`] ?? '-'
                }
              />
            </Col>
          </Row>
          <InfoItemVertical
            label="Item Permintaan Work Order"
            text={modalConfig?.data?.nama_item ?? '-'}
          />
          <Row>
            <Col>
              <InfoItemVertical
                label="Qty Permintaan Work Order"
                text={
                  DecimalConvert(
                    parseFloat(
                      modalConfig?.data?.[
                        `qty_permintaan_work_order_${type}`
                      ] ?? 0
                    )
                  ).getWithComa
                }
              />
            </Col>
            <Col>
              <InfoItemVertical
                label="Qty Telah Di Transfer"
                text={
                  DecimalConvert(
                    parseFloat(
                      modalConfig?.data?.[`qty_transfer_work_order_${type}`] ??
                        0
                    )
                  ).getWithComa
                }
              />
            </Col>
          </Row>
          <InfoItemVertical
            label="Status Permintaan"
            text={
              <Button
                variant={checkStatus ? 'outline-warning' : 'outline-success'}
                text={checkStatus ? 'DIPROSES' : 'SELESAI'}
                disabled
              />
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            text="TUTUP"
            onClick={() => setModalConfig({ show: false, data: {} })}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};
