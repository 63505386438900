import React, { useState, useEffect } from "react";
import {
  CRUDLayout, Input, InputSearch, CreateButton, 
  ActionButton, DataStatus, DeleteModal,
  UpdateButton, DeleteButton, Alert, Pagination,
  THead, TBody, ThFixed, TdFixed, Tr, Th, Td,
  SelectSearch, TextArea, Switch,
} from "../../../components";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { EntitasAsetApi } from "../../../api";
import { PageNumber } from "../../../utilities";
import { ButtonGroup, Modal } from "react-bootstrap";

const EntitasAset = ({ setNavbarTitle }) => {
  // Title
  const title = "Entitas Aset";

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE SEARCHING
  const [searchKey, setSearchKey] = useState("");

  // MODALS SHOW STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE
  const [modal, setModal] = useState({
    show: false,
    submit: "Tambah",
  });

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState({
    id_entitas_aset: "",
    kode_entitas_aset: "",
    nama_entitas_aset: "",
    keterangan: "",
  });

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [alertConfigSearch, setAlertConfigSearch] = useState({
    data: false,
    variant: "primary",
    text: "",
  });

  const [searchTime, setSearchTime] = useState(null)

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchKey(key)
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        });
        setShowAlert(true)
      }, 750)
    );
  };

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setShowAlert(false);

    EntitasAsetApi.getPage(page, dataLength, searchKey)
    .then(res => {
      setData(res.data.data);
      setTotalPage(res.data.total_page);
      setTotalData(res.data.data_count);
    })
    .finally(() => {
      // if (searchKey != "") {
      //   setAlertConfigSearch({
      //     data: true,
      //     variant: "primary",
      //     text: `Hasil Pencarian : ${searchKey}`,
      //   });
      // } else {
      //   setAlertConfigSearch({
      //     data: false,
      //     variant: "",
      //     text: "",
      //   });
      // }

      setIsLoading(false);
    });
  };

  // FUNCTION SWITCH HIDDEN DATA
  const changeDataStatus = (status, value) => {
    setIsLoading(true);
    setShowAlert(false);

    const onLoadedSuccess = () => {
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? EntitasAsetApi.show(value)
        .then(() => {
          getData()
          onLoadedSuccess()
        })
        .catch(() => {
          getData()
          onLoadedFailed()
        })
      : EntitasAsetApi.hide(value)
        .then(() => {
          getData()
          onLoadedSuccess()
        })
        .catch(() => {
          getData()
          onLoadedFailed()
        })
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  const formInitialValues = {
    id_entitas_aset: updateData.id_entitas_aset,
    kode_entitas_aset: updateData.kode_entitas_aset,
    nama_entitas_aset: updateData.nama_entitas_aset,
    keterangan: updateData.keterangan,
  };

  const DataModal = () => {
    // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
    const formValidationSchema = Yup.object().shape({
      kode_entitas_aset: Yup.string().required("Kode Entitas Aset wajib diisi")
      .test('checkKode', 'Kode Entitas Aset telah didaftarkan', value => updateData.kode_entitas_aset === value
        ? true
        : EntitasAsetApi.single({
            kode_entitas_aset: value
          })
          .then(() => false)
          .catch(() => true)
      ),
      nama_entitas_aset: Yup.string().required("Nama Entitas Aset wajib diisi")
      .test('checkNama', 'Nama Entitas Aset telah didaftarkan', value => updateData.nama_entitas_aset === value
        ? true
        : EntitasAsetApi.single({
            nama_entitas_aset: value
          })
          .then(() => false)
          .catch(() => true)
      ),
    });
    
    const formSubmitHandler = (values) => {
      if (modal.submit == "Tambah") {
        EntitasAsetApi.create({
          kode_entitas_aset: values.kode_entitas_aset,
          nama_entitas_aset: values.nama_entitas_aset,
          keterangan: values.keterangan,
        })
          .then((res) => {
            // SUCCESS ALERT
            setAlertConfig({
              variant: "primary",
              text: "Tambah data berhasil!",
            });
          })
          .catch((err) => {
            // ERROR ALERT
            setAlertConfig({
              variant: "danger",
              text: `Tambah data gagal! (${err.response.data.message})`,
            });
          })
          .finally(() => {
            // CLOSE MODAL
            setModal({
              ...modal,
              show: false
            });
            // FETCH DATA DARI SERVER
            getData();
            // TAMPILKAN ALERT
            setShowAlert(true);
          });
      } else if (modal.submit == "Ubah") {
        EntitasAsetApi.update({
          id_entitas_aset: values.id_entitas_aset,
          kode_entitas_aset: values.kode_entitas_aset,
          nama_entitas_aset: values.nama_entitas_aset,
          keterangan: values.keterangan,
        })
          .then((res) => {
            // SUCCESS ALERT
            setAlertConfig({
              variant: "primary",
              text: "Ubah data berhasil!",
            });
          })
          .catch((err) => {
            // ERROR ALERT
            setAlertConfig({
              variant: "danger",
              text: `Ubah data gagal! (${err.response.data.message})`,
            });
          })
          .finally(() => {
            // CLOSE MODAL
            setModal({
              ...modal,
              show: false
            });
            // FETCH DATA DARI SERVER
            getData();
            // TAMPILKAN ALERT
            setShowAlert(true);
          });
      }
    };

    return (
      <Modal 
        show={modal.show}
        onHide={() => setModal({
          ...modal,
          show: false
        })}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title className={`text-${modal.submit === 'tambah' ? 'primary' : modal.submit === 'ubah' ? 'success' : 'dark'}`}>
            <h6 className="mb-0">
              {`${modal.submit} ${title}`}
            </h6>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="Kode Entitas Aset"
                  type="text"
                  name="kode_entitas_aset"
                  value={values.kode_entitas_aset}
                  onChange={handleChange}
                  error={errors.kode_entitas_aset && touched.kode_entitas_aset && true}
                  errorText={errors.kode_entitas_aset}
                />

                <Input
                  label="Nama Entitas Aset"
                  type="text"
                  name="nama_entitas_aset"
                  value={values.nama_entitas_aset}
                  onChange={handleChange}
                  error={errors.nama_entitas_aset && touched.nama_entitas_aset && true}
                  errorText={errors.nama_entitas_aset}
                />

                <TextArea
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                  rows={2}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant={modal.submit === 'tambah' ? 'primary' : modal.submit === 'ubah' ? 'success' : 'dark'}
                  text={modal.submit}
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    )
  }

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_entitas_aset: updateData.id_entitas_aset };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      EntitasAsetApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // FETCH DATA DARI SERVER
          getData();
          // TAMPIL ALERT
          setShowAlert(true);
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Entitas Aset : {updateData.kode_entitas_aset}</div>
        <div>Nama Entitas Aset : {updateData.nama_entitas_aset}</div>
      </DeleteModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>Kode Entitas Aset</Th>
              <Th>Nama Entitas Aset</Th>
              <Th>Keterangan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_entitas_aset}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <ButtonGroup size="sm" className="mr-1">
                        <UpdateButton
                          onClick={() => {
                            setUpdateData(val);
                            setModal({
                              show: true,
                              submit: "Ubah"
                            })
                          }}
                        />
                        <DeleteButton
                          onClick={() => {
                            setUpdateData(val);
                            setIsDeleteData(true);
                          }}
                        />
                      </ButtonGroup>
                      <Switch
                        id={val.id_entitas_aset}
                        checked={val.is_hidden ? false : true}
                        onChange={() => changeDataStatus(val.is_hidden, {id_entitas_aset: val.id_entitas_aset})}
                      />
                    </div>
                  </TdFixed>
                  <Td>{val.kode_entitas_aset}</Td>
                  <Td>{val.nama_entitas_aset}</Td>
                  <Td>{val.keterangan}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataLength}
          dataPage={
            totalData <= 10
              ? data.length
              : data.map((res, index) => {
                if (index == data.length - 1) {
                  return PageNumber(page, dataLength, index);
                }
              })
          }
          dataNumber={data.map((res, index) => {
            if (index == 0) {
              return PageNumber(page, dataLength, index);
            }
          })}
          dataCount={totalData}
          onDataLengthChange={(e) => {
            setDataLength(e.target.value);
            setPage(1);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch}/>
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Export */}
          {/* <ExportButton /> */}
          {/* Button Tambah */}
          <CreateButton onClick={() => {
            setUpdateData({
              id_entitas_aset: "",
              kode_entitas_aset: "",
              nama_entitas_aset: "",
              keterangan: "",
            })

            setModal({
              show: true,
              submit: "Tambah"
            })
          }} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Alert
        show={alertConfigSearch.data}
        showCloseButton={true}
        variant={alertConfigSearch.variant}
        text={alertConfigSearch.text}
        onClose={() => setAlertConfigSearch({
          data: false,
          variant: "primary",
          text: "",
        })}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <DataModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default EntitasAset;