// Components
import { Card, Col, Row } from "react-bootstrap";
import { DateConvert, DecimalConvert } from "utilities";

// View Components
import List from "../List";

const InfoTambahSection = ({ data }) => (
  <Card>
    <Card.Body>
      <Row>
        <Col md>
          <List label="Tgl. Produksi">
            {data.tgl_jobmix
              ? DateConvert(new Date(data.tgl_jobmix)).detail
              : "-"}
          </List>
          <List label="No. Produksi">{data.no_jobmix ?? "-"}</List>
          <List label="Proyek">{data.nama_proyek ?? "-"}</List>
        </Col>

        <Col md>
          <List label="Kode Barang">{data.kode_item ?? "-"}</List>
          <List label="Nama Barang">
            {data.nama_item ?? "-"}{" "}
            {data.nama_varian ? `[${data.nama_varian}]` : ""}
          </List>
          <List label="Qty Produksi">
            {DecimalConvert(data.qty_jobmix ?? 0).getWithComa}{" "}
            {data.kode_satuan ?? ""}
          </List>
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

export default InfoTambahSection;
