export const calcTotalProgress = (data = []) => {
  const allProgress = data?.reduce(
    (total, item) => total + parseFloat(item?.akumulasi_progress ?? 0),
    0
  );

  const totalProgress = allProgress / data?.length;

  return {
    allProgress,
    totalProgress,
  };
};
