// React
import React, { useState, useEffect } from "react";

// Form
import { Formik } from "formik";

// Components
import { FilterModal } from "components";
import { Select } from "components2";
import { DateConvert } from "utilities";

// API
import Axios from "axios";
import { TransferAlatMesinJobMixApi } from "api";
import { useQuery } from "react-query";
import ItemCheclistApi from "../__ItemChecklistApi__";

export const ModalFilter = ({ toggle, data, setData }) => {
  // Variables
  const option = { label: "Semua Data", value: undefined };

  // States

  const { data: jenisChecklist, isLoading: loadingJenisChecklist } = useQuery(
    ["jenisChecklist", "dropdown"],
    () => ItemCheclistApi.getDropdownJenisChecklist()
  );

  // FORM VALUES
  const formInitialValues = {
    jenis_checklist: data?.jenis_checklist,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setData({
      ...data,
      ...values,
      active: checkActive,
      page: 1,
    });
    toggle();
  };

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    const myPromise = new Promise((resolve) => {
      setValues({
        jenis_checklist: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => {});
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setValues }) => (
        <FilterModal
          isToggle
          toggleModal={toggle}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <Select
            label="Jenis Checklist"
            placeholder="Pilih jenis checklist"
            name="jenis_checklist"
            options={jenisChecklist ?? []}
            loading={loadingJenisChecklist}
            onChange={async (val) =>
              setValues({
                ...values,
                jenis_checklist: val.value,
              })
            }
            defaultValue={jenisChecklist.find(
              (val) => val.value === values.jenis_checklist
            )}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
