import Services from "services"

class TransferAlatMesinJobMixApi {
    page(params) {
        return Services.get("/transfer_produksi_alat_mesin/page", { params })
    }

    single(params) {
        return Services.get(`/transfer_produksi_alat_mesin/single`, { params })
    }

    produksi_page(params) {
        return Services.get("/transfer_produksi_alat_mesin/produksi_page", { params })
    }

    produksi_single(value) {
        return Services.get(`/transfer_produksi_alat_mesin/produksi_single?id_jobmix=${value}`)
    }

    jobmix_single(value) {
        return Services.get(`/transfer_produksi_alat_mesin/jobmix_single?id_jobmix=${value}`)
    }

    histori(value) {
        return Services.get(`/transfer_produksi_alat_mesin/histori?id_jobmix=${value}`)
    }

    getSinglePermintaan(params) {
        return Services.get("/transfer_produksi_alat_mesin/permintaan_single", { params })
    }

    no_baru(value) {
        return Services.get(`/transfer_produksi_alat_mesin/no_baru?tanggal=${value}`)
    }

    getDropdownItem() {
        return Services.get("/dropdown/item_alat")
    }

    getDropdownGudang(params) {
        return Services.get("/dropdown/gudang", { params })
    }

    getDropdownProduksi() {
        return Services.get("/dropdown/item_produksi")
    }

    getDropdownKaryawan() {
        return Services.get("/dropdown/karyawan")
    }

    save(data) {
        return Services.post("/transfer_produksi_alat_mesin", data)
    }
}

export default new TransferAlatMesinJobMixApi();
