// React
import { useEffect, useState } from "react";

// API
import axios from "axios";
import { TransferAlatMesinJobMixApi } from "api";

// Components
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from "components";
import { Card } from "react-bootstrap";
import { DateConvert, DecimalConvert } from "utilities";

// Router
import { useHistory, useLocation, useParams } from "react-router-dom";

// View Components
import { InfoTambahSection, TableTransfer, TransferModal } from "./components";
import { Divider } from "./components/modals/DetailProduksi/components";

// Icons
import { IoAddOutline, IoCheckmarkOutline } from "react-icons/io5";

const TambahTransferAlatMesinJobMix = ({ setNavbarTitle }) => {
  // Variables
  const title = "Transfer Alat & Mesin Produksi";

  // Hooks
  const location = useLocation();
  const { id } = useParams();
  const history = useHistory();

  // States
  const [page, setPage] = useState({
    loading: true,
    status: false,
  });
  const [data, setData] = useState({});
  const [dataHistory, setDataHistory] = useState({});
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  useEffect(() => {
    setNavbarTitle(title);
    getData();

    // eslint-disable-next-line
  }, []);

  const getData = () => {
    setPage({
      loading: true,
      status: false,
    });

    axios
      .all([
        TransferAlatMesinJobMixApi.produksi_single(id),
        TransferAlatMesinJobMixApi.histori(id),
      ])
      .then(
        axios.spread((res, histori) => {
          setData(res.data.data);
          setDataHistory(histori.data.data);

          setPage({
            loading: false,
            status: true,
          });
        })
      )
      .catch(() => {
        setPage({
          loading: false,
          status: false,
        });
      });
  };

  const toggleModal = () => setModal(!modal);
  const toggleSuccess = () => {
    setAlertConfig({
      show: true,
      text: "Tambah data berhasil",
      variant: "primary",
    });
    getData();
  };

  const getLastQtyTransfer = data?.permintaan
    ?.filter(
      (val) =>
        parseFloat(val.qty_transfer ?? 0) <
        parseFloat(val.qty_permintaan_produksi ?? 0)
    )
    .reduce((acc, curr) => {
      const totalTransfer = acc + parseFloat(curr?.qty_transfer ?? 0);
      const totalPermintaan =
        acc + parseFloat(curr?.qty_permintaan_produksi ?? 0);

      return {
        qty_transfer: totalTransfer,
        qty_permintaan_produksi: totalPermintaan,
      };
    }, 0);

  return (
    <>
      <div className="text-right">
        <BackButton
          onClick={() =>
            history.push(
              "/transaksi/transfer-alat-mesin-job-mix/list-permintaan-alat-mesin",
              location.state
            )
          }
        />
      </div>

      <b>Tambah Data {title}</b>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          setAlertConfig({
            ...alertConfig,
            show: false,
          });
        }}
      />

      {page.loading || !page.status ? (
        <DataStatus
          loading={page.loading}
          text={
            page.loading
              ? "Memuat data..."
              : "Data gagal dimuat, tidak dapat menampilkan data"
          }
        />
      ) : (
        <>
          <Divider>
            <InfoTambahSection data={data} />
          </Divider>

          <Divider>
            <Card>
              <Card.Body>
                <small>List Data Permintaan Produksi</small>
                <Table>
                  <THead>
                    <Tr>
                      <ThFixed>No</ThFixed>
                      <ThFixed>
                        Informasi Permintaan Alat & Mesin Produksi
                      </ThFixed>
                      <Th>Item Alat & Mesin</Th>
                      <ThFixed>Satuan</ThFixed>
                      <ThFixed>Qty. Permintaan Produksi</ThFixed>
                      <ThFixed>Total Qty. Telah Ditransfer</ThFixed>
                      <ThFixed>Aksi</ThFixed>
                    </Tr>
                  </THead>

                  <TBody>
                    {data.permintaan.length === 0 ? (
                      <Tr>
                        <Td colSpan={7}>
                          <DataStatus text="Tidak ada data" />
                        </Td>
                      </Tr>
                    ) : (
                      data.permintaan.map((val, index) => {
                        // Variables
                        const qty_permintaan_produksi = parseFloat(
                          val.qty_permintaan_produksi ?? 0
                        );
                        const qty_transfer = parseFloat(val.qty_transfer ?? 0);
                        const notAvailable = Boolean(
                          qty_transfer >= qty_permintaan_produksi
                        );

                        return (
                          <Tr key={index}>
                            <TdFixed>{index + 1}</TdFixed>
                            <Td>
                              <div>
                                {val.tgl_permintaan_produksi_alat_mesin
                                  ? DateConvert(
                                      new Date(
                                        val.tgl_permintaan_produksi_alat_mesin
                                      )
                                    ).defaultDMY
                                  : "-"}
                              </div>
                              <div>
                                {val.no_permintaan_produksi_alat_mesin ?? "-"}
                              </div>
                            </Td>
                            <Td>{val.nama_item ?? "-"}</Td>
                            <Td>{val.kode_satuan ?? "-"}</Td>
                            <Td className="text-right">
                              {
                                DecimalConvert(qty_permintaan_produksi)
                                  .getWithComa
                              }
                            </Td>
                            <Td className="text-right">
                              {DecimalConvert(qty_transfer).getWithComa}
                            </Td>
                            <TdFixed>
                              <ActionButton
                                text={
                                  notAvailable ? (
                                    <IoCheckmarkOutline size={12} />
                                  ) : (
                                    <IoAddOutline size={12} />
                                  )
                                }
                                variant={notAvailable ? "success" : "primary"}
                                className="my-1"
                                size="sm"
                                onClick={() => {
                                  if (!notAvailable) {
                                    setModalData(val);
                                    toggleModal();
                                  } else {
                                    return null;
                                  }
                                }}
                              />
                            </TdFixed>
                          </Tr>
                        );
                      })
                    )}
                  </TBody>
                </Table>

                <small>History {title}</small>
                <TableTransfer data={dataHistory} title={title} />
              </Card.Body>
            </Card>
          </Divider>
        </>
      )}

      {modal && (
        <TransferModal
          data={modalData}
          toggle={toggleModal}
          title={title}
          toggleSuccess={toggleSuccess}
          isCreate
          getLastQtyTransfer={getLastQtyTransfer}
        />
      )}
    </>
  );
};

export default TambahTransferAlatMesinJobMix;
