import React, { Fragment } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import {
  Input,
  TextArea,
  ButtonBack,
  ButtonCreate,
  ButtonApprove,
  ButtonReject,
} from 'components2';
import { DatePicker, InfoItemHorizontal } from 'components';
import { TablePemeliaharaanAsetContent } from './TablePemeliaharaanAsetContent';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DateConvert } from 'utilities';
import { useQuery } from 'react-query';
import FormChecklistApi from '../__FormChecklistApi__';

export const FormAnalisaChecklistContent = ({ action }) => {
  const { values, handleSubmit, setFieldValue, errors, touched, handleChange } =
    useFormikContext();
  const history = useHistory();

  const { data: kodeFormChecklist, isFetching: loadingKode } = useQuery(
    ['formChecklist', 'generateNumber', values?.tgl_form_checklist],
    () =>
      FormChecklistApi.generateNumber({
        tanggal: values?.tgl_form_checklist,
      }).then(({ data }) => setFieldValue('no_form_checklist', data?.data)),
    { enabled: !!values?.tgl_form_checklist && action !== 'detail' }
  );

  return (
    <Fragment>
      <div className="d-flex justify-content-between mb-2">
        <small className="font-weight-bold">
          Detail Data Maintenance Request
        </small>
        <ButtonBack
          size="sm"
          onClick={() => history.push('/transaksi/form-checklist')}
        />
      </div>
      <Card>
        <Card.Body>
          <InfoItemHorizontal
            label="Tgl. Maintenance Request"
            text={
              values?.tgl_maintenance_request_master
                ? DateConvert(new Date(values?.tgl_maintenance_request_master))
                    .detail
                : '-'
            }
          />
          <InfoItemHorizontal
            label="No. Maintenance Request"
            text={values?.no_maintenance_request_master ?? '-'}
          />
          <InfoItemHorizontal
            label="Catatan"
            text={values?.catatan_maintenance_request ?? '-'}
          />
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-between mt-3">
        <small className="font-weight-bold">
          {action === 'detail'
            ? 'Detail Data Form Checklist'
            : 'Tambah Data Form Checklist'}
        </small>
      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <DatePicker
                label="Tgl. Form Checklist"
                onChange={(val) =>
                  setFieldValue(
                    'tgl_form_checklist',
                    DateConvert(new Date(val)).default
                  )
                }
                disabled={action === 'detail'}
                selected={
                  values?.tgl_form_checklist
                    ? new Date(values?.tgl_form_checklist)
                    : null
                }
                error={
                  errors.tgl_form_checklist &&
                  touched.tgl_form_checklist &&
                  true
                }
                errorText={
                  errors.tgl_form_checklist &&
                  touched.tgl_form_checklist &&
                  errors.tgl_form_checklist
                }
              />
            </Col>
            <Col>
              <Input
                label="No. Form Checklist"
                value={values?.no_form_checklist}
                loading={loadingKode}
                disabled
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {action !== 'detail' && (
        <>
          <div className="d-flex justify-content-between mt-3">
            <small className="font-weight-bold">
              List Item Aset Yang Memerlukan Pemeliharaan
            </small>
          </div>
          <Card className="mb-3">
            <Card.Body>
              <TablePemeliaharaanAsetContent
                action={action}
                type="readyChecklist"
                data={values?.detail_item_pemeliharaan_aset}
              />
            </Card.Body>
          </Card>
        </>
      )}

      <div className="d-flex justify-content-between mt-3">
        <small className="font-weight-bold">List Form Checklist</small>
      </div>
      <Card className="mb-3">
        <Card.Body>
          <TablePemeliaharaanAsetContent
            action="detail"
            type={action !== 'detail' && 'alreadyChecklist'}
            data={
              action === 'detail'
                ? values?.detail_item_pemeliharaan_aset
                : values?.detail_item_pemeliharaan_aset?.filter(
                    (val) => val.status_checklist === true
                  )
            }
          />
        </Card.Body>
      </Card>

      <TextArea
        label="Catatan Form Checklist"
        name="catatan"
        value={values?.catatan ?? '-'}
        disabled={action === 'detail' ? true : false}
        onChange={handleChange}
      />
      {action !== 'detail' && (
        <div className="d-flex justify-content-end mt-3">
          <ButtonReject
            className="mr-3"
            onClick={() => {
              setFieldValue('status_checklist', 'reject');
              handleSubmit();
            }}
          />
          <ButtonApprove
            onClick={() => {
              setFieldValue('status_checklist', 'approve');
              handleSubmit();
            }}
          />
        </div>
      )}
    </Fragment>
  );
};
