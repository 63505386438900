import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { FormPermintaanAsetContent } from './__PermintaanAsetComps__';
import { useMutation, useQuery } from 'react-query';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import PenerimaanAsetApi from './__PenerimaanAsetApi__';
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from './__PermintaanAsetUtils__';
import { Alert, DataStatus } from 'components';

export const PenerimaanAsetAsetCreate = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: '',
    variant: 'primary',
  });

  const {
    data: detailPurchaseOrderAset,
    isFetching: loadingDetailPurchaseOrderAset,
  } = useQuery(
    ['permintaanAset', 'single', id],
    () =>
      PenerimaanAsetApi.getSinglePurchaseOrder({
        id_purchase_order_aset_detail: id,
      }),
    { enabled: !!id }
  );

  const createPenerimaanAset = useMutation((data) =>
    PenerimaanAsetApi.create(data)
  );

  const formSubmitHandler = (value, { setSubmitting }) => {
    const fileBuktiPenerimaan = [value?.bukti_penerimaan];
    const formData = new FormData();

    fileBuktiPenerimaan.reduce((_acc, file, _index) => {
      formData.append('file', file);
      return file;
    }, {});

    PenerimaanAsetApi.upload(formData)
      .then((data) => {
        const finalValues = {
          ...formSubmitValueMapper(value),
          bukti_penerimaan: data?.data?.data,
        };

        createPenerimaanAset
          .mutateAsync(finalValues)
          .then(() =>
            history.push('/transaksi/penerimaan-aset', {
              alert: {
                variant: 'primary',
                show: true,
                text: 'Data berhasil disimpan!!!',
              },
            })
          )
          .catch(() =>
            setAlertConfig({
              variant: 'danger',
              show: true,
              text: 'Data gagal disimpan!!!',
            })
          );
      })
      .catch(() =>
        setAlertConfig({
          variant: 'danger',
          show: true,
          text: 'Gambar gagal disimpan!!!',
        })
      )
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    setNavbarTitle('Penerimaan Aset');
    return () => {};
  }, []);

  return (
    <>
      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
        showCloseButton
      />
      {loadingDetailPurchaseOrderAset ? (
        <DataStatus text="Memuat Data..." loading />
      ) : (
        <Formik
          initialValues={formInitialValues(detailPurchaseOrderAset?.data)}
          onSubmit={formSubmitHandler}
          validationSchema={formValidationSchema}
        >
          <FormPermintaanAsetContent action="create" />
        </Formik>
      )}
    </>
  );
};
