export const calculateDefaultQtyRealisasi = ({
  qty_permintaan,
  qty_terealisasi,
}) => {
  const parseQtyPermintaan = parseFloat(qty_permintaan ?? 0);
  const parseQtyTerealisasi = parseFloat(qty_terealisasi ?? 0);

  const defaulQtyRealisasi = parseQtyPermintaan - parseQtyTerealisasi;

  if (defaulQtyRealisasi < 0) {
    return 0;
  }

  return defaulQtyRealisasi;
};

export const mapSparepartUpahSubkonFromServer = (data, subkon = 'upah') => {
  return data?.reduce(
    (sparepart, item) => sparepart.concat(item?.[`sparepart_${subkon}`] ?? []),
    []
  );
};

export const formInitialValues = (data, isReadOnly) => ({
  id_work_order: data?.id_work_order,
  tgl_realisasi_work_order: data?.tgl_realisasi_work_order ?? '',
  no_realisasi_work_order: data?.no_realisasi_work_order ?? '',
  pekerjaan: data?.rincian_pekerjaan ?? [],
  nama_vendor: data?.nama_vendor ?? '',
  bahan:
    data?.bahan?.map((val) => ({
      ...val,
      maxQty: calculateDefaultQtyRealisasi({
        qty_permintaan: val?.qty_transfer_work_order_bahan ?? 0,
        qty_terealisasi: val?.qty_terealisasi ?? 0,
      }),
      qty_realisasi_bahan: isReadOnly
        ? val.qty_realisasi_bahan
        : calculateDefaultQtyRealisasi({
            qty_permintaan: val?.qty_transfer_work_order_bahan ?? 0,
            qty_terealisasi: val?.qty_terealisasi ?? 0,
          }),
    })) ?? [],

  upah:
    data?.upah?.map((val) => ({
      ...val,
      maxQty: calculateDefaultQtyRealisasi({
        qty_permintaan: val?.qty_transfer_work_order_upah ?? 0,
        qty_terealisasi: val?.qty_terealisasi ?? 0,
      }),
      qty_realisasi_upah: isReadOnly
        ? val.qty_realisasi_upah
        : calculateDefaultQtyRealisasi({
            qty_permintaan: val?.qty_transfer_work_order_upah ?? 0,
            qty_terealisasi: val?.qty_terealisasi ?? 0,
          }),
    })) ?? [],

  alat_mesin: data?.alat_mesin?.map((val) => ({
    ...val,
    maxQty: calculateDefaultQtyRealisasi({
      qty_permintaan: val?.qty_transfer_work_order_alat_mesin ?? 0,
      qty_terealisasi: val?.qty_terealisasi ?? 0,
    }),
    qty_realisasi_alat_mesin: isReadOnly
      ? val.qty_realisasi_alat_mesin
      : calculateDefaultQtyRealisasi({
          qty_permintaan: val?.qty_transfer_work_order_alat_mesin ?? 0,
          qty_terealisasi: val?.qty_terealisasi ?? 0,
        }),
  })),

  subkon: data?.subkon?.map((val) => ({
    ...val,
    maxQty: calculateDefaultQtyRealisasi({
      qty_permintaan: val?.qty_transfer_work_order_subkon ?? 0,
      qty_terealisasi: val?.qty_terealisasi ?? 0,
    }),
    qty_realisasi_subkon: isReadOnly
      ? val.qty_realisasi_subkon
      : calculateDefaultQtyRealisasi({
          qty_permintaan: val?.qty_transfer_work_order_subkon ?? 0,
          qty_terealisasi: val?.qty_terealisasi ?? 0,
        }),
  })),

  overhead: data?.overhead?.map((val) => ({
    ...val,
    maxQty: calculateDefaultQtyRealisasi({
      qty_permintaan: val?.qty_transfer_work_order_overhead ?? 0,
      qty_terealisasi: val?.qty_terealisasi ?? 0,
    }),
    qty_realisasi_overhead: isReadOnly
      ? val.qty_realisasi_overhead
      : calculateDefaultQtyRealisasi({
          qty_permintaan: val?.qty_transfer_work_order_overhead ?? 0,
          qty_terealisasi: val?.qty_terealisasi ?? 0,
        }),
  })),
  sparepart_upah: mapSparepartUpahSubkonFromServer(data?.upah ?? [], 'upah'),
  sparepart_subkon: mapSparepartUpahSubkonFromServer(
    data?.subkon ?? [],
    'subkon'
  ),
});
