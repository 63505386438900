import { ButtonBack, ButtonCreate } from "@bhawanadevteam/react-core";
import { SelectSearch, TextArea } from "components";
import { Formik, useFormikContext } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import { useQuery } from "react-query";
import MaintenanceRequestApi from "../__MaintenanceRequestApi__";

export const ModalForm = ({ setModalConfig, modalConfig }) => {
  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();

  const { data: dropdownGrupAset, isFetching: loadingDropdownGrupAset } =
    useQuery(["grupAset", "dropdown"], () =>
      MaintenanceRequestApi.getDropdownGrupAset()
    );

  console.log(values);
  const {
    data: dropdownKategoriAset,
    isFetching: loadingDropdownKategoriAset,
  } = useQuery(
    ["kategoriAset", "dropdown", values?.idGrupAset],
    () =>
      MaintenanceRequestApi.getDropdownKategoriAset({
        id_grup_aset: values?.idGrupAset,
      }),
    { enabled: !!values?.idGrupAset }
  );

  const { data: dropdownJenisAset, isFetching: loadingDropdownJenisAset } =
    useQuery(
      ["jenisAset", "dropdown", values?.idKelompokAset],
      () =>
        MaintenanceRequestApi.getDropdownJenisAset({
          id_kategori_aset: values?.idKelompokAset,
        }),
      { enabled: !!values?.idKelompokAset }
    );

  const { data: dropdownItemAset, isFetching: loadingDropdownItemAset } =
    useQuery(
      ["jenisAset", "dropdown", values?.idJenisAset],
      () =>
        MaintenanceRequestApi.getDropdownItemAset({
          id_jenis_aset: values?.idJenisAset,
        }),
      { enabled: !!values?.idJenisAset }
    );

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ show: false, data: {}, type: "" })}
    >
      <Modal.Header closeButton>
        Tambah Item Aset Yang Memerlukan Pemeliharaan
      </Modal.Header>
      <Modal.Body>
        <SelectSearch
          label="Grup Aset"
          option={dropdownGrupAset}
          loading={loadingDropdownGrupAset}
          name="idGrupAset"
          placeholder="Pilih grup aset"
          onChange={(val) =>
            setValues({
              ...values,
              idGrupAset: val.value,
              namaGrupAset: val.label,
            })
          }
          error={errors.idGrupAset && touched.idGrupAset && true}
          errorText={
            errors.idGrupAset && touched.idGrupAset && errors.idGrupAset
          }
        />
        <SelectSearch
          label="Kelompok Aset"
          name="idKelompokAset"
          placeholder="Pilih kelompok aset"
          option={dropdownKategoriAset}
          loading={loadingDropdownKategoriAset}
          isDisabled={Boolean(!values?.idGrupAset)}
          onChange={(val) =>
            setValues({
              ...values,
              idKelompokAset: val.value,
              namaKelompokAset: val.label,
            })
          }
          error={errors.idKelompokAset && touched.idKelompokAset && true}
          errorText={
            errors.idKelompokAset &&
            touched.idKelompokAset &&
            errors.idKelompokAset
          }
        />
        <SelectSearch
          label="Jenis Aset"
          name="idJenisAset"
          placeholder="Pilih jenis aset"
          option={dropdownJenisAset}
          loading={loadingDropdownJenisAset}
          isDisabled={Boolean(!values?.idKelompokAset)}
          onChange={(val) =>
            setValues({
              ...values,
              idJenisAset: val.value,
              namaJenisAset: val.label,
            })
          }
          error={errors.idJenisAset && touched.idJenisAset && true}
          errorText={
            errors.idJenisAset && touched.idJenisAset && errors.idJenisAset
          }
        />
        <SelectSearch
          label="Item Aset"
          name="idItemAset"
          option={dropdownItemAset}
          placeholder="Pilih item aset"
          loading={loadingDropdownItemAset}
          isDisabled={Boolean(!values?.idJenisAset)}
          onChange={(val) =>
            setValues({
              ...values,
              idItemAset: val.value,
              namaItemAset: val.label,
              kodeItemAset: val.kode,
            })
          }
          error={errors.idItemAset && touched.idItemAset && true}
          errorText={
            errors.idItemAset && touched.idItemAset && errors.idItemAset
          }
        />
        <TextArea
          label="Keterangan"
          name="keterangan"
          placeholder="Masukan keterangan"
          onChange={handleChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonBack />
        <ButtonCreate onClick={handleSubmit} loading={isSubmitting} />
      </Modal.Footer>
    </Modal>
  );
};
