// React
import React from 'react';

// Form
import { Formik, useFormikContext } from 'formik';

// Components
import { DatePicker, FilterModal } from 'components';
import { Select } from 'components2';

// API
import { useQuery } from 'react-query';
import FormChecklistApi from '../__FormChecklistApi__';
import { DateConvert } from 'utilities';

export const ModalFilter = ({ toggle, type }) => {
  const { values, handleSubmit, setValues } = useFormikContext();

  const { data: itemAset, isFetching: loadingItemAset } = useQuery(
    ['itemAset', 'dropdown'],
    () => FormChecklistApi.getDropdownItemAset()
  );

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  const dataStatusChecklist = [
    { label: 'Approved', value: 'approve' },
    { label: 'Reject', value: 'reject' },
  ];

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    const myPromise = new Promise((resolve) => {
      setValues({
        id_grup_aset: undefined,
        tgl_maintenance_request_start: undefined,
        tgl_maintenance_request_end: undefined,
        tgl_form_checklist_start: undefined,
        tgl_form_checklist_end: undefined,
        get_item: undefined,
        status_checklist: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => {});
  };

  return (
    <FilterModal
      isToggle
      toggleModal={toggle}
      onResetButtonClick={() => onResetButtonClick(setValues)}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        label="Tgl. Maintenance Request"
        placeholderText="Pilih tgl. maintenance request"
        startDate={
          values?.tgl_maintenance_request_start
            ? new Date(values?.tgl_maintenance_request_start)
            : ''
        }
        endDate={
          values?.tgl_maintenance_request_end
            ? new Date(values?.tgl_maintenance_request_end)
            : ''
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            'tgl_maintenance_request_start',
            'tgl_maintenance_request_end'
          )
        }
        monthsShown={2}
      />
      {type !== 'preForm' && (
        <DatePicker
          selectsRange
          label="Tgl. Form Checklist"
          placeholderText="Pilih tgl. form checklist"
          startDate={
            values?.tgl_form_checklist_start
              ? new Date(values?.tgl_form_checklist_start)
              : ''
          }
          endDate={
            values?.tgl_form_checklist_end
              ? new Date(values?.tgl_form_checklist_end)
              : ''
          }
          onChange={(dates) =>
            onTanggalChange(
              dates,
              values,
              setValues,
              'tgl_form_checklist_start',
              'tgl_form_checklist_end'
            )
          }
          monthsShown={2}
        />
      )}
      <Select
        label="Item Aset"
        placeholder="Pilih item aset"
        name="get_item"
        options={itemAset ?? []}
        loading={loadingItemAset}
        onChange={async (val) =>
          setValues({
            ...values,
            get_item: val.value,
          })
        }
        defaultValue={itemAset?.find((val) => val.value === values.get_item)}
      />
      {type !== 'preForm' && (
        <Select
          label="Status Checklist"
          placeholder="Pilih status"
          name="status_checklist"
          options={dataStatusChecklist ?? []}
          onChange={async (val) =>
            setValues({
              ...values,
              status_checklist: val.value,
            })
          }
          defaultValue={dataStatusChecklist?.find(
            (val) => val.value === values.status_checklist
          )}
        />
      )}
    </FilterModal>
  );
};
