import Services from "services";

class ItemCheclistApi {
  async getList(params) {
    const fetch = await Services.get("/item_checklist/page", {
      params,
    });
    return fetch.data;
  }

  getSingle(params) {
    return Services("/item_checklist/single/", { params });
  }

  async getDropdownJenisChecklist() {
    return [
      {
        value: "1",
        label: "Perlengkapan",
      },
      {
        value: "2",
        label: "Fungsi",
      },
    ];
  }

  generateNumber(params) {
    return Services.get("/item_checklist/no_baru", { params });
  }

  create(data) {
    return Services.post("/item_checklist", data);
  }

  update(data) {
    return Services.put("/item_checklist", data);
  }
}

export default new ItemCheclistApi();
